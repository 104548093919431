export default class Contact extends Component {
  show() {
    return (
      <contact>
        <email>
          email:{" "}
          <a style={{ marginLeft: 5 }} href="mailto:cammarata.nick@gmail.com">
            cammarata.nick@gmail.com
          </a>
        </email>
        <twitter>
          twitter:{" "}
          <a $handle target="_blank" href="http://twitter.com/nicklovescode">
            @nicklovescode
          </a>{" "}
        </twitter>
      </contact>
    )
  }

  styles = {
    email: {
      height: 30,
      flexFlow: "row",
    },
    contact: {
      display: "bold",
    },
    twitter: {
      display: "block",
      height: 30,
    },
    handle: {
      display: "inline",
    },
  }
}
