import ReactMarkdown from "react-markdown"

const essay = `I find it pretty hard to predict whether personal experiments will succeed or fail, so I try lots of things each year and stick with the winners. These are my favorites from 2019.

**Dear Nick**

![](https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fnick-personal%2FRvO2AYANaL?alt=media&token=ec6fafad-0a10-469d-9bb6-1e87569b5e99)

My life is hilariously unstructured and for 2019 I aimed to have at least one routine. I created this script to follow each Sunday:

  1. 9:30pm -> Head to a boba shop in the outskirts of San Francisco and turn my phone off. Order egg-waffles and boba and sit alone reflecting.
  2. 10:15pm -> Walk a couple of miles home through the fog.
  3. 11pm -> 75 minute massage
  4. 12:30am -> 15 minute metta (aka loving-kindness) meditation on someone I felt close to this week, followed by a second 15 minute metta focused on myself.
  5. 1am -> Dear Nick
  6. 2am -> Sleep

Dear Nick is my attempt at a weekly heart-to-heart with the theoretical best version of myself. Since I don’t have access to the actual best version of myself, I created this several hour routine to turn myself into the closest approximation I can muster.

In this state I’m able to look at every version of Nick from the past week in a more detached but sympathetic way. I start writing a few paragraph letter starting with “Dear Nick" and ending with “I love you Nick” telling these previous versions of myself what I think they most need to know. Then I copy it into an email to my own inbox, bcc several close friends who have agreed to be a part, and click send.

There were two surprises from this experiment:

  1. Treating my past selves as external often allows me to see things in a new way. Conversely, throughout the week I often wonder “What would Sunday Nick think about this situation?”, which gives me partial-access to this state.
  2. Several of the 5 people who receive it have told me that they feel closer to me because they receive Dear Nick. A couple have started one themselves, and it even spread to a few people I don’t know. I think there’s 5 total “Dear X” weekly letters now.

**Kalebods**

![](https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fnick-personal%2FCLKs1UuXM6?alt=media&token=4b5fd7d7-4fb9-4163-ac88-eadf78166f78)

I like having a healthy body but dislike eating healthy food. I had a hypothesis: just as forced smiling makes your brain release serotonin that makes you happy, maybe joining a cult that worships a food you hate will eventually make you love it.

More than a hundred kale meals, dozens of kale selfies and conversations about kale, and one hand-written poem about kale, I enjoy eating kale! I also reached my ideal weight in a way that was fun rather than effortful like other diets I’ve tried.

Kalebods is a support cult for those that worships our leafy queens. It sways between 4-10 members and maintains an equal gender ratio. 

Members send pictures of kale and pictures of their bods. I personally combine them, which is exactly as awkward as it sounds in a shared bathroom. We also share short weekly dietary reviews.

Kalebods is a fun form of social accountability that naturally reminds members around lunchtime that their friends are eating healthy food and they can too. 

At first some people — mostly girls — were quite reasonably shy about sharing slightly intimate selfies but since the group floods everyone who shares with emojis and positive messages, people became more comfortable with time. It also required setting a culture of not sharing posts externally.

Many members messaged me privately to tell me that Kalebods changed their diet for the better. 

![](https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fnick-personal%2FdbzKO4zeO-?alt=media&token=1bf47899-e0ba-4b86-8be4-c0aaaea422fb)

Speaking of which, I’m recruiting for the next year of Kalebods over the next few days! To apply, send emails to cammarata.nick@gmail.com three days in a row of kale selfies. Or even better, try starting your own Kalebods inspired gruop and let me know how it goes!

Looking forward to 2020 and the experiments it will bring!`

export default class Experiments extends Component {
  render() {
    return (
      <post className="experiments">
        <row
          style={{
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1>The Two Winning Experiments of 2019</h1>
          <date>January, 2020</date>
        </row>
        <ReactMarkdown source={essay} />
      </post>
    )
  }

  styles = {
    post: {
      width: 650,
    },
    img: {
      width: "100%",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
