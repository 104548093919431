import { Link } from "react-router-dom"
import { Pane } from "evergreen-ui"

import { H2, H3, Example } from "./components"

const isMobile = window.innerWidth < 1250

class Url extends Component {
  show() {
    const { href, children } = this.props

    return (
      <a href={href} target="_blank">
        {children}
      </a>
    )
  }

  styles = {
    a: {
      textDecoration: "underline",
      color: `#0084B4`,
      fontWeight: 600,
    },
  }
}

export default class Reappraisal extends Component {
  show() {
    const Paragraph = ({ children, ...props }) => (
      <p style={{ fontSize: 20, lineHeight: 1.53, ...props }}>{children}</p>
    )

    const Heading = ({ children, ...props }) => (
      <h2
        style={{
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        {children}
      </h2>
    )
    return (
      <post>
        <row>
          <h1>Empathetic Reappraisal</h1>
          <date>June, 2019</date>
        </row>
        <Paragraph>
          <i>
            This is part of an experiment to write in the open, even with topics
            I would previously have only written about privately
          </i>
        </Paragraph>

        <Paragraph>
          I'd like to share a simple method I created to help me empathize with
          people that I may naturally oversimplify.
        </Paragraph>

        <Paragraph>
          In practice, it's been most helpful when I first meet someone
          impressive or untouchably fun and attractive.
        </Paragraph>

        <Paragraph>
          In these cases I have limited information — a Wikipedia article
          listing their wins or a few minutes of flirtation in flattering light.
          I used to automatically process this uncertainty by placing them on a
          pedestal. Instead of seeing that my shallow model of them was filled
          with holes, I’d plug the holes with positive things.
        </Paragraph>

        <Paragraph>
          The first time I noticed this was when I was sixteen. I had just
          gotten my first cellphone and l got a call saying that gazillionaire
          superinventor{" "}
          <a href="https://en.wikipedia.org/wiki/Dean_Kamen">Dean Kamen</a>{" "}
          liked my work and was wondering if I wanted to spend an afternoon
          together at his lab, Deka Research. It was the first time I had been
          given an invitation like that — it was probably the first invitation I
          had received to anything. I begged my cousin to drive me the couple
          hours to his New Hampshire lab because I didn’t have a license.
        </Paragraph>

        <Paragraph>
          Unfortunately, despite Dean’s crazy amount of kindness and generosity
          during the time we spent together, my brain got in the way. It kept
          looping the two paragraph biography I read of him while we talked, and
          I noticed a desire to impress him. He just wanted to play with
          (extreeemely cool!) toys with me and I was a jittery mess.
        </Paragraph>

        <Paragraph>
          One day I learned about negative reappraisal, a{" "}
          <a href="http://psycnet.apa.org/record/2017-37800-001">
            evidence-backed
          </a>
          &nbsp; technique for getting over someone. After your next break-up,
          scroll through your ex's Facebook feed judging everything as harshly
          as you can. This will help to break your brain’s natural inclination
          to loop on memories and their positive traits. It's not kind, but it
          will reduce your desire to Facebook-stalk them and you can stop the
          judgement after you’ve processed the relationship.
        </Paragraph>

        <Paragraph>
          I tried using negative reappraisal when meeting the impressive and
          beautiful and it worked shockingly well, almost completely removing my
          desire to impress them. But I felt unkind doing it and had less
          interest in getting to know them.
        </Paragraph>

        <Paragraph>
          My natural behavior filled the missing holes with positivity and
          negative reappraisal did the same with negativity. But it was failing
          for the same reason — the whole point is that there’s holes.
        </Paragraph>

        <Paragraph>
          So I created Empathetic Reappraisal, which wonders "what does someone
          who fully loves this person see in them". I’d imagine their mother or
          life partner standing in my place and imagine what they see. They
          certainly don’t love them just because they're hot or impressive. And
          even the way in which they’re hot or impressive is more nuanced than I
          realize.
        </Paragraph>

        <Paragraph>
          I’ve fortunately had the chance to use empathetic reappraisal hundreds
          of times and I can feel it give me a visceral craving to understand
          who they <i>really are</i>. Instead of trying to put them above or
          below me, it takes me out of the scene. It improves my listening and
          allows me to appreciate the subtle details in front of me.
        </Paragraph>

        <Paragraph>
          It's a special case of realizing{" "}
          <a href="/writing/everything-is-fertile">everything is fertile</a>. It
          imagines the magic eyes of someone who's perception is better than
          mine and reminds me just how much I can't see.
        </Paragraph>

        <Paragraph>
          In the last few years I’ve discussed this technique with several
          impressive people and they universally told me how meeting people is
          often hard for them too because the other person gets nervous and
          tries to act cool and impress them. After a few years of this
          happening constantly it starts to compound into a major source of
          suffering for them. If empathetic reappraisal helps you, you’ll really
          be helping both sides.
        </Paragraph>
        <Paragraph
          borderTop="1px solid rgba(0, 0, 0, 0.2)"
          marginTop={20}
          paddingTop={20}
        >
          Thanks to Nikita Obidin and Alok Singh for reviewing drafts of this.
        </Paragraph>
      </post>
    )
  }

  styles = {
    quote: {
      marginTop: 8,
      marginBottom: 15,
      lineHeight: 1.3,
      fontSize: 14,
      color: `rgba(0, 0, 0, 0.9)`,
      fontWeight: 600,
    },
    author: {
      alignSelf: "flex-end",
    },
    i: {
      display: "inline",
    },
    img: {
      marginTop: 10,
      marginBottom: 15,
      borderRadius: 5,
    },
    row: {
      justifyContent: "space-between",
      flexFlow: "row",
      alignItems: "center",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
