import { Link } from 'react-router-dom'
import { Pane } from 'evergreen-ui'

import { H2, H3, Example } from './components'

const isMobile = window.innerWidth < 1250

class Url extends Component {
  show() {
    const { href, children } = this.props

    return (
      <a href={href} target="_blank">
        {children}
      </a>
    )
  }

  styles = {
    a: {
      textDecoration: 'underline',
      color: `#0084B4`,
      fontWeight: 600,
    },
  }
}

export default class Fertile extends Component {
  show() {
    const Paragraph = ({ children, ...props }) => <p>{children}</p>
    const Heading = ({ children, ...props }) => (
      <h2
        style={{
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        {children}
      </h2>
    )
    return (
      <post>
        <row>
          <h1>Everything is Fertile</h1>
          <date>May, 2019</date>
        </row>
        <Paragraph>
          For much of my life I tried hard to keep things from being sterile. I
          devoured biographies of people who took weird paths and tried to
          differentiate myself in every part of life.
        </Paragraph>
        <Paragraph>
          When I met people I’d try to have an experience that was new to both
          of us.
        </Paragraph>
        <Paragraph>
          One beautiful date I discovered is going to a new forest together,
          smell everything, and compress your favorite smelling findings into
          candles over the course of an evening. If they’re adventurous, keep
          the end-goal a secret until you’re back home and start molding the
          candles. It ends up being 10 hours of talking, exploring, and
          crafting.
        </Paragraph>
        <Paragraph>
          Another meaningful experience is to tell each other your life stories.
          Surprisingly, most people have not gotten a chance to hear their own
          life story. Leave three hours per person to go in depth and you’ll
          each discover trends and threads from your life that become obvious at
          such a macro view.
        </Paragraph>
        <Paragraph>
          Over time I’ve created dozens of these adventures.
        </Paragraph>
        <Paragraph>
          At parties I’d ask questions people hadn’t heard before. I’d go long
          distances to try strange dishes at undiscovered restaurants. After
          all, weird dishes have to claw their way onto menus, and they’re
          likely close to the chef’s heart. On a spare weeknight I’d open Omegle
          and video chat someone with no support system for an hour, hearing
          about their life. I’d call a Lyft to an unmarked green spot in the Bay
          Area and explore the forest there alone for a day.
        </Paragraph>
        <Paragraph>
          These are fun habits, but this preference had a dark side. I’d look
          down on manicured parks, refuse to eat with family when they visited
          chain restaurants, and quickly dismissed people I found bland. Each
          time I’d end up in Frankfurt, Germany, I’d spend the day nauseous and
          depressed. The whole town is sterile.
        </Paragraph>
        <Paragraph>
          Recently I’ve softened this view because of Hayao Miyazaki, the great
          Japanese animated-film director.
        </Paragraph>
        <Paragraph>
          Each year I dedicate myself to learning deeply from one of my heroes.
          I've found that a year of focus allows me to understand someone well
          enough to converse with my mental simulation of them and ask them new
          questions. This is Miyazaki’s year.
        </Paragraph>
        <Paragraph>
          Miyazaki is known for his enchanted naturalistic worlds, which are
          often being explored by curious young children for mystical reasons.
          While his worlds are unquestionably magic, featuring flying islands,
          walking houses, and radish spirits, his personal writing focuses more
          on perception of our normal world.
        </Paragraph>
        <Paragraph>
          He thinks deeply about bees, about how their flow of time is likely
          fundamentally different than us. Do they dodge raindrops effortlessly
          like Manhattan bikers swerve throughout cars? In an obscure talk to
          school-children from decades ago, he talks about the complex vortexes
          of air drafts that falcons must master to travel far distances. By
          keeping with the upswells they can get to high ground. But how do they
          see these swells? The falcons must have magic eyes &mdash; perceptual
          mechanisms that render these flows visible.
        </Paragraph>
        <Paragraph>
          This fixation on the fluidity of perception is what makes Miyazaki's
          career finale film "The Wind Rises" my favorite piece of his. The
          movie follows an aeronautical engineer who struggles to balance his
          love of his craft against his ambivalence for Japan's mid-1940s
          militarism. His followers responded loudly: where are the enchanted
          forests? Where's the magic? The film is a departure from his studio's
          canon.
        </Paragraph>
        <Paragraph>
          But the beauty of The Wind Rises is that it is entirely in harmony
          with the rest of his movies. When Spirited Away's protagonist Chihiro
          accidentally discovers an enchanted land filled with exotic creatures,
          she learns to cope and enjoys a wonderful adventure in this new world.
          But the aircraft and engines in The Wind Rises are no less enchanted,
          and that's the point he is leaving us with. The reason we're less
          enthralled by the engines of The Wind Rises than the dragons of
          Spirited Away is that we can't see through the magic eyes of the
          film's main character, Jiro. The movie is an attempt to give you some
          of Jiro's perception, helping you see the magic in a small piece of
          the world you inhabit. The film is a call to action to see the world
          around you.
        </Paragraph>
        <Paragraph>
          It was this insight that let me finally understand Miyazaki's view of
          nature. When I started studying his work I was surprised to see how
          positively he viewed man-made nature such as parks and planned lakes.
          I treasured Miyazaki's naturalistic portrayals and wished I lived in
          them. He seemed like the last person who would fight in favor of these
          sterile constructions.
        </Paragraph>
        <Paragraph>
          In Miyazaki's eyes there is nothing sterile about crafted nature
          because man cannot help but breed fertility. Man can plant one hundred
          oak trees in perfect order and within a year a rich organic ecosystem
          of birds and insects will have made this place their home. If a
          curious child wanders in they will dream up creatures and friends to
          play with in the trees. Within 10 years fallen material will have
          decomposed into the ground, providing nutrients for new types of life.
          In the eyes of nature, before long the entire forest will burn down
          and rise again. The only eyes that see this land as sterile are mine.
        </Paragraph>
        <Paragraph>
          To gain perspective you must see new things. Another of my heroes,
          Alexander Shulgin, crafted tools to improve his eyes. Perhaps the best
          psychopharmacologist in history, Shulgin discovered hundreds of
          psychoactive compounds that deliver some of the most enchanting
          experiences a human can have - no television required.
        </Paragraph>
        <Paragraph>
          Thirty minutes after his first dose of MDMA, a chemical he
          popularized, he noted a few sentences in his journal:
        </Paragraph>
        <Paragraph>
          "The woodpile is so beautiful, about all the joy and beauty that I can
          stand. I am afraid to turn around and face the mountains, for fear
          they will overpower me. But I did look, and I am astounded. Everyone
          must get to experience a profound state like this. I feel totally
          peaceful. I have lived all my life to get here, and I feel I have come
          home. I am complete."
        </Paragraph>
        <Paragraph>
          120mg of powder gave Shulgin the eyes to see the fertility of his
          woodpile. While most of the molecules Shulgin synthesized are
          relatively tame, some journal entries log adventures like "floating
          with self-transforming machine elves". Shulgin tried on quite a few
          magic eyes.
        </Paragraph>
        <Paragraph>
          The psychonauts that idolize Shulgin are often driven by a wish to
          depart this boring place and blast-off inside their own heads. But
          what drives Shulgin? A glass of orange juice.
        </Paragraph>
        <Paragraph>
          At sixteen, Shulgin began studying organic chemistry at Harvard
          University. He left school early to join the U.S. Navy in 1943. During
          service, he developed a thumb infection that required surgery. Before
          the operation a nurse handed him a glass of orange juice with silky
          crystals, which Shulgin recognized as crystalline morphine. He took a
          sip and quickly fell asleep.
        </Paragraph>
        <Paragraph>
          When he woke he asked the nurse about which sedatives they had given
          him in the orange juice. The nurse was surprised and informed him that
          the crystals were just undissolved sugar.
        </Paragraph>
        <Paragraph>
          This demonstration of the brain’s placebo effect had a profound effect
          on the young chemist, inspiring him to start a laboratory in his house
          working on experimental pharmacology to learn more about the human
          perceptual system.
        </Paragraph>
        <Paragraph>
          Over a lifetime, Shulgin changed the field and pushed the forefront of
          human experiences. But his goal wasn't to emigrate to these lands but
          rather to learn from them. Even his most prized molecule, 2-CB, he's
          ingested only a few times. Each new experiment gave him a new
          perspective into his own mind, and that perspective helped him to see
          the world around him sober.
        </Paragraph>
        <Paragraph>
          I was originally attracted to Miyazaki and Shulgin because they spent
          their lives exploring enchanted places. But in truth, I was an
          escapist missing their most potent lesson: exploration changes our
          perspective and forces us to pay attention, but this perspective can
          be brought back. By spending a lifetime dreaming of magical lands or
          flying with transforming elves one crafts the eyes to finally
          appreciate home.
        </Paragraph>
        <Paragraph>
          I still believe in exploration, whether that means diving into new
          fields of knowledge, flooding your brain with neurotransmitters, or
          having first dates that require passports. These experiences give you
          magic eyes and allow you to see things in new ways. Jiro’s eyes allow
          him to appreciate the mechanical beauty of the Ghibli engine, a
          falcon’s eyes let it glide using the vortexes of the wind, and
          Shulgin’s eyes allow him to notice the beauty of a woodpile.
        </Paragraph>
        <Paragraph>
          But by trying to escape into these experiences I wasn't learning from
          my heroes. Now I’m also interested in paying attention to my{' '}
          <a href="/writing/attention">daily walks</a> and fully acknowledging
          the lushness around me. And when I'm confronted with sterility, I will
          embrace that if only I had better eyes I'd see that everything is
          fertile.
        </Paragraph>
        <Paragraph
          borderTop="1px solid rgba(0, 0, 0, 0.2)"
          marginTop={20}
          paddingTop={20}
        >
          Thanks to Nikita Obidin, Rochelle Shen, Alok Singh, Ava Huang, Danny
          Hernandez, Tom Brown, and Jasmine Wang for reading drafts of this.
        </Paragraph>
      </post>
    )
  }

  styles = {
    quote: {
      marginTop: 8,
      marginBottom: 15,
      lineHeight: 1.3,
      fontSize: 14,
      color: `rgba(0, 0, 0, 0.9)`,
      fontWeight: 600,
    },
    author: {
      alignSelf: 'flex-end',
    },
    img: {
      marginTop: 10,
      marginBottom: 15,
      borderRadius: 5,
    },
    row: {
      justifyContent: 'space-between',
      flexFlow: 'row',
      alignItems: 'center',
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
