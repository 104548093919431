import ReactMarkdown from 'react-markdown'

const essay = `
This isn’t about love, the feeling. God or whatever gave me extra spoonfuls of some good stuff — happy, will, energy — but he skimped on empathy and love. I’ve been working on it, and I have a lot of thoughts on love, the feeling, in the same way that a kid who just graduated training wheels has a lot of thoughts on bikes. I’m indebted to my training wheels, totaling half a gram over a year, and I’d like to pay it forward by helping make better ones. But they didn’t qualify me to write essays on love, the feeling.

I’ll just insist that it is a feeling. My friend was feeling sad and sent me “Love is an utterly BS concept humans made up to help them cope with the meaninglessness of life”. But she’s wrong — love is like shoulders, it’s an evolved part of our body that was fitness-enhancing, which doesn’t make it any less powerful. Maybe love, the word is an utterly BS human concept, just like the idea of shoulders separate from the rest of the body. But love, the feeling, is a feeling.

Let’s keep in mind that if we could directly feel each other we wouldn’t need words. Until then, we’re stuck with low-resolution maps we call words in a sad attempt to portray the rich territory inside each of us. 

Since snow is important territory for Eskimos, they put a lot of effort into crafting good maps. Kanevvluk is fine snow and muruaneq is soft deep snow and there’s a lot more. Yet we have one word, love, for love, the feeling. And the word doesn’t do the feeling justice at all.

Feelings morph and swirl and form strange loops. People who get good at vipassana can feel dozens of times a second. You’re feeling that much too even if you’re too numb to notice. Even if we had enough words to capture the entire landscape of feelings, your mouth couldn’t keep up with them.

But even if [consciousness is hard](https://en.wikipedia.org/wiki/Hard_problem_of_consciousness#:~:text=The%20hard%20problem%20of%20consciousness,a%20thermostat%20or%20a%20toaster.) and we can only dream of prose that does justice to our brain, we can surely do better than love, the word. Bros use 1-10 scales to measure their attraction and doctors use the same to measure pain, which at least acknowledges that feelings have intensity. Yet we treat love, the word, as binary, and you can’t go from saying you love someone to saying you feel love but it’s just under the bar for love, the word, without tanking the whole ship.

Love, the word, also has baggage. It means I want to sleep with you unless I pull out I-friend-love-you, which is among the most dead string of words ever spoken. If I say it and you don’t, we both feel awful. It’s weaponized — don’t you love me? Yet for all the seriousness and weight it takes in human relationships, we’ll happily say I love bananas without a second thought.

Feelings go up and down by the moment. You can feel them towards someone, or towards a banana, or towards nothing at all. If you’re buying a sandwich at the deli you don’t love me, since you’re focused on buying the sandwich. I can feel love for you while you feel no love for me and that’s okay. 

No one can change a word for everyone, but they can change how they use it. Love, the word, makes no sense. It’s filled with baggage, used in all these contradictory ways, and makes us less alive. But love, the feeling is this beautiful feeling that’s clear as day when it comes. If the [linguistic-relativity hypothesis](https://en.wikipedia.org/wiki/Sapir%E2%80%93Whorf_hypothesis) is even slightly true then bringing love, the word closer to love, the feeling will create more of both. So let’s keep trying until [love is love is love is love](https://www.youtube.com/watch?v=3frkqULr008).
`

export default class LovetheWord extends Component {
  render() {
    return (
      <post className="experiments">
        <row
          style={{
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1>Love, the Word</h1>
          <date>June, 2020</date>
        </row>
        <ReactMarkdown source={essay} />
      </post>
    )
  }

  styles = {
    post: {
      width: 650,
    },
    strong: {
      display: 'inline',
    },
    img: {
      width: '100%',
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
