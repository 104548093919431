import { Link } from "react-router-dom"
import posts from "../posts"

export default class Writing extends Component {
  show() {
    return (
      <writing>
        <h1>Writing</h1>
        {posts
          .filter(({ stage }) => stage > 0)
          .map(post => (
            <postName key={post.url}>
              <Link to={`/writing/${post.url}`}>
                <div style={{ marginTop: 10, marginBottom: 10 }}>
                  <btn style={{ alignItems: "center" }}>
                    <span style={{ fontSize: 16, fontWeight: 700 }}>
                      {post.name}
                    </span>{" "}
                    <span style={{ opacity: 0.7, marginLeft: 10 }}>
                      {post.date}
                    </span>{" "}
                    {post.stage === 1 && <draft>Draft</draft>}
                  </btn>
                  <span style={{ fontWeight: 400 }}>{post.desc}</span>
                </div>
              </Link>
            </postName>
          ))}
      </writing>
    )
  }

  styles = {
    postName: {
      display: "block",
    },
    btn: {
      alignItems: "center",
      height: 30,
      fontSize: 16,
      flexFlow: "row",
    },
    draft: {
      fontSize: 12,
      alignItems: "center",
      textAlign: "center",
      padding: `3px 6px`,
      background: `rgba(0, 0, 0, 0.02)`,
      border: `1px solid rgba(0, 0, 0, 0.2)`,
      color: `rgba(0, 0, 0, 0.7)`,
      marginLeft: 5,
      borderRadius: 5,
    },
    b: {
      display: "inline",
      marginLeft: 3,
    },
  }
}
