const confidenceColor = confidence => {
  const rgb = [255 - (confidence / 100) * 255, (confidence / 100) * 255, 0]
  return "rgba(" + rgb.join(",") + ", 1)"
}

class Tooltip extends React.Component {
  state = { tooltip: null }

  componentWillMount() {
    window.setTooltip = tooltip => {
      this.setState({ tooltip })
    }
  }
  render() {
    const { tooltip } = this.state
    return (
      tooltip && (
        <div
          style={{
            position: "absolute",
            fontSize: 14,
            maxWidth: 280,
            background: "white",
            left: 700,
            top: tooltip.y,
            border: "1px solid rgba(247, 209, 20, 0.82)",

            padding: 10,
            borderRadius: 5,
            boxShadow:
              "0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)",
          }}
        >
          <div style={{ fontWeight: 500 }}>
            I have <b>{tooltip.confidence}%</b> confidence in this claim.
          </div>
          {tooltip.content && (
            <div style={{ marginTop: 20 }}>{tooltip.content}</div>
          )}
        </div>
      )
    )
  }
}

class Claim extends Component {
  state = { hovering: false }

  render() {
    const { children, confidence, tooltipContent = null } = this.props
    const { hovering } = this.state

    const styles = Object.assign(
      {},
      {
        transition: "150ms all ease-in",
      },
      hovering
        ? {
            background: "rgba(247, 209, 20, 0.32)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.2)",
          }
        : {
            background: "rgba(0, 0, 0, 0.02)",
            borderBottom: "1px solid rgba(0, 0, 0, 0.05)",
          },
    )

    return (
      <span
        key={1}
        onMouseEnter={e => {
          if (this.props.noTooltip) return
          const scrollTop = document.getElementById("root").scrollTop

          setTooltip({
            y: scrollTop + e.target.parentNode.getBoundingClientRect().y,
            content: tooltipContent,
            confidence,
          })

          this.setState({ hovering: true, tooltip: null })
        }}
        onMouseLeave={() => {
          setTooltip(null)
          this.setState({ hovering: false })
        }}
      >
        <span style={styles}>{children}</span>{" "}
        <span
          style={{
            paddingLeft: 5,
            paddingRight: 5,
            paddingTop: 3,
            paddingBottom: 3,
            transition: "150ms all ease-in",
            fontWeight: 800,
            fontSize: 16,
            background: hovering
              ? "rgba(247, 209, 20, 0.12)"
              : "rgba(0, 0, 0, 0.01)",
            borderRadius: 5,
            border: hovering
              ? "1px solid rgba(247, 209, 20, 0.42)"
              : "1px solid rgba(0, 0, 0, 0.1)",
            color: confidenceColor(confidenceColor),
          }}
        >
          {confidence}%
        </span>
      </span>
    )
  }
}

export default class BeSkeptical extends React.Component {
  render() {
    const Paragraph = ({ children, ...props }) => (
      <p key={1} style={{ fontSize: 20, lineHeight: 1.53, ...props }}>
        {children}
      </p>
    )

    return (
      <post className="experiments" key={1}>
        <Tooltip />
        <row
          style={{
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1>
            <Claim confidence={75} noTooltip>
              Be Skeptical
            </Claim>
          </h1>
          <date>January, 2020</date>
        </row>
        <contents key={1}>
          <Paragraph>
            <Claim
              confidence={60}
              tooltipContent={
                <div style={{ fontWeight: 300 }}>
                  <b>30%</b> is from working at a research lab and talking to
                  other researchers about their experience getting stated in
                  science.
                  <br />
                  <br />
                  <b>20%</b> is from talking to friends in graduate school about
                  their experience.
                  <br />
                  <br />
                  <b>10%</b> comes from reading biographies of scientists.
                </div>
              }
            >
              Most scientists learn early in their career that progressing
              science is about <em>being skeptical</em>
            </Claim>
            , and I agree.
          </Paragraph>
          <Paragraph>
            Skepticism is a call to be curious, to be open to all ideas, and to
            take nothing for granted.
          </Paragraph>
          <Paragraph marginLeft={20}>
            Briefly stated, a skeptic is one who is willing to question any
            claim to truth, asking for clarity (…) <br />
            — Paul Kurtz in The New Skepticism, 1992, p. 9
          </Paragraph>
          <Paragraph marginLeft={20}>
            Skepticism is a provisional approach to claims. It is the
            application of reason to any and all ideas—no sacred cows allowed.
            <br />— The Skeptics Society
          </Paragraph>
          <Paragraph>
            <Claim confidence={80}>
              But this virtue is often misinterpreted as “be wary and defensive
              to prevent being wrong”
            </Claim>
            . Here’s what this misinterpretation feels like:
          </Paragraph>
          <div style={{ marginLeft: 20 }}>
            <Paragraph>
              We got into pairs, and one partner would tell the other about the
              most meaningful and exciting thing in their lives. In the first
              round, the listener was supposed to listen “skeptically,” without
              using overt facial expressions or body language. In round two,
              they did the same thing, this time listening with an attitude of
              approval and acceptance.
            </Paragraph>
            <Paragraph>
              The difference between the two conversations was striking. As the
              speaker, I experienced the other person’s emotions deep in my own
              belly, as if I was reading their mind. Their doubt was
              heart-wrenching; their approval was like sunshine in the winter.
            </Paragraph>
            <Paragraph>
              — Tiago Forte,{" "}
              <a href="https://praxis.fortelabs.co/groundbreakers/">
                Groundbreakers
              </a>
            </Paragraph>
          </div>
          <Paragraph>
            <Claim confidence={50}>
              The pervasiveness of this doubt and dismissiveness is one of the
              major reasons why grad school is{" "}
              <a href="https://www.benkuhn.net/grad">
                worse for public health than STDs
              </a>
            </Claim>
            .
          </Paragraph>
          <Paragraph>
            Many scientists have an ever-present feeling of vigilance when they
            interact with their peers, fearing they’ll say something incorrect
            and be <em>found out</em>. In their discussions, and (of course)
            academic publications, they only share their most air-tight
            arguments, minimizing adversarial attack surface area.
          </Paragraph>
          <Paragraph>
            But conversations and writing aren’t only a place to display ideas,
            they're generators of ideas. By only allowing polished ideas into
            these environments, it’s not just that you’re not sharing your
            inklings, but you’re not having them. And all big ideas were once
            inklings.
          </Paragraph>
          <Paragraph>
            <Claim confidence={30}>
              Dismissive scientists think their approach guides them towards
              being more correct, but it doesn't
            </Claim>
            . Luckily there’s a way to work towards correctness while still
            engaging with both inkling ideas and polished ones: probabilities.
          </Paragraph>
          <Paragraph>
            By letting readers how confident you are in your claims, as I have,
            you can safely share your ideas unadulterated without fearing that
            your peers will think you believe your hunches are backed by
            rock-solid evidence. You can even put them in papers without
            worrying about it leading to your doom.
          </Paragraph>
          <Paragraph>
            But what do probabilities <em>feel like</em> &mdash; what's the
            visceral difference between 15% and 25%?{" "}
            <Claim confidence={80}>
              This must not have been useful for survival
            </Claim>{" "}
            because we’re terrible at this naturally.{" "}
            <Claim
              confidence={80}
              tooltipContent={
                <div>
                  <div>
                    ~<b>60%</b> is from hearing about my coworker Danny
                    Hernandez's success in teaching Twitch to calibrate their
                    predictions.
                  </div>
                  <div style={{ marginTop: 10 }}>
                    ~<b>10%</b> is from using prediction markets myself and
                    seeing quick improvements.
                  </div>
                  <div style={{ marginTop: 10 }}>
                    ~<b>10%</b> is from hearing anecdotes from others who have
                    improved at prediction markets quickly.
                  </div>
                </div>
              }
            >
              But you can improve your calibration to probabilities in general
              in just a few hours
            </Claim>
            , and that’s a big deal.
          </Paragraph>
          <Paragraph>
            Unlike the tactic of being doubtful and hoping this leads to
            increased correctness,{" "}
            <a href="https://en.wikipedia.org/wiki/Practice_(learning_method)#Deliberate_practice">
              delibately practicing
            </a>{" "}
            prediction trains your correctness directly, and gives you the Brier
            score and cash to prove it.
          </Paragraph>

          <Paragraph>
            <Claim confidence={80}>
              The cost of only publishing bullet-proof arguments is substantive
            </Claim>
            . Look in the notebook of any great scientist and inklings are
            scattered everywhere &mdash; but when was the last time you saw a
            15% confidence idea in a paper? You don’t because{" "}
            <Claim confidence={40}>
              they informally go through and edit out every idea they have less
              than 90% confidence in
            </Claim>
            .
          </Paragraph>
          <Paragraph>
            Scientists who viciously attack fledgling ideas think they’re being
            scientific but they’re actually yielding authority to the polished
            ideas of the past by judging them as if they’re at analogous stages
            of development. Let’s aim for skepticism instead.
          </Paragraph>

          <Paragraph>-</Paragraph>
          <Paragraph>
            Thanks to Ben Mann and Taylor Rogalski for reviewing drafts of this
            essay.
          </Paragraph>
        </contents>
      </post>
    )
  }

  styles = {
    post: {
      width: 750,
    },
    a: {
      color: "rgb(0, 132, 255)",
    },
    img: {
      width: "100%",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
