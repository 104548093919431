import React from 'react'
import { observer } from 'mobx-react'
import Tree from './tree'

@observer
export default class Home extends Component {
  show() {
    const mobile = window.innerWidth < 1250

    return (
      <home $mobile={mobile}>
        <bio>
          <p>
            Hey, I’m Nick. I do research at OpenAI on what neural networks
            learn. It turns out their inner world is beautiful, and I helped try
            to share a glimpse of it with{' '}
            <a href="https://distill.pub/2020/circuits/zoom-in/">Zoom In</a>.
            I'm on the <a href="http://distill.pub">Distill</a> team dreaming up
            new ways of publishing science, like{' '}
            <a href="https://distill.pub/2020/selforg/">scientific threads</a>.
            Outside of machine learning, I care a lot about psychedelics, which
            I believe will give us a broad-spectrum cure to trauma in the short
            term, and long term hopefully let us access more of our vast world
            inside us.
          </p>

          <p>
            Before switching to research I did startups for about a decade. At
            14 I started Upload Robots, a file management web-app that was used
            by a few million people, later co-founded an ed-tech company that
            was acquired by an enterprise learning company, and finally was CEO
            of a Founders Fund backed machine learning company. I angel invest a
            bit, and was in the first rounds of Eaze and Roam Research.
          </p>

          <p>
            I write essays in the hazy space between psychology and philosophy,
            and you might enjoy{' '}
            <a href="http://nickcammarata.com/writing/everything-is-fertile">
              Everything is Fertile
            </a>
            . I also tweet at{' '}
            <a href="https://twitter.com/nickcammarata">@nickcammarata</a>,
            because the internet is incredible and I love spending time with all
            of you. Say hi if we're into some of the same things!
          </p>
        </bio>
        {!mobile && (
          <treesAbs>
            <rel>
              <Tree scale={0.9} maxDepth={11} right={60} />
              <Tree
                scale={0.4}
                config={{
                  lineWidth: 55,
                }}
                maxDepth={8}
                right={-140}
              />
              {/* 800 / 873, width / height = .92 */}
            </rel>
          </treesAbs>
        )}
        {mobile && (
          <tree>
            <Tree
              scale={0.7}
              width={window.innerWidth}
              height={360}
              maxDepth={11}
              right={-30}
            />
          </tree>
        )}
      </home>
    )
  }

  styles = {
    home: {
      paddingBottom: 20,
      display: 'block',
      marginTop: 35,
    },
    tree: {
      position: 'relative',
    },
    line: {
      marginTop: 5,
      marginBottom: 5,
    },
    rel: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    mobile: {
      marginBottom: 100,
      marginTop: `10px !important`,
    },
    treesAbs: {
      position: 'absolute',
      bottom: 0,
      right: 60,
      width: 500,
      height: 500,
    },
    header: {
      fontSize: `1.4rem`,
      fontWeight: 300,
      margin: 0,
      marginBottom: 5,
      padding: 0,
      lineHeight: 1.4,
    },
    spread: {
      flexFlow: 'row',
      justifyContent: 'space-between',
    },
    bioHeader: {
      marginTop: 15,
      marginBottom: 5,
    },
    a: {
      color: `rgba(83,53,10,1)`,
      fontWeight: 500,
      textDecoration: 'underline',
    },
    h2: {
      fontWeight: 600,
      margin: 0,
      padding: 0,
    },
    bio: {
      maxWidth: 680,
      marginTop: 6,
    },
    p: {
      //textAlign: 'justify',
      marginTop: 6,
      lineHeight: 1.5,
      fontSize: 18,
      marginBottom: 6,
    },
    twitter: {
      marginTop: 5,
      flexFlow: 'row',
    },
    handle: {
      marginLeft: 4,
    },
  }
}
