import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Pane } from '../../layers'
import { Icon } from '../../icon'
import { TableRow, TextTableCell } from '../../table'

export default class Option extends PureComponent {
  static propTypes = {
    label: PropTypes.string,
    style: PropTypes.any,
    height: PropTypes.number,
    onSelect: PropTypes.func,
    onDeselect: PropTypes.func,
    isHighlighted: PropTypes.bool,
    isSelected: PropTypes.bool,
    isSelectable: PropTypes.bool
  }

  render() {
    const {
      label,
      onSelect,
      onDeselect,
      isHighlighted,
      isSelected,
      isSelectable,
      style,
      height,
      ...props
    } = this.props

    return (
      <TableRow
        isSelectable={isSelectable}
        isHighlighted={isHighlighted}
        onSelect={onSelect}
        onDeselect={onDeselect}
        isSelected={isSelected}
        style={style}
        display="flex"
        alignItems="center"
        borderBottom={false}
        {...props}
      >
        <Pane
          paddingLeft={12}
          paddingRight={8}
          opacity={isSelected ? 1 : 0}
          flexGrow={0}
          paddingTop={4}
        >
          <Icon color="selected" icon="tick" size={14} />
        </Pane>
        <TextTableCell
          height={height}
          borderBottom="muted"
          textProps={isSelected ? { color: 'selected' } : {}}
          paddingLeft={0}
          borderRight={null}
          flex={1}
          alignSelf="stretch"
        >
          {label}
        </TextTableCell>
      </TableRow>
    )
  }
}
