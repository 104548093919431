export default class PrixFixe extends Component {
  render() {
    const Paragraph = ({ children, ...props }) => (
      <p style={{ fontSize: 18, lineHeight: 1.53 }}>{children}</p>
    )

    const Header = ({ children }) => (
      <h2 style={{ fontWeight: 600, fontSize: 22 }}>{children}</h2>
    )

    const Desc = ({ children }) => (
      <div style={{ marginLeft: 20 }}>{children}</div>
    )

    const Item = ({ children }) => (
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {children}
      </div>
    )

    const When = ({ children }) => (
      <h2 style={{ fontWeight: 600, fontSize: 18, opacity: 0.7 }}>
        {children}
      </h2>
    )

    return (
      <div style={{ paddingBottom: 80 }}>
        <Paragraph>
          When I meet people I often wish to have a tasting menu of what they
          stand for, but instead we usually randomly gradient descent and
          discuss a local minima or two. I really wish I could just see the
          menu. To make it easier for others to do this for me, I made this.
        </Paragraph>
        <Paragraph>
          I tried to only allow items that are important to me, not objectively
          correct (their opposites could be reasonably argued), and I haven't
          heard many others talk about.
        </Paragraph>
        <Paragraph>Here’s my identity, I hope you like it.</Paragraph>
        <div style={{ marginTop: 30 }}>
          <div style={{ textAlign: "center", fontSize: 30 }}>
            NC Tasting Menu, 2019
          </div>
          <div style={{ textAlign: "center", display: "flex", width: 400 }}>
            The menu changes often and may not reflect the latest items.
            Replacements allowed, just ask.
          </div>
        </div>

        <Item>
          <Header>Radiate your beliefs</Header>
          <When>2010</When>
        </Item>
        <Desc>
          <Paragraph>
            The world is a giant ball of people trying to find each other. There
            may be millions of people who are trying to find you to work with
            you, befriend you, and love you. Help them find you! It's extremely
            easy to ignore someone online - don't worry about being a bother (in
            person be more sensitive).
          </Paragraph>
        </Desc>
        <Item>
          <Header>Everything is Fertile</Header>
          <When>2019</When>
        </Item>
        <Desc>
          <Paragraph>You just need the right magic eyes. See essay.</Paragraph>
        </Desc>
        <Item>
          <Header>Try the front door last</Header>
          <When>2011</When>
        </Item>
        <Desc>
          <Paragraph>
            To every nightclub there's three entrances. The first is the front
            door, where you must wait in line for an hour and hope that you look
            cool enough to get in. The second is the VIP entrance, where you
            fistbump the security guard and walk right through. The third is the
            window way in the back. If you break in wearing an apron and pretend
            to be a chef you can make it through the kitchen. Then if you run
            quickly and befriend a few of the right people along the way you'll
            be on the dance floor in no time.
          </Paragraph>
          <Paragraph>
            More things are possible to hack than you think. I was accepted to a
            Good College by manually convincing the vice-president of the school
            in person after a 16 hour drive. I've heard of dozens of similar
            stories with different systems. Even the most strict of systems have
            back doors.
          </Paragraph>
          <Paragraph>Always try the front door last.</Paragraph>
        </Desc>
        <Item>
          <Header>Fight for UBI for the Mind</Header>
          <When>2015</When>
        </Item>

        <Desc>
          <Paragraph>
            Proponents of Universal Basic Income believe that everyone has a
            right to a living wage. I believe that everyone has a right to a
            base level of life satisfaction. There's some hope that this is
            tractable. This is my current goal. I believe that working on AI
            research is the way for me to have the largest marginal impact on
            it.
          </Paragraph>
          <Header>Monopolize Everything</Header>
          <Paragraph>
            Aim to become the best in everything you do, even if it’s narrow. In
            relationships practice Weirding (essay coming soon). Figure out what
            makes you unique and lean on it.
          </Paragraph>
        </Desc>
        <Item>
          <Header>Your Sensors are Tricking You Into an Ego</Header>
          <When>2019</When>
        </Item>
        <Desc>
          <Paragraph>See essay</Paragraph>
        </Desc>
        <Item>
          <Header>Follow your limbic system</Header>
          <When>2016</When>
        </Item>
        <Desc>
          <Paragraph>
            Your cortex sees maps and your limbic system sees territories. If
            you get into your dream school but still feel uncomfortable after a
            year it’s probably not for you.
          </Paragraph>
          <Paragraph>
            "It doesn't matter how beautiful your theory is, it doesn't matter
            how smart you are. If it doesn't agree with experiment, it's wrong."
            - Feynman
          </Paragraph>
        </Desc>
        <Item>
          <Header>Change your limbic system</Header>
          <When>2019</When>
        </Item>
        <Desc>
          <Paragraph>It’s fucking hard, but worth it.</Paragraph>
        </Desc>
        <Item>
          <Header>Heroes over Ideas</Header>
          <When>2013</When>
        </Item>
        <Desc>
          <Paragraph>
            I’m very driven by individual heroes. Find the ones that speak to
            you and study them hard. If you do a job well you should understand
            them better than they understood themselves. My goal is to have a
            few heroes I can mentally simulate and ask new questions to.
          </Paragraph>
        </Desc>
        <Item>
          <Header>The Basics are Powerful</Header>
          <When>2018</When>
        </Item>
        <Desc>
          <Paragraph>
            Try to find the basics that work for you using a combination of life
            satisfaction research, personality tests, and individual
            experimentation (see essay). Stick to those basics and get them
            really right before trying anything fancy. Most people who claim to
            be in bad shape despite having a good diet, exercise, support
            system, and sleep aren't actually doing those things well.
          </Paragraph>
          <Paragraph>
            If these sound interesting and you realize you've been trying to
            find me, please contact me. Also, please make a tasting menu for
            yourself, because I might be looking for you.
          </Paragraph>
        </Desc>
      </div>
    )
  }
}
