import ReactMarkdown from "react-markdown"

const essay = `
Many of you are considering quarantine. But in addition to "can I avoid covid?" you might want to ask "is this a moment of growth?"

Last year I read a lot of deathbed interviews and I was struck by how often long stretches of time away from people's day-to-day routine were among the most meaningful memories in their lives.

It may have been an extended meditation retreat, a winter alone in a cabin deep in mathematics research, or a summer spent on a sea-vessel with their best friend sailing around the Caribbean. Extended time outside their bubble let them see their life with clearer vision. They reflected on these moments of life as some of their most transformative. And those who never took a such a retreat generally wished they had.

Pandemics are tragic and we'll all likely lose people close to us. You can hole up in your apartment with soylent mountains and you'll likely be safe. But you'll be losing a lot of life's beauty during that time.

Instead, you might be able to use the unique incentives that covid has temporarily created as a catalyst to create a moment you might not get to otherwise but may wish you had one day.

A lot of us have family to look after or other obligations that stop us from shifting on a dime.

But for some of you, asking "how could I use this unique time with close friends, family, or alone as a period of growth?" instead of just "how do I dodge the bug?" might transform an otherwise defensive period of sterility into one of your fondest memories.
`

export default class Quarantine extends Component {
  render() {
    return (
      <post className="experiments">
        <row
          style={{
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1>Quarantine</h1>
          <date>Feb, 2020</date>
        </row>
        <ReactMarkdown source={essay} />
      </post>
    )
  }

  styles = {
    post: {
      width: 650,
    },
    strong: {
      display: "inline",
    },
    img: {
      width: "100%",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
