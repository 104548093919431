export default class Intermission extends Component {
  show() {
    const Paragraph = ({ children, ...props }) => (
      <p style={{ fontSize: 20, lineHeight: 1.53, ...props }}>{children}</p>
    )

    const Heading = ({ children, ...props }) => (
      <h2
        style={{
          fontSize: 20,
          fontWeight: 600,
        }}
      >
        {children}
      </h2>
    )

    return (
      <post>
        <row>
          <h1>Intermission</h1>
          <date>June, 2019</date>
        </row>
        <Paragraph>
          Tomorrow morning I'm going to order the most over-the-top breakfast of
          my life, then head to the airport and book a flight somewhere eastward
          that looks interesting. I'll repeat this occasionally over the next
          four weeks until I've circumnavigated the Earth.
        </Paragraph>

        <Paragraph>
          Eight months ago I joined OpenAI and proceeded to grow as a researcher
          faster than I thought I was capable of.
        </Paragraph>

        <Paragraph>
          The night before joining I sent an email to every member of the lab
          introducing myself, then turned off the lights and went to bed.
        </Paragraph>

        <Paragraph>
          After a minute I got out of bed and scrawled this in my journal before
          going back to sleep.
        </Paragraph>

        <Paragraph>
          Tonight I'll write myself another note before I sleep.
        </Paragraph>

        <Paragraph>
          I'm ready for the intermission and I'm excited for part 2.
        </Paragraph>

        <Heading>Note to yourself before joining OpenAI</Heading>

        <Paragraph>
          Keep things light. Be like Feynmann. Talk to anyone. Judge ideas.
        </Paragraph>

        <Paragraph>
          It’s worth spending time to make your relationship with Chris good. He
          likes you a lot. You’re sitting next to him every day, which is so
          cool.
        </Paragraph>

        <Paragraph>
          Don’t think of it has work. You don’t have to work at OpenAI. You get
          to work at OpenAI.
        </Paragraph>
        <Paragraph>
          Be a good learner. It’s okay if you don’t know something, don’t
          pretend to know it. Be good at learning.
        </Paragraph>

        <Paragraph>
          Meet everyone. Take propranolol if it helps. Meet everyone and become
          comfortable with the org. This will make it easier to judge if you
          want to spend more time there.
        </Paragraph>

        <Paragraph>Figure out what your longer term project will be.</Paragraph>

        <Paragraph>
          Measure how much time you spend in flow each day. Aim for 5 hours.
        </Paragraph>

        <Paragraph>
          Don’t worry about meetings. Just meet people and be genuinely
          interested in their work. You might not be at OpenAI for long. You
          should ask them everything you want to know.
        </Paragraph>
        <Paragraph>I love you Nick — good luck!</Paragraph>
      </post>
    )
  }

  styles = {
    quote: {
      marginTop: 8,
      marginBottom: 15,
      lineHeight: 1.3,
      fontSize: 14,
      color: `rgba(0, 0, 0, 0.9)`,
      fontWeight: 600,
    },
    author: {
      alignSelf: "flex-end",
    },
    i: {
      display: "inline",
    },
    img: {
      marginTop: 10,
      marginBottom: 15,
      borderRadius: 5,
    },
    row: {
      justifyContent: "space-between",
      flexFlow: "row",
      alignItems: "center",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
