import { Link } from "react-router-dom"
import { Pane } from "evergreen-ui"

import { H2, H3, Example } from "./components"

const pageWidth = 530

export default class Problem extends Component {
  show() {
    const Paragraph = ({ children, ...props }) => (
      <p style={{ maxWidth: pageWidth, fontSize: 16, lineHeight: 1.4 }}>
        {children}
      </p>
    )
    const Heading = ({ children, ...props }) => (
      <h2
        style={{
          fontWeight: 600,
        }}
      >
        {children}
      </h2>
    )
    return (
      <post>
        <row style={{ maxWidth: pageWidth }}>
          <h1>Sensors</h1>
          <date>May, 2019</date>
        </row>
        <Paragraph>
          I have only one problem — my sensors to the world are attached to Nick
          Cammarata's body; mostly his head.
        </Paragraph>
        <Paragraph>
          Sometimes I think I’m <i style={{ display: "inline" }}>inside</i> his
          head, and my thoughts disproportionally fixate on the well-being of
          Nick.
        </Paragraph>
        <Paragraph>
          When Nick has lots to do I feel overwhelmed. I worry what you think of
          Nick. I feel happy when you compliment Nick.
        </Paragraph>
        <Paragraph>
          The sensors cause me to often forget about the billions of people that
          aren’t Nick.
        </Paragraph>
        <Paragraph>
          The sensors are why I feel different when Nick is alone or on stage.
          They’re why I feel different from you.
        </Paragraph>
        <Paragraph>
          The sensors cause me to have self-centered emotions, and when I
          realize this they melt.
        </Paragraph>
        <Paragraph>So I remind myself to forget myself.</Paragraph>
      </post>
    )
  }

  styles = {
    quote: {
      marginTop: 8,
      marginBottom: 15,
      lineHeight: 1.3,
      fontSize: 14,
      color: `rgba(0, 0, 0, 0.9)`,
      fontWeight: 600,
    },
    author: {
      alignSelf: "flex-end",
    },
    img: {
      marginTop: 10,
      marginBottom: 15,
      borderRadius: 5,
    },
    row: {
      justifyContent: "space-between",
      flexFlow: "row",
      alignItems: "center",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
