module.exports = {
  "grid": [
    {
      "x": 11,
      "y": 12
    },
    {
      "x": 15,
      "y": 10
    },
    {
      "x": 25,
      "y": 24
    },
    {
      "x": 32,
      "y": 24
    },
    {
      "x": 0,
      "y": 21
    },
    {
      "x": 26,
      "y": 20
    },
    {
      "x": 34,
      "y": 34
    },
    {
      "x": 29,
      "y": 23
    },
    {
      "x": 10,
      "y": 31
    },
    {
      "x": 7,
      "y": 1
    },
    {
      "x": 22,
      "y": 24
    },
    {
      "x": 8,
      "y": 7
    },
    {
      "x": 22,
      "y": 2
    },
    {
      "x": 13,
      "y": 12
    },
    {
      "x": 5,
      "y": 6
    },
    {
      "x": 6,
      "y": 3
    },
    {
      "x": 24,
      "y": 12
    },
    {
      "x": 33,
      "y": 0
    },
    {
      "x": 11,
      "y": 32
    },
    {
      "x": 23,
      "y": 22
    },
    {
      "x": 15,
      "y": 12
    },
    {
      "x": 7,
      "y": 18
    },
    {
      "x": 27,
      "y": 34
    },
    {
      "x": 34,
      "y": 33
    },
    {
      "x": 12,
      "y": 6
    },
    {
      "x": 16,
      "y": 3
    },
    {
      "x": 31,
      "y": 7
    },
    {
      "x": 26,
      "y": 10
    },
    {
      "x": 26,
      "y": 29
    },
    {
      "x": 28,
      "y": 4
    },
    {
      "x": 18,
      "y": 11
    },
    {
      "x": 28,
      "y": 31
    },
    {
      "x": 25,
      "y": 10
    },
    {
      "x": 29,
      "y": 10
    },
    {
      "x": 20,
      "y": 15
    },
    {
      "x": 24,
      "y": 25
    },
    {
      "x": 21,
      "y": 14
    },
    {
      "x": 7,
      "y": 32
    },
    {
      "x": 23,
      "y": 30
    },
    {
      "x": 26,
      "y": 30
    },
    {
      "x": 23,
      "y": 17
    },
    {
      "x": 11,
      "y": 9
    },
    {
      "x": 9,
      "y": 13
    },
    {
      "x": 2,
      "y": 15
    },
    {
      "x": 13,
      "y": 31
    },
    {
      "x": 19,
      "y": 1
    },
    {
      "x": 31,
      "y": 14
    },
    {
      "x": 29,
      "y": 14
    },
    {
      "x": 1,
      "y": 18
    },
    {
      "x": 24,
      "y": 30
    },
    {
      "x": 10,
      "y": 13
    },
    {
      "x": 9,
      "y": 30
    },
    {
      "x": 19,
      "y": 34
    },
    {
      "x": 6,
      "y": 25
    },
    {
      "x": 8,
      "y": 20
    },
    {
      "x": 12,
      "y": 11
    },
    {
      "x": 26,
      "y": 25
    },
    {
      "x": 12,
      "y": 28
    },
    {
      "x": 6,
      "y": 23
    },
    {
      "x": 15,
      "y": 5
    },
    {
      "x": 9,
      "y": 2
    },
    {
      "x": 20,
      "y": 33
    },
    {
      "x": 3,
      "y": 19
    },
    {
      "x": 22,
      "y": 6
    },
    {
      "x": 27,
      "y": 0
    },
    {
      "x": 8,
      "y": 25
    },
    {
      "x": 23,
      "y": 25
    },
    {
      "x": 4,
      "y": 22
    },
    {
      "x": 2,
      "y": 27
    },
    {
      "x": 22,
      "y": 18
    },
    {
      "x": 31,
      "y": 18
    },
    {
      "x": 23,
      "y": 0
    },
    {
      "x": 17,
      "y": 2
    },
    {
      "x": 25,
      "y": 18
    },
    {
      "x": 11,
      "y": 16
    },
    {
      "x": 18,
      "y": 8
    },
    {
      "x": 17,
      "y": 0
    },
    {
      "x": 4,
      "y": 25
    },
    {
      "x": 4,
      "y": 3
    },
    {
      "x": 4,
      "y": 7
    },
    {
      "x": 13,
      "y": 11
    },
    {
      "x": 14,
      "y": 16
    },
    {
      "x": 3,
      "y": 1
    },
    {
      "x": 6,
      "y": 19
    },
    {
      "x": 23,
      "y": 19
    },
    {
      "x": 0,
      "y": 1
    },
    {
      "x": 15,
      "y": 3
    },
    {
      "x": 8,
      "y": 22
    },
    {
      "x": 19,
      "y": 10
    },
    {
      "x": 34,
      "y": 27
    },
    {
      "x": 9,
      "y": 25
    },
    {
      "x": 0,
      "y": 33
    },
    {
      "x": 30,
      "y": 22
    },
    {
      "x": 18,
      "y": 20
    },
    {
      "x": 25,
      "y": 9
    },
    {
      "x": 21,
      "y": 21
    },
    {
      "x": 10,
      "y": 19
    },
    {
      "x": 22,
      "y": 28
    },
    {
      "x": 16,
      "y": 23
    },
    {
      "x": 27,
      "y": 27
    },
    {
      "x": 0,
      "y": 22
    },
    {
      "x": 0,
      "y": 4
    },
    {
      "x": 19,
      "y": 23
    },
    {
      "x": 16,
      "y": 25
    },
    {
      "x": 7,
      "y": 9
    },
    {
      "x": 11,
      "y": 14
    },
    {
      "x": 3,
      "y": 14
    },
    {
      "x": 23,
      "y": 20
    },
    {
      "x": 12,
      "y": 30
    },
    {
      "x": 27,
      "y": 6
    },
    {
      "x": 25,
      "y": 27
    },
    {
      "x": 4,
      "y": 27
    },
    {
      "x": 11,
      "y": 18
    },
    {
      "x": 15,
      "y": 30
    },
    {
      "x": 18,
      "y": 26
    },
    {
      "x": 13,
      "y": 28
    },
    {
      "x": 8,
      "y": 30
    },
    {
      "x": 22,
      "y": 30
    },
    {
      "x": 33,
      "y": 8
    },
    {
      "x": 15,
      "y": 9
    },
    {
      "x": 34,
      "y": 9
    },
    {
      "x": 14,
      "y": 10
    },
    {
      "x": 26,
      "y": 34
    },
    {
      "x": 28,
      "y": 13
    },
    {
      "x": 0,
      "y": 24
    },
    {
      "x": 27,
      "y": 15
    },
    {
      "x": 0,
      "y": 30
    },
    {
      "x": 18,
      "y": 1
    },
    {
      "x": 25,
      "y": 34
    },
    {
      "x": 11,
      "y": 27
    },
    {
      "x": 22,
      "y": 9
    },
    {
      "x": 34,
      "y": 32
    },
    {
      "x": 10,
      "y": 21
    },
    {
      "x": 29,
      "y": 30
    },
    {
      "x": 14,
      "y": 21
    },
    {
      "x": 12,
      "y": 14
    },
    {
      "x": 17,
      "y": 23
    },
    {
      "x": 34,
      "y": 13
    },
    {
      "x": 33,
      "y": 34
    },
    {
      "x": 12,
      "y": 10
    },
    {
      "x": 14,
      "y": 24
    },
    {
      "x": 20,
      "y": 11
    },
    {
      "x": 7,
      "y": 30
    },
    {
      "x": 10,
      "y": 26
    },
    {
      "x": 34,
      "y": 26
    },
    {
      "x": 30,
      "y": 21
    },
    {
      "x": 32,
      "y": 34
    },
    {
      "x": 21,
      "y": 20
    },
    {
      "x": 27,
      "y": 3
    },
    {
      "x": 33,
      "y": 10
    },
    {
      "x": 24,
      "y": 23
    },
    {
      "x": 0,
      "y": 3
    },
    {
      "x": 28,
      "y": 11
    },
    {
      "x": 22,
      "y": 17
    },
    {
      "x": 3,
      "y": 4
    },
    {
      "x": 17,
      "y": 4
    },
    {
      "x": 34,
      "y": 31
    },
    {
      "x": 4,
      "y": 10
    },
    {
      "x": 32,
      "y": 7
    },
    {
      "x": 7,
      "y": 8
    },
    {
      "x": 33,
      "y": 33
    },
    {
      "x": 9,
      "y": 32
    },
    {
      "x": 32,
      "y": 21
    },
    {
      "x": 14,
      "y": 14
    },
    {
      "x": 30,
      "y": 24
    },
    {
      "x": 33,
      "y": 9
    },
    {
      "x": 24,
      "y": 24
    },
    {
      "x": 22,
      "y": 27
    },
    {
      "x": 2,
      "y": 28
    },
    {
      "x": 30,
      "y": 5
    },
    {
      "x": 5,
      "y": 26
    },
    {
      "x": 3,
      "y": 24
    },
    {
      "x": 23,
      "y": 28
    },
    {
      "x": 12,
      "y": 0
    },
    {
      "x": 20,
      "y": 4
    },
    {
      "x": 16,
      "y": 12
    },
    {
      "x": 28,
      "y": 14
    },
    {
      "x": 17,
      "y": 29
    },
    {
      "x": 12,
      "y": 22
    },
    {
      "x": 11,
      "y": 26
    },
    {
      "x": 21,
      "y": 18
    },
    {
      "x": 31,
      "y": 13
    },
    {
      "x": 16,
      "y": 5
    },
    {
      "x": 13,
      "y": 22
    },
    {
      "x": 15,
      "y": 6
    },
    {
      "x": 26,
      "y": 5
    },
    {
      "x": 18,
      "y": 6
    },
    {
      "x": 1,
      "y": 2
    },
    {
      "x": 11,
      "y": 34
    },
    {
      "x": 27,
      "y": 31
    },
    {
      "x": 5,
      "y": 10
    },
    {
      "x": 17,
      "y": 16
    },
    {
      "x": 6,
      "y": 16
    },
    {
      "x": 23,
      "y": 12
    },
    {
      "x": 14,
      "y": 6
    },
    {
      "x": 34,
      "y": 23
    },
    {
      "x": 27,
      "y": 21
    },
    {
      "x": 8,
      "y": 29
    },
    {
      "x": 8,
      "y": 31
    },
    {
      "x": 2,
      "y": 8
    },
    {
      "x": 22,
      "y": 10
    },
    {
      "x": 23,
      "y": 7
    },
    {
      "x": 34,
      "y": 10
    },
    {
      "x": 22,
      "y": 31
    },
    {
      "x": 1,
      "y": 23
    },
    {
      "x": 13,
      "y": 1
    },
    {
      "x": 11,
      "y": 0
    },
    {
      "x": 6,
      "y": 1
    },
    {
      "x": 27,
      "y": 13
    },
    {
      "x": 25,
      "y": 12
    },
    {
      "x": 11,
      "y": 8
    },
    {
      "x": 26,
      "y": 19
    },
    {
      "x": 7,
      "y": 13
    },
    {
      "x": 25,
      "y": 29
    },
    {
      "x": 7,
      "y": 23
    },
    {
      "x": 5,
      "y": 24
    },
    {
      "x": 14,
      "y": 18
    },
    {
      "x": 26,
      "y": 27
    },
    {
      "x": 30,
      "y": 6
    },
    {
      "x": 14,
      "y": 33
    },
    {
      "x": 34,
      "y": 12
    },
    {
      "x": 23,
      "y": 24
    },
    {
      "x": 29,
      "y": 6
    },
    {
      "x": 26,
      "y": 6
    },
    {
      "x": 31,
      "y": 34
    },
    {
      "x": 12,
      "y": 20
    },
    {
      "x": 19,
      "y": 22
    },
    {
      "x": 12,
      "y": 7
    },
    {
      "x": 32,
      "y": 10
    },
    {
      "x": 3,
      "y": 5
    },
    {
      "x": 28,
      "y": 7
    },
    {
      "x": 27,
      "y": 5
    },
    {
      "x": 21,
      "y": 10
    },
    {
      "x": 29,
      "y": 5
    },
    {
      "x": 29,
      "y": 15
    },
    {
      "x": 32,
      "y": 19
    },
    {
      "x": 19,
      "y": 7
    },
    {
      "x": 0,
      "y": 20
    },
    {
      "x": 13,
      "y": 9
    },
    {
      "x": 13,
      "y": 0
    },
    {
      "x": 18,
      "y": 16
    },
    {
      "x": 18,
      "y": 12
    },
    {
      "x": 34,
      "y": 22
    },
    {
      "x": 19,
      "y": 14
    },
    {
      "x": 17,
      "y": 22
    },
    {
      "x": 8,
      "y": 10
    },
    {
      "x": 9,
      "y": 1
    },
    {
      "x": 6,
      "y": 28
    },
    {
      "x": 11,
      "y": 11
    },
    {
      "x": 5,
      "y": 21
    },
    {
      "x": 25,
      "y": 7
    },
    {
      "x": 14,
      "y": 11
    },
    {
      "x": 3,
      "y": 7
    },
    {
      "x": 24,
      "y": 31
    },
    {
      "x": 2,
      "y": 14
    },
    {
      "x": 25,
      "y": 13
    },
    {
      "x": 7,
      "y": 20
    },
    {
      "x": 23,
      "y": 34
    },
    {
      "x": 9,
      "y": 31
    },
    {
      "x": 15,
      "y": 23
    },
    {
      "x": 5,
      "y": 28
    },
    {
      "x": 8,
      "y": 19
    },
    {
      "x": 7,
      "y": 31
    },
    {
      "x": 34,
      "y": 6
    },
    {
      "x": 22,
      "y": 23
    },
    {
      "x": 31,
      "y": 10
    },
    {
      "x": 4,
      "y": 33
    },
    {
      "x": 18,
      "y": 9
    },
    {
      "x": 34,
      "y": 17
    },
    {
      "x": 17,
      "y": 21
    },
    {
      "x": 24,
      "y": 13
    },
    {
      "x": 21,
      "y": 25
    },
    {
      "x": 30,
      "y": 9
    },
    {
      "x": 29,
      "y": 26
    },
    {
      "x": 0,
      "y": 6
    },
    {
      "x": 9,
      "y": 20
    },
    {
      "x": 25,
      "y": 4
    },
    {
      "x": 27,
      "y": 4
    },
    {
      "x": 1,
      "y": 21
    },
    {
      "x": 25,
      "y": 26
    },
    {
      "x": 20,
      "y": 8
    },
    {
      "x": 17,
      "y": 34
    },
    {
      "x": 15,
      "y": 19
    },
    {
      "x": 26,
      "y": 26
    },
    {
      "x": 31,
      "y": 11
    },
    {
      "x": 7,
      "y": 26
    },
    {
      "x": 20,
      "y": 14
    },
    {
      "x": 34,
      "y": 30
    },
    {
      "x": 15,
      "y": 25
    },
    {
      "x": 11,
      "y": 10
    },
    {
      "x": 25,
      "y": 6
    },
    {
      "x": 33,
      "y": 23
    },
    {
      "x": 6,
      "y": 6
    },
    {
      "x": 20,
      "y": 19
    },
    {
      "x": 4,
      "y": 8
    },
    {
      "x": 33,
      "y": 1
    },
    {
      "x": 29,
      "y": 16
    },
    {
      "x": 16,
      "y": 19
    },
    {
      "x": 9,
      "y": 11
    },
    {
      "x": 29,
      "y": 33
    },
    {
      "x": 10,
      "y": 16
    },
    {
      "x": 3,
      "y": 29
    },
    {
      "x": 8,
      "y": 18
    },
    {
      "x": 18,
      "y": 34
    },
    {
      "x": 29,
      "y": 12
    },
    {
      "x": 2,
      "y": 30
    },
    {
      "x": 7,
      "y": 19
    },
    {
      "x": 8,
      "y": 26
    },
    {
      "x": 28,
      "y": 21
    },
    {
      "x": 32,
      "y": 14
    },
    {
      "x": 23,
      "y": 15
    },
    {
      "x": 16,
      "y": 29
    },
    {
      "x": 7,
      "y": 16
    },
    {
      "x": 22,
      "y": 5
    },
    {
      "x": 9,
      "y": 4
    },
    {
      "x": 23,
      "y": 4
    },
    {
      "x": 20,
      "y": 24
    },
    {
      "x": 0,
      "y": 34
    },
    {
      "x": 21,
      "y": 3
    },
    {
      "x": 1,
      "y": 6
    },
    {
      "x": 28,
      "y": 17
    },
    {
      "x": 4,
      "y": 34
    },
    {
      "x": 13,
      "y": 3
    },
    {
      "x": 9,
      "y": 21
    },
    {
      "x": 34,
      "y": 8
    },
    {
      "x": 33,
      "y": 5
    },
    {
      "x": 14,
      "y": 22
    },
    {
      "x": 25,
      "y": 31
    },
    {
      "x": 23,
      "y": 18
    },
    {
      "x": 30,
      "y": 10
    },
    {
      "x": 5,
      "y": 3
    },
    {
      "x": 0,
      "y": 25
    },
    {
      "x": 31,
      "y": 0
    },
    {
      "x": 32,
      "y": 13
    },
    {
      "x": 8,
      "y": 27
    },
    {
      "x": 34,
      "y": 29
    },
    {
      "x": 11,
      "y": 4
    },
    {
      "x": 25,
      "y": 14
    },
    {
      "x": 16,
      "y": 21
    },
    {
      "x": 10,
      "y": 9
    },
    {
      "x": 30,
      "y": 11
    },
    {
      "x": 2,
      "y": 6
    },
    {
      "x": 8,
      "y": 23
    },
    {
      "x": 16,
      "y": 0
    },
    {
      "x": 7,
      "y": 22
    },
    {
      "x": 1,
      "y": 4
    },
    {
      "x": 32,
      "y": 4
    },
    {
      "x": 14,
      "y": 13
    },
    {
      "x": 0,
      "y": 7
    },
    {
      "x": 28,
      "y": 12
    },
    {
      "x": 5,
      "y": 22
    },
    {
      "x": 1,
      "y": 1
    },
    {
      "x": 25,
      "y": 22
    },
    {
      "x": 3,
      "y": 11
    },
    {
      "x": 27,
      "y": 18
    },
    {
      "x": 5,
      "y": 1
    },
    {
      "x": 9,
      "y": 6
    },
    {
      "x": 10,
      "y": 25
    },
    {
      "x": 34,
      "y": 7
    },
    {
      "x": 32,
      "y": 15
    },
    {
      "x": 13,
      "y": 6
    },
    {
      "x": 17,
      "y": 3
    },
    {
      "x": 26,
      "y": 12
    },
    {
      "x": 17,
      "y": 25
    },
    {
      "x": 33,
      "y": 2
    },
    {
      "x": 1,
      "y": 27
    },
    {
      "x": 26,
      "y": 11
    },
    {
      "x": 34,
      "y": 28
    },
    {
      "x": 23,
      "y": 11
    },
    {
      "x": 25,
      "y": 11
    },
    {
      "x": 6,
      "y": 2
    },
    {
      "x": 8,
      "y": 21
    },
    {
      "x": 31,
      "y": 3
    },
    {
      "x": 30,
      "y": 4
    },
    {
      "x": 20,
      "y": 16
    },
    {
      "x": 20,
      "y": 34
    },
    {
      "x": 9,
      "y": 5
    },
    {
      "x": 13,
      "y": 8
    },
    {
      "x": 14,
      "y": 5
    },
    {
      "x": 33,
      "y": 27
    },
    {
      "x": 1,
      "y": 34
    },
    {
      "x": 24,
      "y": 28
    },
    {
      "x": 33,
      "y": 32
    },
    {
      "x": 32,
      "y": 8
    },
    {
      "x": 4,
      "y": 6
    },
    {
      "x": 22,
      "y": 19
    },
    {
      "x": 15,
      "y": 33
    },
    {
      "x": 16,
      "y": 32
    },
    {
      "x": 9,
      "y": 16
    },
    {
      "x": 14,
      "y": 0
    },
    {
      "x": 22,
      "y": 26
    },
    {
      "x": 32,
      "y": 6
    },
    {
      "x": 12,
      "y": 33
    },
    {
      "x": 20,
      "y": 25
    },
    {
      "x": 10,
      "y": 18
    },
    {
      "x": 27,
      "y": 16
    },
    {
      "x": 0,
      "y": 11
    },
    {
      "x": 33,
      "y": 15
    },
    {
      "x": 10,
      "y": 1
    },
    {
      "x": 18,
      "y": 23
    },
    {
      "x": 3,
      "y": 27
    },
    {
      "x": 10,
      "y": 14
    },
    {
      "x": 29,
      "y": 25
    },
    {
      "x": 20,
      "y": 10
    },
    {
      "x": 6,
      "y": 33
    },
    {
      "x": 23,
      "y": 5
    },
    {
      "x": 0,
      "y": 16
    },
    {
      "x": 15,
      "y": 27
    },
    {
      "x": 8,
      "y": 15
    },
    {
      "x": 11,
      "y": 7
    },
    {
      "x": 2,
      "y": 12
    },
    {
      "x": 23,
      "y": 3
    },
    {
      "x": 25,
      "y": 16
    },
    {
      "x": 0,
      "y": 29
    },
    {
      "x": 22,
      "y": 25
    },
    {
      "x": 33,
      "y": 13
    },
    {
      "x": 10,
      "y": 12
    },
    {
      "x": 11,
      "y": 15
    },
    {
      "x": 7,
      "y": 27
    },
    {
      "x": 28,
      "y": 10
    },
    {
      "x": 3,
      "y": 18
    },
    {
      "x": 31,
      "y": 6
    },
    {
      "x": 20,
      "y": 7
    },
    {
      "x": 32,
      "y": 26
    },
    {
      "x": 31,
      "y": 4
    },
    {
      "x": 18,
      "y": 18
    },
    {
      "x": 6,
      "y": 17
    },
    {
      "x": 27,
      "y": 28
    },
    {
      "x": 22,
      "y": 8
    },
    {
      "x": 28,
      "y": 9
    },
    {
      "x": 9,
      "y": 29
    },
    {
      "x": 29,
      "y": 21
    },
    {
      "x": 20,
      "y": 12
    },
    {
      "x": 18,
      "y": 28
    },
    {
      "x": 23,
      "y": 8
    },
    {
      "x": 6,
      "y": 15
    },
    {
      "x": 4,
      "y": 17
    },
    {
      "x": 3,
      "y": 9
    },
    {
      "x": 4,
      "y": 2
    },
    {
      "x": 24,
      "y": 6
    },
    {
      "x": 33,
      "y": 31
    },
    {
      "x": 33,
      "y": 30
    },
    {
      "x": 24,
      "y": 11
    },
    {
      "x": 10,
      "y": 7
    },
    {
      "x": 7,
      "y": 0
    },
    {
      "x": 30,
      "y": 14
    },
    {
      "x": 11,
      "y": 30
    },
    {
      "x": 15,
      "y": 1
    },
    {
      "x": 27,
      "y": 12
    },
    {
      "x": 20,
      "y": 23
    },
    {
      "x": 10,
      "y": 24
    },
    {
      "x": 24,
      "y": 7
    },
    {
      "x": 14,
      "y": 32
    },
    {
      "x": 0,
      "y": 28
    },
    {
      "x": 17,
      "y": 24
    },
    {
      "x": 19,
      "y": 27
    },
    {
      "x": 26,
      "y": 18
    },
    {
      "x": 32,
      "y": 33
    },
    {
      "x": 4,
      "y": 0
    },
    {
      "x": 13,
      "y": 18
    },
    {
      "x": 26,
      "y": 28
    },
    {
      "x": 8,
      "y": 24
    },
    {
      "x": 2,
      "y": 5
    },
    {
      "x": 14,
      "y": 31
    },
    {
      "x": 6,
      "y": 5
    },
    {
      "x": 10,
      "y": 8
    },
    {
      "x": 17,
      "y": 26
    },
    {
      "x": 1,
      "y": 32
    },
    {
      "x": 18,
      "y": 3
    },
    {
      "x": 0,
      "y": 32
    },
    {
      "x": 30,
      "y": 34
    },
    {
      "x": 25,
      "y": 25
    },
    {
      "x": 22,
      "y": 34
    },
    {
      "x": 26,
      "y": 32
    },
    {
      "x": 17,
      "y": 19
    },
    {
      "x": 11,
      "y": 29
    },
    {
      "x": 30,
      "y": 16
    },
    {
      "x": 8,
      "y": 9
    },
    {
      "x": 28,
      "y": 29
    },
    {
      "x": 13,
      "y": 32
    },
    {
      "x": 31,
      "y": 16
    },
    {
      "x": 25,
      "y": 3
    },
    {
      "x": 7,
      "y": 11
    },
    {
      "x": 11,
      "y": 24
    },
    {
      "x": 22,
      "y": 11
    },
    {
      "x": 13,
      "y": 30
    },
    {
      "x": 17,
      "y": 18
    },
    {
      "x": 26,
      "y": 23
    },
    {
      "x": 10,
      "y": 34
    },
    {
      "x": 1,
      "y": 30
    },
    {
      "x": 10,
      "y": 2
    },
    {
      "x": 21,
      "y": 13
    },
    {
      "x": 34,
      "y": 20
    },
    {
      "x": 13,
      "y": 25
    },
    {
      "x": 0,
      "y": 18
    },
    {
      "x": 14,
      "y": 20
    },
    {
      "x": 30,
      "y": 13
    },
    {
      "x": 33,
      "y": 6
    },
    {
      "x": 34,
      "y": 24
    },
    {
      "x": 1,
      "y": 15
    },
    {
      "x": 9,
      "y": 9
    },
    {
      "x": 2,
      "y": 4
    },
    {
      "x": 19,
      "y": 31
    },
    {
      "x": 19,
      "y": 12
    },
    {
      "x": 5,
      "y": 17
    },
    {
      "x": 7,
      "y": 33
    },
    {
      "x": 22,
      "y": 29
    },
    {
      "x": 16,
      "y": 16
    },
    {
      "x": 18,
      "y": 4
    },
    {
      "x": 19,
      "y": 33
    },
    {
      "x": 17,
      "y": 1
    },
    {
      "x": 30,
      "y": 1
    },
    {
      "x": 23,
      "y": 2
    },
    {
      "x": 4,
      "y": 18
    },
    {
      "x": 11,
      "y": 17
    },
    {
      "x": 11,
      "y": 2
    },
    {
      "x": 9,
      "y": 26
    },
    {
      "x": 13,
      "y": 34
    },
    {
      "x": 23,
      "y": 23
    },
    {
      "x": 23,
      "y": 21
    },
    {
      "x": 16,
      "y": 6
    },
    {
      "x": 34,
      "y": 14
    },
    {
      "x": 29,
      "y": 13
    },
    {
      "x": 12,
      "y": 32
    },
    {
      "x": 8,
      "y": 4
    },
    {
      "x": 1,
      "y": 0
    },
    {
      "x": 7,
      "y": 6
    },
    {
      "x": 20,
      "y": 3
    },
    {
      "x": 10,
      "y": 4
    },
    {
      "x": 24,
      "y": 27
    },
    {
      "x": 19,
      "y": 26
    },
    {
      "x": 28,
      "y": 1
    },
    {
      "x": 12,
      "y": 9
    },
    {
      "x": 24,
      "y": 34
    },
    {
      "x": 3,
      "y": 25
    },
    {
      "x": 20,
      "y": 28
    },
    {
      "x": 23,
      "y": 10
    },
    {
      "x": 19,
      "y": 16
    },
    {
      "x": 33,
      "y": 19
    },
    {
      "x": 17,
      "y": 32
    },
    {
      "x": 34,
      "y": 25
    },
    {
      "x": 1,
      "y": 3
    },
    {
      "x": 12,
      "y": 13
    },
    {
      "x": 6,
      "y": 18
    },
    {
      "x": 28,
      "y": 25
    },
    {
      "x": 27,
      "y": 20
    },
    {
      "x": 6,
      "y": 7
    },
    {
      "x": 0,
      "y": 5
    },
    {
      "x": 13,
      "y": 20
    },
    {
      "x": 12,
      "y": 23
    },
    {
      "x": 22,
      "y": 32
    },
    {
      "x": 26,
      "y": 14
    },
    {
      "x": 23,
      "y": 6
    },
    {
      "x": 26,
      "y": 31
    },
    {
      "x": 19,
      "y": 21
    },
    {
      "x": 7,
      "y": 34
    },
    {
      "x": 32,
      "y": 9
    },
    {
      "x": 28,
      "y": 0
    },
    {
      "x": 9,
      "y": 34
    },
    {
      "x": 34,
      "y": 18
    },
    {
      "x": 3,
      "y": 21
    },
    {
      "x": 3,
      "y": 28
    },
    {
      "x": 14,
      "y": 8
    },
    {
      "x": 28,
      "y": 3
    },
    {
      "x": 16,
      "y": 7
    },
    {
      "x": 31,
      "y": 23
    },
    {
      "x": 18,
      "y": 30
    },
    {
      "x": 5,
      "y": 31
    },
    {
      "x": 6,
      "y": 0
    },
    {
      "x": 33,
      "y": 21
    },
    {
      "x": 15,
      "y": 8
    },
    {
      "x": 12,
      "y": 25
    },
    {
      "x": 18,
      "y": 33
    },
    {
      "x": 4,
      "y": 29
    },
    {
      "x": 29,
      "y": 34
    },
    {
      "x": 34,
      "y": 15
    },
    {
      "x": 6,
      "y": 29
    },
    {
      "x": 30,
      "y": 15
    },
    {
      "x": 12,
      "y": 8
    },
    {
      "x": 13,
      "y": 15
    },
    {
      "x": 26,
      "y": 13
    },
    {
      "x": 4,
      "y": 5
    },
    {
      "x": 22,
      "y": 4
    },
    {
      "x": 13,
      "y": 29
    },
    {
      "x": 9,
      "y": 17
    },
    {
      "x": 10,
      "y": 32
    },
    {
      "x": 16,
      "y": 10
    },
    {
      "x": 3,
      "y": 26
    },
    {
      "x": 25,
      "y": 20
    },
    {
      "x": 19,
      "y": 19
    },
    {
      "x": 8,
      "y": 2
    },
    {
      "x": 27,
      "y": 23
    },
    {
      "x": 5,
      "y": 27
    },
    {
      "x": 28,
      "y": 34
    },
    {
      "x": 27,
      "y": 26
    },
    {
      "x": 32,
      "y": 2
    },
    {
      "x": 31,
      "y": 33
    },
    {
      "x": 26,
      "y": 4
    },
    {
      "x": 27,
      "y": 1
    },
    {
      "x": 18,
      "y": 17
    },
    {
      "x": 8,
      "y": 33
    },
    {
      "x": 31,
      "y": 25
    },
    {
      "x": 0,
      "y": 0
    },
    {
      "x": 11,
      "y": 20
    },
    {
      "x": 1,
      "y": 22
    },
    {
      "x": 29,
      "y": 1
    },
    {
      "x": 1,
      "y": 29
    },
    {
      "x": 16,
      "y": 18
    },
    {
      "x": 3,
      "y": 33
    },
    {
      "x": 32,
      "y": 32
    },
    {
      "x": 24,
      "y": 32
    },
    {
      "x": 6,
      "y": 26
    },
    {
      "x": 0,
      "y": 14
    },
    {
      "x": 2,
      "y": 2
    },
    {
      "x": 31,
      "y": 15
    },
    {
      "x": 13,
      "y": 4
    },
    {
      "x": 31,
      "y": 17
    },
    {
      "x": 29,
      "y": 7
    },
    {
      "x": 21,
      "y": 27
    },
    {
      "x": 10,
      "y": 33
    },
    {
      "x": 5,
      "y": 4
    },
    {
      "x": 15,
      "y": 34
    },
    {
      "x": 25,
      "y": 19
    },
    {
      "x": 23,
      "y": 9
    },
    {
      "x": 17,
      "y": 31
    },
    {
      "x": 8,
      "y": 14
    },
    {
      "x": 5,
      "y": 2
    },
    {
      "x": 30,
      "y": 2
    },
    {
      "x": 28,
      "y": 28
    },
    {
      "x": 32,
      "y": 31
    },
    {
      "x": 2,
      "y": 0
    },
    {
      "x": 8,
      "y": 16
    },
    {
      "x": 25,
      "y": 15
    },
    {
      "x": 21,
      "y": 34
    },
    {
      "x": 28,
      "y": 16
    },
    {
      "x": 15,
      "y": 22
    },
    {
      "x": 30,
      "y": 33
    },
    {
      "x": 21,
      "y": 22
    },
    {
      "x": 25,
      "y": 33
    },
    {
      "x": 15,
      "y": 28
    },
    {
      "x": 31,
      "y": 19
    },
    {
      "x": 34,
      "y": 19
    },
    {
      "x": 31,
      "y": 2
    },
    {
      "x": 9,
      "y": 24
    },
    {
      "x": 1,
      "y": 20
    },
    {
      "x": 20,
      "y": 22
    },
    {
      "x": 11,
      "y": 13
    },
    {
      "x": 16,
      "y": 26
    },
    {
      "x": 22,
      "y": 7
    },
    {
      "x": 17,
      "y": 17
    },
    {
      "x": 9,
      "y": 18
    },
    {
      "x": 0,
      "y": 15
    },
    {
      "x": 9,
      "y": 8
    },
    {
      "x": 19,
      "y": 17
    },
    {
      "x": 5,
      "y": 23
    },
    {
      "x": 15,
      "y": 13
    },
    {
      "x": 29,
      "y": 31
    },
    {
      "x": 16,
      "y": 30
    },
    {
      "x": 24,
      "y": 1
    },
    {
      "x": 15,
      "y": 24
    },
    {
      "x": 19,
      "y": 30
    },
    {
      "x": 24,
      "y": 4
    },
    {
      "x": 2,
      "y": 29
    },
    {
      "x": 5,
      "y": 11
    },
    {
      "x": 29,
      "y": 2
    },
    {
      "x": 19,
      "y": 29
    },
    {
      "x": 14,
      "y": 4
    },
    {
      "x": 31,
      "y": 26
    },
    {
      "x": 0,
      "y": 12
    },
    {
      "x": 30,
      "y": 0
    },
    {
      "x": 22,
      "y": 16
    },
    {
      "x": 28,
      "y": 30
    },
    {
      "x": 20,
      "y": 17
    },
    {
      "x": 33,
      "y": 22
    },
    {
      "x": 10,
      "y": 28
    },
    {
      "x": 18,
      "y": 19
    },
    {
      "x": 10,
      "y": 17
    },
    {
      "x": 2,
      "y": 26
    },
    {
      "x": 29,
      "y": 24
    },
    {
      "x": 26,
      "y": 33
    },
    {
      "x": 8,
      "y": 28
    },
    {
      "x": 6,
      "y": 31
    },
    {
      "x": 13,
      "y": 16
    },
    {
      "x": 33,
      "y": 26
    },
    {
      "x": 30,
      "y": 3
    },
    {
      "x": 21,
      "y": 6
    },
    {
      "x": 9,
      "y": 7
    },
    {
      "x": 2,
      "y": 22
    },
    {
      "x": 16,
      "y": 24
    },
    {
      "x": 30,
      "y": 17
    },
    {
      "x": 12,
      "y": 34
    },
    {
      "x": 10,
      "y": 15
    },
    {
      "x": 33,
      "y": 29
    },
    {
      "x": 5,
      "y": 33
    },
    {
      "x": 16,
      "y": 4
    },
    {
      "x": 28,
      "y": 5
    },
    {
      "x": 24,
      "y": 15
    },
    {
      "x": 3,
      "y": 3
    },
    {
      "x": 5,
      "y": 13
    },
    {
      "x": 27,
      "y": 25
    },
    {
      "x": 20,
      "y": 18
    },
    {
      "x": 4,
      "y": 14
    },
    {
      "x": 23,
      "y": 1
    },
    {
      "x": 34,
      "y": 3
    },
    {
      "x": 21,
      "y": 17
    },
    {
      "x": 20,
      "y": 2
    },
    {
      "x": 31,
      "y": 21
    },
    {
      "x": 33,
      "y": 28
    },
    {
      "x": 17,
      "y": 10
    },
    {
      "x": 3,
      "y": 6
    },
    {
      "x": 2,
      "y": 18
    },
    {
      "x": 23,
      "y": 33
    },
    {
      "x": 10,
      "y": 6
    },
    {
      "x": 6,
      "y": 11
    },
    {
      "x": 21,
      "y": 31
    },
    {
      "x": 2,
      "y": 10
    },
    {
      "x": 30,
      "y": 23
    },
    {
      "x": 13,
      "y": 14
    },
    {
      "x": 16,
      "y": 34
    },
    {
      "x": 1,
      "y": 10
    },
    {
      "x": 1,
      "y": 28
    },
    {
      "x": 1,
      "y": 7
    },
    {
      "x": 19,
      "y": 0
    },
    {
      "x": 15,
      "y": 4
    },
    {
      "x": 8,
      "y": 0
    },
    {
      "x": 30,
      "y": 7
    },
    {
      "x": 15,
      "y": 11
    },
    {
      "x": 2,
      "y": 1
    },
    {
      "x": 4,
      "y": 4
    },
    {
      "x": 25,
      "y": 1
    },
    {
      "x": 0,
      "y": 8
    },
    {
      "x": 7,
      "y": 15
    },
    {
      "x": 12,
      "y": 16
    },
    {
      "x": 33,
      "y": 16
    },
    {
      "x": 24,
      "y": 2
    },
    {
      "x": 26,
      "y": 3
    },
    {
      "x": 32,
      "y": 30
    },
    {
      "x": 32,
      "y": 0
    },
    {
      "x": 10,
      "y": 20
    },
    {
      "x": 3,
      "y": 20
    },
    {
      "x": 21,
      "y": 7
    },
    {
      "x": 18,
      "y": 21
    },
    {
      "x": 29,
      "y": 28
    },
    {
      "x": 1,
      "y": 13
    },
    {
      "x": 13,
      "y": 2
    },
    {
      "x": 23,
      "y": 31
    },
    {
      "x": 33,
      "y": 14
    },
    {
      "x": 14,
      "y": 1
    },
    {
      "x": 18,
      "y": 5
    },
    {
      "x": 2,
      "y": 11
    },
    {
      "x": 11,
      "y": 1
    },
    {
      "x": 5,
      "y": 7
    },
    {
      "x": 29,
      "y": 17
    },
    {
      "x": 33,
      "y": 11
    },
    {
      "x": 2,
      "y": 23
    },
    {
      "x": 34,
      "y": 5
    },
    {
      "x": 7,
      "y": 14
    },
    {
      "x": 31,
      "y": 20
    },
    {
      "x": 31,
      "y": 32
    },
    {
      "x": 34,
      "y": 0
    },
    {
      "x": 27,
      "y": 9
    },
    {
      "x": 22,
      "y": 15
    },
    {
      "x": 16,
      "y": 1
    },
    {
      "x": 21,
      "y": 33
    },
    {
      "x": 22,
      "y": 20
    },
    {
      "x": 24,
      "y": 19
    },
    {
      "x": 1,
      "y": 8
    },
    {
      "x": 2,
      "y": 25
    },
    {
      "x": 32,
      "y": 3
    },
    {
      "x": 8,
      "y": 1
    },
    {
      "x": 32,
      "y": 29
    },
    {
      "x": 24,
      "y": 26
    },
    {
      "x": 28,
      "y": 27
    },
    {
      "x": 24,
      "y": 8
    },
    {
      "x": 0,
      "y": 31
    },
    {
      "x": 20,
      "y": 29
    },
    {
      "x": 30,
      "y": 32
    },
    {
      "x": 10,
      "y": 27
    },
    {
      "x": 2,
      "y": 24
    },
    {
      "x": 27,
      "y": 8
    },
    {
      "x": 21,
      "y": 19
    },
    {
      "x": 5,
      "y": 34
    },
    {
      "x": 12,
      "y": 15
    },
    {
      "x": 25,
      "y": 21
    },
    {
      "x": 26,
      "y": 9
    },
    {
      "x": 13,
      "y": 33
    },
    {
      "x": 14,
      "y": 2
    },
    {
      "x": 26,
      "y": 17
    },
    {
      "x": 3,
      "y": 22
    },
    {
      "x": 9,
      "y": 19
    },
    {
      "x": 33,
      "y": 12
    },
    {
      "x": 4,
      "y": 30
    },
    {
      "x": 29,
      "y": 22
    },
    {
      "x": 3,
      "y": 34
    },
    {
      "x": 10,
      "y": 0
    },
    {
      "x": 19,
      "y": 5
    },
    {
      "x": 29,
      "y": 32
    },
    {
      "x": 23,
      "y": 29
    },
    {
      "x": 0,
      "y": 23
    },
    {
      "x": 29,
      "y": 27
    },
    {
      "x": 5,
      "y": 9
    },
    {
      "x": 1,
      "y": 33
    },
    {
      "x": 29,
      "y": 9
    },
    {
      "x": 17,
      "y": 12
    },
    {
      "x": 19,
      "y": 25
    },
    {
      "x": 31,
      "y": 1
    },
    {
      "x": 21,
      "y": 9
    },
    {
      "x": 28,
      "y": 22
    },
    {
      "x": 18,
      "y": 27
    },
    {
      "x": 11,
      "y": 28
    },
    {
      "x": 4,
      "y": 23
    },
    {
      "x": 17,
      "y": 5
    },
    {
      "x": 16,
      "y": 2
    },
    {
      "x": 0,
      "y": 27
    },
    {
      "x": 4,
      "y": 19
    },
    {
      "x": 16,
      "y": 13
    },
    {
      "x": 24,
      "y": 3
    },
    {
      "x": 18,
      "y": 13
    },
    {
      "x": 12,
      "y": 17
    },
    {
      "x": 26,
      "y": 22
    },
    {
      "x": 27,
      "y": 33
    },
    {
      "x": 24,
      "y": 33
    },
    {
      "x": 10,
      "y": 10
    },
    {
      "x": 22,
      "y": 1
    },
    {
      "x": 11,
      "y": 3
    },
    {
      "x": 17,
      "y": 28
    },
    {
      "x": 8,
      "y": 11
    },
    {
      "x": 20,
      "y": 13
    },
    {
      "x": 0,
      "y": 17
    },
    {
      "x": 5,
      "y": 0
    },
    {
      "x": 6,
      "y": 34
    },
    {
      "x": 14,
      "y": 17
    },
    {
      "x": 29,
      "y": 29
    },
    {
      "x": 22,
      "y": 33
    },
    {
      "x": 9,
      "y": 22
    },
    {
      "x": 7,
      "y": 7
    },
    {
      "x": 5,
      "y": 19
    },
    {
      "x": 29,
      "y": 20
    },
    {
      "x": 1,
      "y": 5
    },
    {
      "x": 9,
      "y": 28
    },
    {
      "x": 33,
      "y": 3
    },
    {
      "x": 25,
      "y": 8
    },
    {
      "x": 4,
      "y": 26
    },
    {
      "x": 1,
      "y": 19
    },
    {
      "x": 10,
      "y": 23
    },
    {
      "x": 14,
      "y": 19
    },
    {
      "x": 5,
      "y": 8
    },
    {
      "x": 34,
      "y": 21
    },
    {
      "x": 10,
      "y": 30
    },
    {
      "x": 11,
      "y": 21
    },
    {
      "x": 9,
      "y": 27
    },
    {
      "x": 26,
      "y": 8
    },
    {
      "x": 19,
      "y": 24
    },
    {
      "x": 22,
      "y": 13
    },
    {
      "x": 12,
      "y": 24
    },
    {
      "x": 27,
      "y": 30
    },
    {
      "x": 31,
      "y": 31
    },
    {
      "x": 22,
      "y": 21
    },
    {
      "x": 17,
      "y": 6
    },
    {
      "x": 29,
      "y": 11
    },
    {
      "x": 8,
      "y": 13
    },
    {
      "x": 29,
      "y": 0
    },
    {
      "x": 14,
      "y": 34
    },
    {
      "x": 28,
      "y": 24
    },
    {
      "x": 7,
      "y": 17
    },
    {
      "x": 30,
      "y": 20
    },
    {
      "x": 25,
      "y": 2
    },
    {
      "x": 28,
      "y": 26
    },
    {
      "x": 16,
      "y": 8
    },
    {
      "x": 10,
      "y": 29
    },
    {
      "x": 21,
      "y": 12
    },
    {
      "x": 4,
      "y": 28
    },
    {
      "x": 32,
      "y": 28
    },
    {
      "x": 10,
      "y": 5
    },
    {
      "x": 15,
      "y": 15
    },
    {
      "x": 28,
      "y": 19
    },
    {
      "x": 15,
      "y": 20
    },
    {
      "x": 14,
      "y": 9
    },
    {
      "x": 8,
      "y": 12
    },
    {
      "x": 12,
      "y": 26
    },
    {
      "x": 33,
      "y": 7
    },
    {
      "x": 24,
      "y": 18
    },
    {
      "x": 16,
      "y": 15
    },
    {
      "x": 5,
      "y": 25
    },
    {
      "x": 4,
      "y": 31
    },
    {
      "x": 3,
      "y": 15
    },
    {
      "x": 13,
      "y": 23
    },
    {
      "x": 19,
      "y": 13
    },
    {
      "x": 25,
      "y": 5
    },
    {
      "x": 21,
      "y": 2
    },
    {
      "x": 22,
      "y": 12
    },
    {
      "x": 7,
      "y": 12
    },
    {
      "x": 18,
      "y": 15
    },
    {
      "x": 27,
      "y": 2
    },
    {
      "x": 33,
      "y": 17
    },
    {
      "x": 15,
      "y": 26
    },
    {
      "x": 2,
      "y": 3
    },
    {
      "x": 5,
      "y": 18
    },
    {
      "x": 34,
      "y": 4
    },
    {
      "x": 20,
      "y": 27
    },
    {
      "x": 15,
      "y": 32
    },
    {
      "x": 28,
      "y": 2
    },
    {
      "x": 2,
      "y": 13
    },
    {
      "x": 6,
      "y": 20
    },
    {
      "x": 4,
      "y": 1
    },
    {
      "x": 14,
      "y": 30
    },
    {
      "x": 17,
      "y": 15
    },
    {
      "x": 8,
      "y": 3
    },
    {
      "x": 19,
      "y": 32
    },
    {
      "x": 6,
      "y": 21
    },
    {
      "x": 13,
      "y": 27
    },
    {
      "x": 30,
      "y": 27
    },
    {
      "x": 25,
      "y": 30
    },
    {
      "x": 19,
      "y": 6
    },
    {
      "x": 16,
      "y": 17
    },
    {
      "x": 32,
      "y": 1
    },
    {
      "x": 13,
      "y": 24
    },
    {
      "x": 2,
      "y": 21
    },
    {
      "x": 7,
      "y": 4
    },
    {
      "x": 27,
      "y": 7
    },
    {
      "x": 0,
      "y": 2
    },
    {
      "x": 21,
      "y": 26
    },
    {
      "x": 28,
      "y": 18
    },
    {
      "x": 11,
      "y": 25
    },
    {
      "x": 30,
      "y": 31
    },
    {
      "x": 24,
      "y": 14
    },
    {
      "x": 12,
      "y": 27
    },
    {
      "x": 34,
      "y": 11
    },
    {
      "x": 29,
      "y": 3
    },
    {
      "x": 28,
      "y": 33
    },
    {
      "x": 17,
      "y": 20
    },
    {
      "x": 6,
      "y": 10
    },
    {
      "x": 27,
      "y": 22
    },
    {
      "x": 5,
      "y": 14
    },
    {
      "x": 9,
      "y": 3
    },
    {
      "x": 13,
      "y": 13
    },
    {
      "x": 15,
      "y": 29
    },
    {
      "x": 15,
      "y": 21
    },
    {
      "x": 7,
      "y": 5
    },
    {
      "x": 9,
      "y": 0
    },
    {
      "x": 14,
      "y": 12
    },
    {
      "x": 22,
      "y": 14
    },
    {
      "x": 0,
      "y": 13
    },
    {
      "x": 7,
      "y": 21
    },
    {
      "x": 32,
      "y": 18
    },
    {
      "x": 0,
      "y": 10
    },
    {
      "x": 18,
      "y": 0
    },
    {
      "x": 19,
      "y": 9
    },
    {
      "x": 6,
      "y": 24
    },
    {
      "x": 1,
      "y": 16
    },
    {
      "x": 16,
      "y": 31
    },
    {
      "x": 23,
      "y": 14
    },
    {
      "x": 14,
      "y": 28
    },
    {
      "x": 25,
      "y": 28
    },
    {
      "x": 1,
      "y": 9
    },
    {
      "x": 25,
      "y": 23
    },
    {
      "x": 21,
      "y": 8
    },
    {
      "x": 28,
      "y": 15
    },
    {
      "x": 21,
      "y": 0
    },
    {
      "x": 14,
      "y": 23
    },
    {
      "x": 8,
      "y": 6
    },
    {
      "x": 8,
      "y": 32
    },
    {
      "x": 13,
      "y": 7
    },
    {
      "x": 28,
      "y": 32
    },
    {
      "x": 7,
      "y": 24
    },
    {
      "x": 26,
      "y": 15
    },
    {
      "x": 16,
      "y": 20
    },
    {
      "x": 26,
      "y": 2
    },
    {
      "x": 7,
      "y": 25
    },
    {
      "x": 13,
      "y": 26
    },
    {
      "x": 28,
      "y": 8
    },
    {
      "x": 3,
      "y": 23
    },
    {
      "x": 24,
      "y": 16
    },
    {
      "x": 33,
      "y": 20
    },
    {
      "x": 23,
      "y": 16
    },
    {
      "x": 19,
      "y": 4
    },
    {
      "x": 3,
      "y": 12
    },
    {
      "x": 24,
      "y": 22
    },
    {
      "x": 20,
      "y": 0
    },
    {
      "x": 20,
      "y": 32
    },
    {
      "x": 22,
      "y": 3
    },
    {
      "x": 6,
      "y": 14
    },
    {
      "x": 21,
      "y": 4
    },
    {
      "x": 30,
      "y": 8
    },
    {
      "x": 0,
      "y": 19
    },
    {
      "x": 24,
      "y": 20
    },
    {
      "x": 4,
      "y": 20
    },
    {
      "x": 34,
      "y": 2
    },
    {
      "x": 31,
      "y": 29
    },
    {
      "x": 7,
      "y": 2
    },
    {
      "x": 11,
      "y": 19
    },
    {
      "x": 14,
      "y": 25
    },
    {
      "x": 16,
      "y": 28
    },
    {
      "x": 3,
      "y": 30
    },
    {
      "x": 17,
      "y": 30
    },
    {
      "x": 7,
      "y": 3
    },
    {
      "x": 19,
      "y": 18
    },
    {
      "x": 14,
      "y": 15
    },
    {
      "x": 14,
      "y": 7
    },
    {
      "x": 21,
      "y": 32
    },
    {
      "x": 4,
      "y": 9
    },
    {
      "x": 26,
      "y": 7
    },
    {
      "x": 11,
      "y": 33
    },
    {
      "x": 9,
      "y": 23
    },
    {
      "x": 3,
      "y": 31
    },
    {
      "x": 6,
      "y": 8
    },
    {
      "x": 27,
      "y": 29
    },
    {
      "x": 20,
      "y": 5
    },
    {
      "x": 5,
      "y": 16
    },
    {
      "x": 10,
      "y": 11
    },
    {
      "x": 20,
      "y": 1
    },
    {
      "x": 14,
      "y": 27
    },
    {
      "x": 33,
      "y": 18
    },
    {
      "x": 31,
      "y": 30
    },
    {
      "x": 32,
      "y": 20
    },
    {
      "x": 3,
      "y": 13
    },
    {
      "x": 12,
      "y": 4
    },
    {
      "x": 15,
      "y": 7
    },
    {
      "x": 18,
      "y": 29
    },
    {
      "x": 13,
      "y": 17
    },
    {
      "x": 3,
      "y": 32
    },
    {
      "x": 21,
      "y": 24
    },
    {
      "x": 16,
      "y": 22
    },
    {
      "x": 20,
      "y": 26
    },
    {
      "x": 10,
      "y": 22
    },
    {
      "x": 6,
      "y": 9
    },
    {
      "x": 24,
      "y": 9
    },
    {
      "x": 19,
      "y": 20
    },
    {
      "x": 30,
      "y": 18
    },
    {
      "x": 26,
      "y": 21
    },
    {
      "x": 17,
      "y": 13
    },
    {
      "x": 18,
      "y": 2
    },
    {
      "x": 32,
      "y": 5
    },
    {
      "x": 11,
      "y": 5
    },
    {
      "x": 4,
      "y": 32
    },
    {
      "x": 5,
      "y": 32
    },
    {
      "x": 4,
      "y": 24
    },
    {
      "x": 16,
      "y": 14
    },
    {
      "x": 14,
      "y": 3
    },
    {
      "x": 31,
      "y": 28
    },
    {
      "x": 15,
      "y": 2
    },
    {
      "x": 1,
      "y": 31
    },
    {
      "x": 2,
      "y": 17
    },
    {
      "x": 2,
      "y": 34
    },
    {
      "x": 19,
      "y": 11
    },
    {
      "x": 12,
      "y": 18
    },
    {
      "x": 20,
      "y": 31
    },
    {
      "x": 5,
      "y": 20
    },
    {
      "x": 11,
      "y": 22
    },
    {
      "x": 25,
      "y": 32
    },
    {
      "x": 1,
      "y": 11
    },
    {
      "x": 5,
      "y": 29
    },
    {
      "x": 3,
      "y": 8
    },
    {
      "x": 23,
      "y": 32
    },
    {
      "x": 26,
      "y": 16
    },
    {
      "x": 17,
      "y": 7
    },
    {
      "x": 9,
      "y": 14
    },
    {
      "x": 20,
      "y": 30
    },
    {
      "x": 29,
      "y": 4
    },
    {
      "x": 28,
      "y": 23
    },
    {
      "x": 27,
      "y": 17
    },
    {
      "x": 5,
      "y": 30
    },
    {
      "x": 24,
      "y": 17
    },
    {
      "x": 22,
      "y": 22
    },
    {
      "x": 17,
      "y": 14
    },
    {
      "x": 33,
      "y": 4
    },
    {
      "x": 20,
      "y": 20
    },
    {
      "x": 7,
      "y": 10
    },
    {
      "x": 19,
      "y": 2
    },
    {
      "x": 4,
      "y": 13
    },
    {
      "x": 9,
      "y": 15
    },
    {
      "x": 32,
      "y": 22
    },
    {
      "x": 24,
      "y": 10
    },
    {
      "x": 17,
      "y": 27
    },
    {
      "x": 18,
      "y": 24
    },
    {
      "x": 3,
      "y": 16
    },
    {
      "x": 21,
      "y": 5
    },
    {
      "x": 2,
      "y": 32
    },
    {
      "x": 25,
      "y": 17
    },
    {
      "x": 4,
      "y": 15
    },
    {
      "x": 24,
      "y": 5
    },
    {
      "x": 31,
      "y": 5
    },
    {
      "x": 1,
      "y": 25
    },
    {
      "x": 18,
      "y": 25
    },
    {
      "x": 21,
      "y": 11
    },
    {
      "x": 1,
      "y": 14
    },
    {
      "x": 33,
      "y": 24
    },
    {
      "x": 1,
      "y": 26
    },
    {
      "x": 21,
      "y": 1
    },
    {
      "x": 2,
      "y": 20
    },
    {
      "x": 33,
      "y": 25
    },
    {
      "x": 20,
      "y": 6
    },
    {
      "x": 6,
      "y": 4
    },
    {
      "x": 27,
      "y": 24
    },
    {
      "x": 27,
      "y": 19
    },
    {
      "x": 32,
      "y": 23
    },
    {
      "x": 18,
      "y": 14
    },
    {
      "x": 11,
      "y": 6
    },
    {
      "x": 13,
      "y": 5
    },
    {
      "x": 16,
      "y": 9
    },
    {
      "x": 15,
      "y": 18
    },
    {
      "x": 21,
      "y": 23
    },
    {
      "x": 11,
      "y": 31
    },
    {
      "x": 1,
      "y": 12
    },
    {
      "x": 3,
      "y": 0
    },
    {
      "x": 31,
      "y": 22
    },
    {
      "x": 26,
      "y": 0
    },
    {
      "x": 32,
      "y": 27
    },
    {
      "x": 2,
      "y": 31
    },
    {
      "x": 8,
      "y": 34
    },
    {
      "x": 26,
      "y": 1
    },
    {
      "x": 6,
      "y": 22
    },
    {
      "x": 32,
      "y": 12
    },
    {
      "x": 32,
      "y": 25
    },
    {
      "x": 8,
      "y": 17
    },
    {
      "x": 18,
      "y": 7
    },
    {
      "x": 5,
      "y": 15
    },
    {
      "x": 29,
      "y": 19
    },
    {
      "x": 4,
      "y": 16
    },
    {
      "x": 6,
      "y": 30
    },
    {
      "x": 21,
      "y": 30
    },
    {
      "x": 28,
      "y": 20
    },
    {
      "x": 34,
      "y": 16
    },
    {
      "x": 12,
      "y": 12
    },
    {
      "x": 19,
      "y": 15
    },
    {
      "x": 17,
      "y": 8
    },
    {
      "x": 31,
      "y": 12
    },
    {
      "x": 31,
      "y": 27
    },
    {
      "x": 12,
      "y": 31
    },
    {
      "x": 19,
      "y": 28
    },
    {
      "x": 30,
      "y": 12
    },
    {
      "x": 20,
      "y": 21
    },
    {
      "x": 6,
      "y": 32
    },
    {
      "x": 12,
      "y": 2
    },
    {
      "x": 23,
      "y": 27
    },
    {
      "x": 12,
      "y": 21
    },
    {
      "x": 27,
      "y": 11
    },
    {
      "x": 19,
      "y": 8
    },
    {
      "x": 6,
      "y": 27
    },
    {
      "x": 13,
      "y": 21
    },
    {
      "x": 21,
      "y": 29
    },
    {
      "x": 31,
      "y": 24
    },
    {
      "x": 21,
      "y": 28
    },
    {
      "x": 8,
      "y": 5
    },
    {
      "x": 26,
      "y": 24
    },
    {
      "x": 27,
      "y": 32
    },
    {
      "x": 23,
      "y": 13
    },
    {
      "x": 28,
      "y": 6
    },
    {
      "x": 21,
      "y": 15
    },
    {
      "x": 6,
      "y": 13
    },
    {
      "x": 12,
      "y": 29
    },
    {
      "x": 12,
      "y": 1
    },
    {
      "x": 16,
      "y": 33
    },
    {
      "x": 2,
      "y": 33
    },
    {
      "x": 19,
      "y": 3
    },
    {
      "x": 7,
      "y": 29
    },
    {
      "x": 10,
      "y": 3
    },
    {
      "x": 12,
      "y": 3
    },
    {
      "x": 18,
      "y": 31
    },
    {
      "x": 17,
      "y": 9
    },
    {
      "x": 9,
      "y": 10
    },
    {
      "x": 30,
      "y": 19
    },
    {
      "x": 2,
      "y": 7
    },
    {
      "x": 15,
      "y": 31
    },
    {
      "x": 20,
      "y": 9
    },
    {
      "x": 24,
      "y": 29
    },
    {
      "x": 3,
      "y": 2
    },
    {
      "x": 4,
      "y": 21
    },
    {
      "x": 22,
      "y": 0
    },
    {
      "x": 16,
      "y": 11
    },
    {
      "x": 1,
      "y": 17
    },
    {
      "x": 4,
      "y": 11
    },
    {
      "x": 17,
      "y": 11
    },
    {
      "x": 31,
      "y": 8
    },
    {
      "x": 21,
      "y": 16
    },
    {
      "x": 18,
      "y": 10
    },
    {
      "x": 14,
      "y": 29
    },
    {
      "x": 14,
      "y": 26
    },
    {
      "x": 17,
      "y": 33
    },
    {
      "x": 2,
      "y": 16
    },
    {
      "x": 7,
      "y": 28
    },
    {
      "x": 30,
      "y": 25
    },
    {
      "x": 2,
      "y": 9
    },
    {
      "x": 12,
      "y": 19
    },
    {
      "x": 9,
      "y": 12
    },
    {
      "x": 18,
      "y": 22
    },
    {
      "x": 24,
      "y": 0
    },
    {
      "x": 29,
      "y": 8
    },
    {
      "x": 9,
      "y": 33
    },
    {
      "x": 29,
      "y": 18
    },
    {
      "x": 4,
      "y": 12
    },
    {
      "x": 15,
      "y": 14
    },
    {
      "x": 32,
      "y": 16
    },
    {
      "x": 2,
      "y": 19
    },
    {
      "x": 16,
      "y": 27
    },
    {
      "x": 5,
      "y": 12
    },
    {
      "x": 8,
      "y": 8
    },
    {
      "x": 27,
      "y": 14
    },
    {
      "x": 5,
      "y": 5
    },
    {
      "x": 15,
      "y": 16
    },
    {
      "x": 30,
      "y": 26
    },
    {
      "x": 31,
      "y": 9
    },
    {
      "x": 0,
      "y": 9
    },
    {
      "x": 30,
      "y": 30
    },
    {
      "x": 32,
      "y": 17
    },
    {
      "x": 12,
      "y": 5
    },
    {
      "x": 1,
      "y": 24
    },
    {
      "x": 3,
      "y": 17
    },
    {
      "x": 13,
      "y": 10
    },
    {
      "x": 0,
      "y": 26
    },
    {
      "x": 18,
      "y": 32
    },
    {
      "x": 6,
      "y": 12
    },
    {
      "x": 25,
      "y": 0
    },
    {
      "x": 30,
      "y": 28
    },
    {
      "x": 15,
      "y": 17
    },
    {
      "x": 23,
      "y": 26
    },
    {
      "x": 30,
      "y": 29
    },
    {
      "x": 15,
      "y": 0
    },
    {
      "x": 24,
      "y": 21
    },
    {
      "x": 13,
      "y": 19
    },
    {
      "x": 32,
      "y": 11
    },
    {
      "x": 27,
      "y": 10
    },
    {
      "x": 34,
      "y": 1
    },
    {
      "x": 11,
      "y": 23
    },
    {
      "x": 3,
      "y": 10
    }
  ],
  "colors": [
    [-26.56470972152618],
    [5.038886593613651],
    [-0.8856745606783853],
    [29.724113223644895],
    [58.34132272915859],
    [2.117609543043242],
    [64.58932657121348],
    [60.440943790854924],
    [50.02863080094599],
    [85.44316909709946],
    [-0.19158586937313726],
    [26.270043476212006],
    [-39.750685821289274],
    [-6.884530984003835],
    [47.56459858359807],
    [47.5549371320298],
    [20.33379637556635],
    [-58.854622969446154],
    [50.34216292653779],
    [2.0413109485300875],
    [-6.881435775317868],
    [1.2638159275917398],
    [-63.132097016914926],
    [-65.26306381039794],
    [5.9455074734073525],
    [37.38081751025927],
    [10.07969653771501],
    [8.208998097956789],
    [-18.344296785958555],
    [11.39408052407313],
    [4.958326044951363],
    [-60.54198475362127],
    [19.28864931709209],
    [9.300262095102134],
    [-1.0207842662114641],
    [0.5690801158172593],
    [-12.583920198682685],
    [-21.17640045862404],
    [-61.98087154562306],
    [40.18187106069236],
    [-14.885268144478465],
    [5.9342210696940585],
    [-9.101443017896123],
    [-23.80449275510981],
    [48.94663388710273],
    [-39.5779906485652],
    [31.83941417670326],
    [9.268350228124914],
    [-86.01092313021705],
    [-62.31225209472771],
    [-9.040588107959566],
    [-21.08210804320146],
    [59.40662487703848],
    [-29.83397630418036],
    [3.635133011900191],
    [-2.3718854492168586],
    [-0.9257326355873021],
    [2.7991609816824385],
    [-29.775363496601642],
    [6.518411160090588],
    [85.4327544430175],
    [-61.5291967237919],
    [12.536445640672266],
    [17.62959477957061],
    [93.23348545505718],
    [3.834975630973267],
    [28.96273749200212],
    [-29.770270156991415],
    [-31.12262410690994],
    [-1.8177103014590221],
    [-73.51644078457319],
    [-57.786321285139735],
    [37.36908826053379],
    [-14.895361682172366],
    [-4.163089466130701],
    [6.830505154394132],
    [-42.8922104762751],
    [-49.32348648404971],
    [47.563444632636255],
    [38.17465128855643],
    [-9.045231015276828],
    [-4.163089523734278],
    [82.82501851961229],
    [1.25742448215831],
    [-0.5616209456502204],
    [82.84038648651133],
    [16.747685974971237],
    [3.4630242237188913],
    [4.565638861443009],
    [-43.79436381157053],
    [22.828391516562938],
    [46.44669207711252],
    [33.256998012276696],
    [-2.159320584628123],
    [7.490172999833346],
    [0.4535541068426378],
    [-13.365922097793964],
    [18.494884208230527],
    [-4.957372897294142],
    [27.375276135735422],
    [58.34013426389112],
    [80.67608636839864],
    [-6.476485553871528],
    [-17.302609991090616],
    [5.316342009398649],
    [-2.0828421266014376],
    [-23.441985194736223],
    [-11.385420621593072],
    [49.01208412676898],
    [19.581542383344186],
    [18.383778350094424],
    [-49.265847101751255],
    [-13.036536190055708],
    [48.89990555108322],
    [-6.301816501819924],
    [-19.127032987696403],
    [50.31824840775143],
    [40.1817771177801],
    [34.73869464824287],
    [14.935872604493902],
    [34.757888443404894],
    [6.022741616119194],
    [-41.940768637740405],
    [10.314227708418471],
    [58.334257259080594],
    [-16.166643464022922],
    [-51.66713434620809],
    [51.4909493156547],
    [-41.94299071123999],
    [22.712924356142356],
    [19.275535855815168],
    [-68.70980715348334],
    [-13.024942096772115],
    [-60.72180364583903],
    [-5.155744089016509],
    [-6.477415743865969],
    [-4.863280291030769],
    [52.60885732482126],
    [64.58991731636098],
    [5.803997102017957],
    [-17.286812622516937],
    [-2.975140507330376],
    [13.701735498552994],
    [3.691266725429857],
    [-43.81181156326512],
    [62.47899080625919],
    [44.20688882836986],
    [0.6002799123329555],
    [11.371523878523481],
    [32.17447454059868],
    [-11.264712945796157],
    [81.21498260311331],
    [10.233731539446575],
    [-1.1859589083702426],
    [78.83668014429638],
    [17.692565826421163],
    [-64.66162020557485],
    [-34.187424502227906],
    [10.103082676530356],
    [26.375160547422684],
    [64.50917505791531],
    [50.32440480689157],
    [-45.10513180084875],
    [-3.4226192045872503],
    [33.22285903213786],
    [34.22037456561192],
    [0.24110442400015988],
    [-18.221302963261774],
    [-52.39884894033884],
    [10.943833977593348],
    [-50.06725997764481],
    [-30.600941334166745],
    [18.59440442026959],
    [74.23874091015865],
    [-12.064907837117481],
    [-2.4829209321602255],
    [9.243649295527007],
    [36.32315517715727],
    [-8.101183877049621],
    [-10.346741594096482],
    [-11.366907059478164],
    [8.644065935238302],
    [15.467440137579361],
    [-10.871207458920521],
    [14.746261266927647],
    [11.418484510709266],
    [15.862676898817945],
    [38.170807626527925],
    [-90.00078024174826],
    [-62.801747781280085],
    [-27.747596376238977],
    [-1.7524438089724905],
    [-22.00169594366066],
    [-14.651381971488183],
    [6.496462451371],
    [-40.85164802712226],
    [61.02235119085374],
    [2.9445286821670154],
    [-45.981372155306616],
    [-33.26629883246655],
    [-14.434303069254792],
    [-36.43935057858973],
    [34.745546814451224],
    [-59.79500024771249],
    [-30.46901007295591],
    [73.51592125301084],
    [21.59855907386914],
    [74.43745852423628],
    [20.352144466309873],
    [-16.125027156451967],
    [6.524845544743754],
    [-15.10577118446861],
    [-26.56443854856454],
    [-60.686198327475836],
    [22.671114218218285],
    [-31.08328541384454],
    [-3.3399804768169665],
    [-46.393792670079776],
    [10.121466425207213],
    [71.11742934511528],
    [34.28505604330578],
    [28.78104321591615],
    [53.645409982143946],
    [11.412749106657474],
    [64.89775680496932],
    [3.6364132263111726],
    [0.7335206035169679],
    [24.848684949499344],
    [10.155849849764472],
    [-34.21119762427286],
    [7.50011852988528],
    [11.35411791729134],
    [-22.790516188114246],
    [-80.58418150351666],
    [9.025956517049886],
    [45.4392598285655],
    [-22.78391502126518],
    [13.253492607210452],
    [-35.21794891319425],
    [51.49045783811377],
    [-1.8008963133644482],
    [-7.006910280245795],
    [-40.82138440233115],
    [-5.762272216334129],
    [-0.1642111487895569],
    [26.12789469927512],
    [72.62406504102756],
    [-49.89384910160058],
    [-9.102278916885592],
    [1.2535158281328467],
    [-36.42456338154786],
    [-5.642010394848365],
    [-32.57112829120623],
    [-54.04741297168737],
    [-25.140253681535384],
    [-16.16973986410184],
    [-21.909886848899742],
    [-41.93707475003248],
    [48.96189810185928],
    [-10.802985799969115],
    [-50.423311749878714],
    [-8.043016196304393],
    [41.261789769064656],
    [90.2312097156437],
    [29.15151987246687],
    [10.106746198907668],
    [43.00252262583585],
    [-22.806213084417262],
    [-73.17676420449808],
    [-5.869094662613839],
    [-12.59151210593965],
    [0.653710204917819],
    [9.523873354828021],
    [60.40108621052224],
    [-55.63801750487166],
    [-8.170724908773794],
    [-38.29275698802655],
    [-36.95064860418673],
    [-24.53076999154575],
    [-8.476480284588177],
    [-22.783341311836708],
    [71.08692922833235],
    [-4.413846019033416],
    [-8.462164934740004],
    [-2.9428190956742117],
    [-0.7784880531893047],
    [-2.7631510084961097],
    [-66.86473458750761],
    [-10.357069049454601],
    [5.632010418000116],
    [19.481811785503258],
    [45.43828592763741],
    [-34.097296628428815],
    [0.38208240386964865],
    [-33.12107864398559],
    [87.81670053389497],
    [9.192269912944576],
    [-1.6444112805316962],
    [5.317209682744388],
    [-63.09620236562126],
    [-3.535294935529457],
    [68.79226097281105],
    [-13.14999731951223],
    [-76.78707409326572],
    [66.78809687775156],
    [-51.17002517507084],
    [1.3290902149698627],
    [22.766975320772254],
    [23.84505901561165],
    [31.810913233654983],
    [2.1622654857497126],
    [-19.951346385754775],
    [12.70443167199206],
    [17.307953245981214],
    [26.31911992151087],
    [17.68068159929817],
    [0.68504931486768],
    [55.68370764106082],
    [-36.96977408932692],
    [-56.087300717688976],
    [20.40551433294045],
    [55.68377692780553],
    [-29.04732839543545],
    [3.64355957000191],
    [34.737904773576155],
    [53.65165232192752],
    [-5.605022182786786],
    [-53.63343196434878],
    [2.03648161798042],
    [9.840664939257373],
    [-70.38358361297936],
    [54.70662137814283],
    [-94.5726140294292],
    [52.60117039630907],
    [2.6696121217312903],
    [-66.87465063910447],
    [-28.967330067503703],
    [-16.27887047152596],
    [-3.8276073100414862],
    [26.300069661814554],
    [10.079371233173974],
    [-55.39773197462059],
    [22.833959580700594],
    [51.49388264956555],
    [-22.019715198402174],
    [80.56490385676294],
    [-58.85586998407472],
    [-3.268601741447825],
    [-55.40380492934768],
    [67.4065338129793],
    [39.07508791668202],
    [80.544491140661],
    [24.226620881991764],
    [-34.18115268532546],
    [23.891992682293466],
    [85.45553595938333],
    [6.076852998482006],
    [22.765823661900345],
    [-75.36017573874878],
    [31.384312204889117],
    [14.214293816240984],
    [37.36263660796916],
    [8.932091862336055],
    [7.2204732923282835],
    [87.80922940568577],
    [54.71056152107964],
    [20.245018902455175],
    [-66.90293443285914],
    [20.365908681118285],
    [8.333940424618053],
    [-70.38103819213275],
    [3.708108575604696],
    [-80.6555946369532],
    [-79.97470280720198],
    [2.145654154947923],
    [-54.15862168311163],
    [21.063622597351678],
    [6.493509047763705],
    [16.57193985354915],
    [-41.03310794659701],
    [42.998687494701045],
    [-46.389430808704816],
    [-68.60044661352985],
    [34.748754173150125],
    [-33.31317297774478],
    [0.015933835440804612],
    [71.09818509225684],
    [36.198706491662705],
    [-13.585406563026135],
    [51.490039236255946],
    [-17.271869877546017],
    [69.81269565511845],
    [-89.739485925792],
    [-17.905241051695153],
    [-5.0469439006302075],
    [-14.89965881793644],
    [42.22128280882657],
    [52.59885628019415],
    [21.615145214979158],
    [-10.556913341001502],
    [-51.998325416016144],
    [-8.981988993053367],
    [33.2501039231358],
    [-12.583342182050364],
    [41.27234028023125],
    [-38.50641187197242],
    [97.10918389982913],
    [-10.186299196848628],
    [-27.3479957133814],
    [6.530555396903779],
    [-25.435910055261573],
    [-28.22364037598373],
    [-16.17341177795983],
    [77.64352096599798],
    [-0.08472316033253034],
    [34.74677365391058],
    [-26.967814038494684],
    [-4.605736867420555],
    [-11.977584462089872],
    [8.986243643043505],
    [12.526717037940692],
    [53.64959830093545],
    [15.904855934251646],
    [-45.20100278058593],
    [-80.69836250716699],
    [-1.6251123358343404],
    [-21.941018888900295],
    [28.662822264890107],
    [-12.100235112345901],
    [8.095864448838896],
    [-48.092333101688546],
    [30.29132658130818],
    [-15.532315277269293],
    [-17.759306728202723],
    [19.347630993764135],
    [-7.142941547119997],
    [12.602490711672054],
    [-25.159811410547956],
    [75.9395636738645],
    [-28.31118789294131],
    [-66.94020023886628],
    [-68.28010908595508],
    [1.6035320299539075],
    [26.240143594957768],
    [85.4529275001996],
    [66.78795018328647],
    [50.00324533376426],
    [37.370537878643724],
    [8.453663958794769],
    [0.5062673662825872],
    [3.7284499844713386],
    [18.806498087841998],
    [48.946376883907696],
    [-50.692919930322525],
    [-10.005047064276308],
    [-17.28814304900032],
    [27.567104282765513],
    [64.79640909528511],
    [75.93799540631153],
    [-6.758974448961931],
    [28.664229044682287],
    [22.817931260684148],
    [80.52273485336477],
    [-89.96842644624269],
    [22.048123775052925],
    [5.914298722547586],
    [-10.361392722301371],
    [68.79591220014082],
    [16.702380613944985],
    [-56.98712716092961],
    [64.97213432451068],
    [0.15922799672966673],
    [-78.19466502527685],
    [-62.7969650920396],
    [-2.1634654789228147],
    [2.7991013702542693],
    [62.93840628342844],
    [-27.53412905802113],
    [27.879492011047983],
    [49.91036673044267],
    [31.407352509748264],
    [56.77038379776681],
    [-27.422181586207422],
    [-9.770984658548564],
    [-22.774750408688895],
    [-20.060674158537527],
    [-1.8693876039634378],
    [29.058636655302326],
    [-90.05845940102368],
    [46.44372295600926],
    [74.28903849353912],
    [-16.001233728701695],
    [-71.81828280371255],
    [-17.217555869096884],
    [39.07381645873833],
    [-5.2261335543052985],
    [67.3379185697439],
    [90.23051109156009],
    [-43.895115672112794],
    [97.11798666548304],
    [5.326402600992626],
    [75.93695539368728],
    [-76.78787943496273],
    [-5.017645575308376],
    [-7.426710361027151],
    [41.26506137022347],
    [18.59579696560577],
    [-4.372184400327552],
    [16.574220915698817],
    [36.28687202144709],
    [-42.88027168778828],
    [-80.9109414904268],
    [-38.42082863370305],
    [-21.984731748361295],
    [-13.839716378278185],
    [73.87620349619915],
    [-12.025485674085242],
    [59.400777286022745],
    [27.889016636359397],
    [27.99561501695268],
    [17.58718145733854],
    [-71.81201192764148],
    [8.640349312511885],
    [13.737987157167272],
    [21.32392355502093],
    [82.86359266376384],
    [25.63381167904923],
    [16.2352043772521],
    [22.053218791406156],
    [-46.41445192270415],
    [-19.176705223668513],
    [93.23359683167232],
    [-9.032268997443815],
    [-62.99139013043393],
    [-31.496957672959315],
    [-19.69143108581334],
    [-14.448029134835666],
    [-4.212858426705151],
    [-71.81949955612026],
    [35.78151223895101],
    [-40.922640138908335],
    [82.82434087309531],
    [-6.076100166163182],
    [-7.386889610788067],
    [62.88025639330603],
    [27.897802600243654],
    [25.14676140561532],
    [78.96867078654088],
    [-8.135257225065235],
    [-0.3797187116582529],
    [-53.986350109624354],
    [8.648434843713135],
    [19.475648278091327],
    [-59.7998666617662],
    [-6.033116645389872],
    [41.27781019523492],
    [-74.15524845725292],
    [-83.59135820705707],
    [-89.98428580443657],
    [45.44084948559756],
    [-24.37573841210476],
    [-52.45829639275093],
    [15.946771761506183],
    [-80.22747112415897],
    [14.14314226450445],
    [33.3020652508947],
    [40.18332388805185],
    [68.78788711735461],
    [74.38839142795707],
    [-40.69672552047438],
    [-35.36802242380944],
    [-0.7864371027739463],
    [71.11755119112678],
    [-50.403418400163936],
    [44.2312274224579],
    [-71.81029942360468],
    [-47.41080649156706],
    [66.77466774449505],
    [14.663239216358578],
    [-2.749284156501066],
    [-15.977840093935354],
    [47.557628108586066],
    [-39.624256981835074],
    [48.696110651809164],
    [-2.5760706671999585],
    [48.89249399367507],
    [4.566873258060991],
    [-31.042473864425997],
    [27.91494124340805],
    [-2.502901356133129],
    [-25.984603694649834],
    [-14.890829269641479],
    [-51.852505866071546],
    [-41.93933371002317],
    [-0.8991462114497157],
    [90.23092494348268],
    [44.22590452612885],
    [-37.702030933146524],
    [-83.59299841971698],
    [-1.5440436265460198],
    [13.689330439215343],
    [30.306208925401492],
    [81.19338357394601],
    [-13.025223465855921],
    [76.87834033025385],
    [-80.59422588932513],
    [46.449006625073146],
    [-4.163090270078339],
    [46.445532298510436],
    [-64.8292990615915],
    [57.67901510802565],
    [-48.38555390297832],
    [97.1133329852404],
    [81.33135116790207],
    [31.156429744566548],
    [14.822774494402061],
    [31.410913960558634],
    [7.834374111544364],
    [-61.92444276807894],
    [-89.97287329196692],
    [-70.38229942921707],
    [59.406689123202796],
    [2.175549246317593],
    [17.72748451483443],
    [-19.93338506583157],
    [-9.171876241665469],
    [74.29347964174126],
    [-94.57201032279305],
    [61.45715648665614],
    [-65.0889658661088],
    [85.46440186445118],
    [-13.857161585212921],
    [-16.160998645167933],
    [-53.63861239119393],
    [66.86913941837263],
    [-6.391940398091747],
    [64.5876863062728],
    [-0.16692015604455915],
    [-41.94009832076994],
    [-18.310852081367546],
    [33.32197010623392],
    [45.439589005140995],
    [-94.57217777263476],
    [-49.19801585097074],
    [13.257314904857903],
    [0.47703219083624376],
    [-9.133157479223922],
    [-19.085763647411454],
    [-36.362818400767814],
    [-3.1169424573060107],
    [-4.22641347244327],
    [97.11462045486657],
    [5.933185383398707],
    [-4.073319183771426],
    [39.09101550196192],
    [-3.4102410116041906],
    [-53.66952438632182],
    [48.67688917496203],
    [-35.49269178029321],
    [-5.823863647728786],
    [36.193113394777875],
    [-39.84526640125286],
    [-52.41908902163436],
    [-27.66653718263538],
    [-81.0736556855595],
    [-18.28556235734502],
    [16.547355196049633],
    [-45.18162739517515],
    [42.22099723270324],
    [-94.57459554414673],
    [-1.1702082881871572],
    [28.081541858760648],
    [-4.076981936434701],
    [-40.864886906057194],
    [-48.65335887754134],
    [-4.0095925274466255],
    [-7.735594844299806],
    [-52.352505589957474],
    [30.32121741799209],
    [44.215396567377525],
    [-47.29725273106619],
    [-20.85848479281892],
    [-4.163087752363116],
    [-40.92290891999952],
    [-80.91521475679613],
    [6.9265421250839525],
    [24.860401981517867],
    [-24.39626511679052],
    [-9.86734269066183],
    [31.19258568962599],
    [-89.96687356239818],
    [-6.225796549349201],
    [-68.62734405089239],
    [-45.972330565838035],
    [17.31591032630996],
    [-28.304638996282563],
    [-3.4308511473207246],
    [82.40999702631031],
    [12.666756186241637],
    [61.477537890730986],
    [-4.118462055981656],
    [-23.330827486375266],
    [-42.861373607413036],
    [-58.852167972288484],
    [-3.69790928883059],
    [-42.89285611269236],
    [-40.9131163444024],
    [-67.00046564345892],
    [4.984190504754888],
    [80.55942072957757],
    [-85.9441186798014],
    [-78.19478548110669],
    [26.42324560620922],
    [-12.70439431538064],
    [71.07644721869984],
    [-25.250379693321612],
    [32.82021790232916],
    [-6.107676772837224],
    [59.401465761304316],
    [-32.58320233064155],
    [-31.218931888533326],
    [42.226437310843295],
    [-35.487707966298935],
    [-29.058762166385225],
    [85.45641686814007],
    [53.643682689919636],
    [5.0388708968689215],
    [78.85332488686848],
    [-34.56292645943183],
    [56.78977568740059],
    [97.10689884816111],
    [-13.74394764214217],
    [-14.01792896086046],
    [33.31021246654371],
    [-35.47416321180099],
    [-80.96024441662956],
    [-68.5846591775904],
    [93.23332929851782],
    [-2.807373158556625],
    [39.09037342461014],
    [15.447495005116238],
    [-4.784654832821958],
    [-44.26011343015856],
    [97.1149589895114],
    [21.090537921644223],
    [40.151665554619],
    [32.21919628172635],
    [-28.962159133442828],
    [16.5471751290459],
    [-85.95279114273774],
    [72.65367921764027],
    [-34.20077909853564],
    [62.785431252159896],
    [-75.31427381936624],
    [-31.180437045990374],
    [-75.27272556018681],
    [-13.572774030138989],
    [66.72826221953753],
    [64.75376937782151],
    [-94.57282539918364],
    [-36.41904944298838],
    [2.1086403819765165],
    [-39.74976144486941],
    [-53.60052349267941],
    [-1.1137756575089919],
    [2.202777007713023],
    [-33.315932034101614],
    [58.33491705924513],
    [-80.71614861394296],
    [74.06017290018727],
    [-65.43408678459326],
    [28.70901487013008],
    [-62.3091429764529],
    [-36.20995307830067],
    [77.64330643310312],
    [-20.876084499299832],
    [64.94971063209954],
    [2.951281044174141],
    [-50.40529071904056],
    [7.49090765843659],
    [2.018635509603393],
    [43.003078092759125],
    [-7.1453910364611115],
    [-11.395837713570273],
    [7.504818855469414],
    [-90.03769307103595],
    [37.3669411244235],
    [-14.891873400012583],
    [-31.045274813255507],
    [-22.007251238884006],
    [52.6018774994157],
    [-21.423984536775823],
    [61.99532804685832],
    [-21.40311083090874],
    [85.45384401411545],
    [17.727455737997207],
    [44.22843921619989],
    [40.155107581725844],
    [54.712737434666096],
    [28.089228460176866],
    [25.293078319021777],
    [-56.9864623885795],
    [-12.17669696150753],
    [-6.3096528439976955],
    [-8.455593837016066],
    [-58.86160372961287],
    [19.239211925496775],
    [62.69433090941746],
    [-17.295091412467613],
    [2.953283587193738],
    [-31.69765091283263],
    [15.697731732308542],
    [-37.4210777032583],
    [-51.85251391291441],
    [-29.750334397744307],
    [-2.4971548332669857],
    [-39.197126625130366],
    [-4.10967017866045],
    [-7.121022454731937],
    [2.1186926645629627],
    [44.229222642509406],
    [44.20290138716343],
    [-35.443483776905765],
    [-38.3200102723908],
    [-25.802232076543845],
    [-19.113470794831965],
    [-8.99516866083289],
    [-5.477708986615408],
    [97.11231314343121],
    [-70.3809459759084],
    [42.995756851089475],
    [-3.91806056513438],
    [61.247961168118096],
    [-78.20009640492663],
    [-8.046455953885363],
    [26.3273078168331],
    [39.075526798611214],
    [23.885782171950247],
    [-56.10644878985743],
    [-47.42398654387697],
    [90.2348449563527],
    [11.35488835149829],
    [-48.76760136735429],
    [-24.408220930478304],
    [3.7409016010492717],
    [-5.327833877687599],
    [-34.41655807915605],
    [29.810380503220234],
    [-19.91716267016374],
    [-4.77204209046831],
    [-48.73924816386895],
    [19.384095954235576],
    [-0.20586179309365843],
    [-15.813134300003355],
    [-9.583844117692989],
    [-54.18615263735068],
    [64.55679723897777],
    [28.107185786150865],
    [16.102431641114613],
    [8.908794815326324],
    [-26.55356218564375],
    [-57.78373591477204],
    [59.40127904597396],
    [62.606211515583546],
    [-13.618605488924903],
    [11.990688526221549],
    [56.76836437174017],
    [-0.9194591497191915],
    [15.174076333103827],
    [-47.42138256209799],
    [-14.608265125436478],
    [-50.08498669211562],
    [-65.29698142155226],
    [26.325066727132224],
    [-5.456639911113944],
    [11.995174787446079],
    [-10.844950351488508],
    [2.6562346535361896],
    [-9.09848581803363],
    [-8.231591578835953],
    [-74.1740089800141],
    [0.08353192682291553],
    [-2.1873309783683115],
    [-50.434866458753554],
    [-21.360511301584125],
    [13.250531177133498],
    [-7.510463639072742],
    [-1.6401187986843722],
    [11.408252497500692],
    [-37.97169513367904],
    [-38.915090895194545],
    [-27.083662706917835],
    [-2.6359687500900137],
    [-57.78929073091255],
    [-73.16466331742217],
    [-18.562368290122265],
    [47.59050355654694],
    [39.09406937491247],
    [90.2387228211227],
    [-19.086931759540892],
    [36.2779285609162],
    [-80.27711666192343],
    [-86.12088489065589],
    [1.3322840961370923],
    [38.174249449082176],
    [-19.86958688114702],
    [-4.163092248703242],
    [-70.3818023161322],
    [-53.86270447350234],
    [-29.7805486093213],
    [-8.254617296525888],
    [60.41389936264835],
    [-62.4284512907523],
    [16.141232713040917],
    [-2.9584709908637263],
    [93.2341897257147],
    [-10.083650777631627],
    [-31.020660982572718],
    [-25.932312964667297],
    [11.407080946210568],
    [-55.54270299653973],
    [-0.1627554577509273],
    [23.89315442536525],
    [3.6941506590112043],
    [64.9056127683532],
    [-15.779717928668326],
    [-7.9185489883651545],
    [34.752747147678704],
    [-81.13511396669001],
    [-63.049371873587674],
    [-5.144504968212674],
    [25.595718291179324],
    [-14.991192395857135],
    [-27.310412172822296],
    [-25.887965804410623],
    [-3.1682667261219737],
    [-19.948148465091386],
    [-1.58339660488012],
    [47.550146638483504],
    [51.44423811383114],
    [-6.85495077365592],
    [-38.761623265969675],
    [42.228712582970886],
    [3.954300843820593],
    [33.198463037205244],
    [-55.33231528169643],
    [-37.41666483815698],
    [14.241955678023457],
    [-50.32194551446427],
    [-23.795502913696932],
    [36.2901246888449],
    [-14.499109418090313],
    [-19.40913442208436],
    [40.15492976547531],
    [-32.55111017903085],
    [-28.24490884247267],
    [19.39836313603136],
    [20.396726832869216],
    [-42.86358136504356],
    [-4.398387517989426],
    [24.802225091111406],
    [13.969737815403098],
    [6.500110266432488],
    [44.226531724344184],
    [3.8345963549679256],
    [-15.963643540271612],
    [-4.384048889366165],
    [56.770773295298596],
    [22.67953607775619],
    [-7.879120281512144],
    [9.979012572239895],
    [-31.692120528257178],
    [-16.10557412414712],
    [-44.2618760315069],
    [-11.370152812522376],
    [16.317374599117805],
    [-32.620239137216],
    [-11.402757684767401],
    [-37.42214034320136],
    [-76.78771720634955],
    [17.65921934911197],
    [1.1804067559607196],
    [-38.513444611249355],
    [-75.05970973324698],
    [-23.799382170451302],
    [-11.366876871443973],
    [-31.396845393896538],
    [-58.860004253423625],
    [-63.21623219215563],
    [-25.98723186449882],
    [-5.122519321188231],
    [-17.267407107058077],
    [-19.182168982695185],
    [-47.810322846872026],
    [-19.91320137110221],
    [-25.916334352615912],
    [-1.5463484893255734],
    [-4.812904519930296],
    [15.383079526382161],
    [57.68111195414746],
    [25.06078126952517],
    [-12.134895957502533],
    [49.934076960971325],
    [-11.98289018736653],
    [-50.17110992144537],
    [-34.63316437510951],
    [27.48456809874272],
    [17.637102305086593],
    [-24.414986155380447],
    [-9.024797905612518],
    [-35.49235727799409],
    [-10.170643961686014],
    [52.606594480068004],
    [-68.54143390415588],
    [-45.19497603367442],
    [-85.96973477717303],
    [-29.065044890934864],
    [16.35342787024313],
    [-17.530636371370136],
    [-7.699395475119448],
    [-56.98675990717056],
    [-16.993997033692775],
    [-3.3390625604059263],
    [-17.087294792682737],
    [-10.01184582340175],
    [26.171718278167155],
    [-36.38828837857701],
    [-3.1225712497722324],
    [30.900740776731528],
    [1.8703954950569657],
    [-3.1642006840505466],
    [37.36789652928187],
    [90.22973140116208],
    [14.847611050283874],
    [41.26532138155892],
    [41.26271167191637],
    [-50.373628762566206],
    [-4.007330192119004],
    [-29.013031555499115],
    [-65.15975748692831],
    [21.63901634433095],
    [46.449327050429936],
    [-24.414213917435386],
    [55.68533675058032],
    [4.3426143565491735],
    [-4.41540538918545],
    [71.10831642211535],
    [-7.224687417894591],
    [-10.196135949120862],
    [44.20037730971795],
    [97.10934463198883],
    [68.78315420341012],
    [-33.93339030591788],
    [-60.69622894537583],
    [11.989266067668208],
    [16.108647702444696],
    [-13.82389259227487],
    [-60.530179885576494],
    [-80.8924639004314],
    [23.942662523791828],
    [23.921512258523816],
    [68.78272251168518],
    [-11.366225819931273],
    [29.389839053794123],
    [-3.993248970677101],
    [53.64508116351402],
    [-4.103983867348678],
    [-27.332515503253877],
    [-39.59767987396012],
    [-12.81199504332063],
    [-5.601601862868985],
    [29.838076166225452],
    [7.700933753410076],
    [7.249306310993744],
    [-9.974559456473227],
    [-23.877949990088688],
    [-37.650795084622494],
    [55.68342330482888],
    [-1.5166616731649747],
    [-23.186904910959758],
    [19.46929603567362],
    [69.81232404221582],
    [-30.757863987272565],
    [-17.201507237767306],
    [6.92873877816219],
    [-86.2498574685471],
    [-45.187998131444616],
    [54.712519636749796],
    [-39.59771046532659],
    [39.0978904353947],
    [-66.91721801956288],
    [17.32029950524886],
    [21.621212961312004],
    [23.844933504559123],
    [1.7044549547650634],
    [45.442645619715236],
    [-3.856507513392094],
    [24.815027438483664],
    [16.22558101000275],
    [15.180461499149096],
    [-4.915380764089776],
    [-10.756668250519727],
    [50.00156464894948],
    [-32.58812077626332],
    [82.81982459543474],
    [-45.106009461275434],
    [56.787195158118614],
    [-64.99966788399377],
    [46.444681207968586],
    [43.00332000253609],
    [-83.5910192238302],
    [-30.84502262635549],
    [31.890719116567595],
    [-44.26577408869399],
    [12.538565417185835],
    [16.162824631115072],
    [12.537131073863236],
    [66.57274478726902],
    [12.488991114645389],
    [-47.30493917983745],
    [36.17131176219357],
    [67.34569800444649],
    [-73.12024878007446],
    [-6.792411541765415],
    [-2.0240984159542914],
    [14.524762656621098],
    [31.55733239151586],
    [-43.808183453530766],
    [-19.961145415265204],
    [-20.64734536913269],
    [31.256773082809627],
    [0.3943153150484423],
    [-47.45332548800344],
    [72.68775974024481],
    [-18.244609991608428],
    [3.6359068287951404],
    [8.52984392905819],
    [15.248711198793432],
    [22.838085896819113],
    [-4.9964810556017945],
    [-60.51570362744287],
    [33.343403450570655],
    [-20.918949789104467],
    [26.326655444525468],
    [-28.23239841445611],
    [-62.81262127397007],
    [-15.768072458955638],
    [10.944331897699232],
    [-5.843786177178386],
    [-13.757235439801173],
    [-19.902322763535643],
    [74.23663404963801],
    [-76.78752090252672],
    [41.27304397500531],
    [17.591117417411606],
    [13.694274227961598],
    [21.166661250916405],
    [21.175462453369978],
    [-18.235915186851155],
    [4.609890154647668],
    [2.7590690354097305],
    [62.75266556160562],
    [-55.52185812016299],
    [35.705244584978814],
    [-22.796426398191688],
    [-20.87004989971928],
    [78.85699925452704],
    [-22.850760591444796],
    [-42.89251856388224],
    [4.987087108805867],
    [-86.06054930336491],
    [-12.822711504091652],
    [4.627398839543026],
    [-74.14164594866199],
    [-3.6776334325253925],
    [4.5505603839285556],
    [-19.084888248672733],
    [-10.875697420545865],
    [71.08036845970769],
    [13.250163799642038],
    [-49.28851206353383],
    [30.284786843352506],
    [-33.30713093256563],
    [-7.624941630682289],
    [-26.550880192450013],
    [-6.014265864698792],
    [-57.78919981836207],
    [10.074836299530189],
    [-89.98833338085534],
    [61.50845967082015],
    [-25.132039704475343],
    [-2.4965089588260025],
    [32.20879295522487],
    [76.87845905153172],
    [-19.17401272643269],
    [-25.274127046025725],
    [26.32231465244904],
    [8.379960889229865],
    [38.17055635495883],
    [-3.704831149934159],
    [30.2199767132605],
    [10.10983654886615],
    [-56.102248034226534],
    [-68.57645549994821],
    [31.32015347990557],
    [21.157647032115463],
    [-30.704353154399197],
    [-23.800530220737095],
    [-6.850627354924934],
    [54.70592363326129],
    [35.80627821206992],
    [-26.540337562956562],
    [56.76866063994559],
    [29.838749587151067],
    [-4.089751891835203],
    [-46.38621199097125],
    [-68.42402439031093],
    [51.49106521739964],
    [23.928582284078644],
    [-4.17866925880915],
    [34.187105005152894],
    [8.194945679349729],
    [87.81217971057839],
    [-9.836578786386074],
    [-33.069864807447075]
  ],
  "coordinates": [
    [-23.07230655508561, -9.18335261570234],
    [-3.523768383509561, -13.63484283979384],
    [12.042560468791574, 16.655055732177576],
    [49.304762131211, 15.101303359241328],
    [-63.51900953615427, 13.53849620311427],
    [12.485915380343542, 1.7869332932114579],
    [37.584738654897315, 46.70091084451205],
    [34.11457632589978, 16.168985190023555],
    [-16.719645343020492, 46.94055896080031],
    [-26.395392772017534, -63.60178839651494],
    [5.23338572043908, 8.810974561646004],
    [-22.282665992806102, -23.927580009829143],
    [5.127359533718967, -42.69200845704986],
    [-6.883648627566384, -12.464789609083333],
    [-30.051819594157593, -34.592270248900434],
    [-31.41423622788389, -33.94396132815501],
    [26.099018383933057, -5.964109052664684],
    [46.72261333573906, -42.79421698221745],
    [-16.87946492680165, 50.618271976228584],
    [11.784655611801435, 3.05787851483626],
    [-7.707493030315972, -11.17384227500645],
    [-29.689127101672266, 1.3643462572784737],
    [28.07501384983271, 35.450928383672455],
    [40.68271118513974, 33.98641620111425],
    [-18.3759115826495, -21.062911128227867],
    [-4.1686870359907235, -51.59774199416707],
    [37.79020677515731, -21.25569315140344],
    [27.923128114901836, -16.96549254298046],
    [2.980471862731622, 30.814862050026104],
    [24.194317224319057, -36.09867390921044],
    [-0.7302906809664907, -13.90501416421461],
    [19.732770072441305, 39.5112510191115],
    [12.615416253475678, -22.076258163089403],
    [35.31080917864262, -15.22442847019272],
    [7.966427733040218, -3.135764047206672],
    [3.8953021800697134, 14.086354959255692],
    [8.232183298855873, -6.496860401131695],
    [-32.789159664013496, 36.21129419865904],
    [23.38999143197743, 31.66243412913714],
    [10.159000730543593, 48.64601558198207],
    [18.822011318829414, 2.8299014960739814],
    [-16.0300496689279, -19.034335502502717],
    [-17.337522913268575, -10.121813714829226],
    [-47.04414433243613, -4.935191190213687],
    [-12.28669377938236, 41.98843641971328],
    [7.441523050374289, -45.57059243529726],
    [49.5569250034451, -8.293551719831374],
    [34.10184844706929, -15.794405721240505],
    [-57.49720387206401, -6.918346300176069],
    [24.956298051008087, 31.968813484640073],
    [-15.827983808825467, -8.896865681226227],
    [-32.67429665560396, 34.31493965610632],
    [-4.358587325355524, 75.14008711910293],
    [-35.64038302614797, 12.562974249177081],
    [-15.981312708568433, 11.219103163411209],
    [-10.985577660616585, -6.134321558219575],
    [13.22094892452752, 15.734000517173174],
    [-13.29580269992579, 29.62965918613459],
    [-34.26682099451238, 13.130137193699985],
    [-12.928772339620675, -24.543203061420794],
    [-26.240457770475782, -62.94987540357021],
    [22.515598610505965, 34.963671002674594],
    [-36.01307490718641, -3.1160014661277198],
    [7.373796228738396, -34.43717122036996],
    [33.18817762554966, -64.82783977647995],
    [-20.095898521994958, 14.85395151078824],
    [19.01312641956118, 17.93693015645417],
    [-34.16421012920311, 12.146173508013108],
    [-43.930207729165524, 18.814371892881137],
    [3.419071492789157, 1.7467545064581425],
    [57.04081074573652, 0.062403294667003326],
    [20.26447873496012, -63.61072779528695],
    [-2.7879416597328337, -51.216686409299],
    [21.99777973820366, 3.065103129950045],
    [-5.094160794606641, 1.3999796789982006],
    [1.472069170967986, -18.90494578006811],
    [4.507044095689672, -69.91134755405344],
    [-32.23084795968694, 20.88330194541195],
    [-30.090020488835, -34.67913004675776],
    [-37.51226729803571, -39.046853867436305],
    [-15.259799330686787, -9.609960631922222],
    [-4.085281902540571, 2.5942905196032977],
    [-44.8295611498797, -51.45129578703932],
    [-29.305578397801117, 0.7943466359819866],
    [5.428093173553508, 6.397330484828757],
    [-44.65422072371958, -52.1511795548825],
    [-2.0218611368651733, -35.32054999439955],
    [-16.57765729436295, 9.015404421072121],
    [3.8478238740133692, -12.076642521348276],
    [50.71766486484629, 26.627963960514165],
    [-21.376632026952052, 22.56663150996317],
    [-54.869129120765606, 42.661214519396054],
    [48.17548196725916, 6.192675384570959],
    [0.5813326173065588, 3.0246182485063673],
    [25.19556955897255, -24.085899855187925],
    [8.808602459877791, 11.32909128808787],
    [-20.351746134418008, 4.201488710933692],
    [11.886703881290105, 32.900117791872646],
    [-0.3197094102078211, 8.68715999888445],
    [25.44901798742546, 16.52231568013075],
    [-63.341507543732185, 13.550564123351888],
    [-50.32179614645328, -45.85184331132978],
    [-2.9446995632593524, 12.904926755586454],
    [1.5432525130335208, 21.620392645103987],
    [-22.72733650726254, -15.879019243913895],
    [-11.075320889516686, -6.935412552098146],
    [-42.50487024113507, -3.385976482284335],
    [12.568839872350427, 8.220739771532404],
    [-13.505552541528157, 43.153520159563335],
    [15.782964345060591, -27.24684241799234],
    [11.616830636185709, 32.37133822541236],
    [-32.17843924755704, 19.55695890942584],
    [-16.469550349529428, 4.528055871398814],
    [-11.13088160289869, 43.29261828829929],
    [0.5356241277547769, 13.849926518227338],
    [-6.065456730515732, 27.426436899351863],
    [-16.890558474637455, 49.99126917268621],
    [10.155576082097387, 48.682870107471274],
    [62.69429053632671, -19.864702177656746],
    [-5.539248244921384, -25.751827265437903],
    [64.00245176369394, -18.531924096249902],
    [-13.140051868364102, -18.787188780060518],
    [26.398195076249, 48.50349870801036],
    [39.11237576324275, -17.610298434786465],
    [-61.62997913946039, 13.66489522994323],
    [18.316742363684213, -8.756772223720926],
    [-42.99331863158529, 28.70230568738283],
    [-9.853203209394586, -65.79022873096477],
    [26.906947465553756, 48.18194124376453],
    [-17.871581546651193, 20.758091805709583],
    [12.4979844945607, -22.346542130415887],
    [55.47467840484941, 40.62854736803358],
    [-17.910634408572854, 4.784732688616346],
    [19.850047356820948, 37.05924657531336],
    [-6.762166763174056, 8.553445712226598],
    [-4.553702688499955, -8.47103310020563],
    [-1.4415674845512096, 6.14950292254469],
    [61.43254347795494, -7.90281274494971],
    [38.030625311057534, 46.184056398163555],
    [-16.746846401454427, -16.614491537841406],
    [-0.8733098947704737, 21.865964691089577],
    [1.057924957446676, -1.96621820119551],
    [-20.028018397385843, 40.07416019714998],
    [-18.566299019620736, 14.82825391699494],
    [50.600022874232366, 25.653493404891872],
    [35.962993969659145, 6.5342254485909566],
    [33.16217384768566, 60.072491364394715],
    [6.733505865498801, 14.675986417821102],
    [24.00609359435191, -33.36465866907157],
    [53.1050002422447, -7.79774477209292],
    [14.63614333559893, 10.630329556189892],
    [-47.995569136173934, -46.57344321879039],
    [39.857156159466996, -22.430898838454926],
    [7.171725332717962, -4.243274852634421],
    [-47.39649064343778, -35.76128957945302],
    [5.255288793029151, -34.15791256941884],
    [44.582232455426784, 35.72101425437735],
    [-36.20550117786716, -24.707036770879224],
    [37.75703979365888, -19.707023271640768],
    [-23.904094658806677, -23.022820596171137],
    [37.8996704511821, 44.599371725116995],
    [-16.52311865496031, 50.289972210841285],
    [58.90174463996123, 13.208590396435607],
    [-7.7622074668433, -1.8169060102987373],
    [47.86639632787924, 6.656146275389701],
    [58.86492870765908, -17.63551055466685],
    [5.90112540636729, 11.48227315413794],
    [5.404572891059186, 29.22567033202165],
    [-47.974851803427285, 31.619858485577236],
    [30.478266029582933, -28.590616107334824],
    [-34.78389407025622, 25.460481829509952],
    [-43.419518755521715, 13.062689440228665],
    [12.860593021838085, 35.08747554202119],
    [-19.994867281500852, -53.31798238789202],
    [4.533156532001753, -24.896903493939814],
    [-1.9167603263956388, -8.341018055642296],
    [33.07212008059582, -16.377626725975546],
    [-1.3043191015620956, 47.05734462999124],
    [-11.120878039541886, 9.130823140227852],
    [-11.360446692107473, 17.502137973916756],
    [13.68340319424087, 7.894855315593763],
    [30.437948093308034, -12.098928706914933],
    [-1.0663381983233473, -25.486707067839376],
    [-8.22353037414783, 11.165968636413782],
    [-7.495331531828035, -26.727966874987516],
    [26.557478198752897, -35.620567844928274],
    [0.005041146277507408, -26.8004904777679],
    [-37.10947761351469, -40.07292028653013],
    [-12.906602454851193, 61.6174279561792],
    [27.194941996459487, 32.72872570728979],
    [-30.641538692483046, -14.739808071107051],
    [4.170979849458253, 1.1259448676208517],
    [-24.257720107377033, 8.724805609031922],
    [14.610212142743086, -12.434431528748005],
    [-12.098667595876059, -22.323695403451428],
    [37.87988990894901, 24.303578049737297],
    [33.129172749682816, 12.087159987971363],
    [-14.451893284452563, 32.179286422374844],
    [-23.19613352424057, 56.92294722518974],
    [-42.91307621503155, -22.432410562151606],
    [13.957499437151824, -15.65065444260974],
    [21.031810438649217, -16.280617852164212],
    [62.58704559546911, -19.868203436147265],
    [18.557212171106386, 44.16870000066687],
    [-42.91169313170254, 12.801223561181507],
    [-16.372771291338115, -53.932470004829845],
    [-14.683538894129585, -42.96162525901965],
    [-21.114297694816756, -51.45003886434437],
    [24.974921515899407, -4.752120303158896],
    [17.761788583835283, -8.22644310333145],
    [-12.389757993277595, -23.205424380254968],
    [20.15759385521294, 0.4596744707189153],
    [-23.198834804501267, -7.33792361207135],
    [19.263866660822337, 36.96221064612186],
    [-18.761641743546686, 18.943366382935903],
    [-44.75506004643077, 17.157443555608758],
    [-6.667881212159343, 4.427436770052148],
    [16.43190993682931, 27.70710030591332],
    [37.82114431372761, -22.812123146243582],
    [0.09070257607114696, 57.67979167676349],
    [58.98646705054372, -17.720763826580054],
    [16.95809146202799, 20.75040091601975],
    [38.34108857590138, -33.17739203550969],
    [25.990007129358112, -35.26335618386027],
    [40.87462608067006, 47.709265770235575],
    [-16.929356834011774, 10.386686913917146],
    [6.171417050961487, 16.489344669374812],
    [-15.815872655119522, -29.883471882699375],
    [39.82679399162839, -20.794913806038814],
    [-37.103552798824914, -25.50203256454614],
    [24.73275016729903, -23.207908915405202],
    [23.40787999523232, -32.58282518796122],
    [8.952264438500574, -18.12867642858798],
    [34.7340396966952, -46.96099030317927],
    [32.9368818450793, -14.520841611502211],
    [73.61709768857551, 14.001095391886896],
    [7.216993712270236, -18.643992628073804],
    [-55.00141624200022, 5.913551545395993],
    [-8.360348128578108, -18.19144348395573],
    [-9.956287281866096, -65.3187703264085],
    [2.6244977317965072, 2.527282307440616],
    [-9.663415667620015, -10.705837578531739],
    [37.29606854593594, 23.95196048485721],
    [4.244630879593373, -6.383050339597418],
    [4.107361471025542, 9.314655405597765],
    [-21.211794797657685, -21.67664408544659],
    [-11.635059441538239, -54.63111158849813],
    [-35.435614135244855, 22.096478552192135],
    [-15.892043635423642, -7.039925727173242],
    [-29.32352781945609, 2.367425834157827],
    [20.396380179248112, -16.764150265141474],
    [0.48539433661671316, -9.238156424601181],
    [-48.583935861349694, -19.498755074333243],
    [18.27355230168109, 58.2335699860641],
    [-41.55392917120402, -12.85556398521182],
    [17.749111799041433, -9.1067970655637],
    [-23.010594251703512, 6.760328863034358],
    [25.89945443709018, 46.75088073804651],
    [-12.731330437024958, 40.74867636493494],
    [-7.073165755394208, 10.71167971294225],
    [-37.119120885881024, 26.853087983227574],
    [-11.882772225225597, 8.20279846531209],
    [-29.089519334614735, 45.18474519252892],
    [59.41630309109134, -34.42895895454063],
    [17.8455738421711, 16.293446448136926],
    [38.415204339042305, -20.288021925836993],
    [-28.625454120717663, 67.03907030879466],
    [7.853815622429247, -17.19204810791414],
    [57.688868389503, 1.484195903887161],
    [-3.3594519974500825, 9.994636138774942],
    [9.365993941128085, -6.745501784140498],
    [8.69724848217351, 14.36722701663967],
    [35.410878732914604, -17.15356404869658],
    [34.38828495295278, 17.023540786394975],
    [-59.142320415255135, -31.090624751911125],
    [-13.86346554557845, 7.688904893860446],
    [14.528344654924545, -44.02083961180738],
    [18.145762362947394, -41.055636066364535],
    [-49.6034484236307, 5.874558555507747],
    [8.364654748935576, 21.60478954257951],
    [9.093063236429156, -16.377242115140955],
    [1.878449741050649, 55.73931976995892],
    [-4.522288112063691, 4.330513305687311],
    [8.142970521240663, 22.805801961825157],
    [42.80200267998877, -6.519307750019847],
    [-16.955300092269642, 17.809050199414752],
    [-2.3570618367786462, -6.8792904473617815],
    [61.391518018440664, 30.538918415884417],
    [-9.620624437125867, 18.252057099163114],
    [-12.396280448614718, -16.55785005738368],
    [14.714774810059845, -26.8602801606058],
    [73.06425867417217, 13.405790305374667],
    [-39.1192806680945, -25.56024597996077],
    [5.959815842988164, 12.225372088945047],
    [-42.65729945417019, -21.09489658501399],
    [58.00102706899803, -47.90664719901337],
    [34.29485190389253, -14.530032632620276],
    [3.771416666328784, 3.1469995234596224],
    [-22.727416546297718, -15.87886816789858],
    [28.62615794704999, 34.542211437193416],
    [-4.365303437068648, -3.8795302410378123],
    [-41.68107198328294, 43.63604965855653],
    [-19.151754065973527, 4.16333469217248],
    [6.896446570562582, 65.8724185061891],
    [36.454035111264, -2.3383739535548638],
    [-41.40009053481675, 27.600257779745853],
    [-33.12361832236905, 5.544080560123501],
    [-19.605612447378146, 21.228088799425596],
    [25.945921354717576, 11.27569000975686],
    [49.091562697163596, -8.634349237904177],
    [12.22046998577537, 0.3572910942455658],
    [-4.268699988459451, 34.473794310223084],
    [-32.64305140010799, -5.288405918137279],
    [4.0973705297167955, -31.28890244419774],
    [-22.456661771115918, -25.447383869432322],
    [6.752583155482335, -31.75356364717085],
    [7.244389576088894, 15.280011657907195],
    [-45.615173151496904, 56.62654521399208],
    [18.16764471776638, -41.064369271995446],
    [-56.337083895684856, -29.1868357767898],
    [27.750620468784728, -4.941047352111958],
    [-45.43215006036614, 56.14235554465065],
    [-5.6671394905429056, -42.21745024700514],
    [-16.850778586285497, 11.153768178051557],
    [60.84606283237938, -21.206678407235586],
    [38.50479472153889, -34.5259171802996],
    [0.8878037340925989, 6.775321225716994],
    [17.211350712384306, 54.78373195006817],
    [9.730643777726577, 2.2114832658531784],
    [34.71186485526883, -21.12995988160408],
    [-28.69393745104787, -47.053658050479456],
    [-60.15501843430541, 23.92925906027118],
    [45.684993055059635, -58.099597419302576],
    [60.04882109701254, -7.631612730274819],
    [-14.669926855053955, 28.846113164431948],
    [60.92194359439002, 30.3848138380888],
    [-7.15470883884405, -40.921174133095526],
    [18.833963663865397, -10.600340308731035],
    [-1.7035427248937933, 3.7235288691630086],
    [-22.98733804241795, -24.6275768356101],
    [37.40332032607853, -20.5559353493185],
    [-61.35363182053127, -28.650718979511545],
    [-21.60266102636276, 22.98782395313425],
    [-10.874975052292337, -66.91026194495508],
    [-25.699155936370627, 7.153449831548532],
    [-51.82182028309458, -46.44335094287853],
    [46.93766636036113, -43.11261949324846],
    [-3.60636575791572, -5.171208219927382],
    [-61.236618003725, -28.889424619003087],
    [32.48138145621042, -1.5028032613673588],
    [-37.356553463618106, 5.254636398392336],
    [-51.607423140479845, -45.38485755102887],
    [24.37738257693567, 13.175087200465965],
    [-37.70023935171321, -25.32336048064389],
    [23.428623939953045, 10.372933640138438],
    [-25.765372990657, -64.94038754937297],
    [-13.859491447157644, -19.158120177847593],
    [-19.70792384853211, 21.106766252572967],
    [48.339670523552044, -25.201346834734665],
    [45.76334738424341, -7.987385596077631],
    [-3.9678203861441874, -19.080998418332076],
    [-2.5998176197413563, -49.36613600053822],
    [33.71929049776485, -12.112801416325585],
    [-6.554636671561566, 20.073969165047277],
    [57.35548605243193, -47.93350112177561],
    [-59.23550200277023, 23.83222094017639],
    [26.023472674248232, -6.944027043472861],
    [62.845100264376256, 31.947506954957895],
    [25.01665268790445, -4.436561800704921],
    [26.991934150074496, -12.22192208343898],
    [-27.72827898782509, -46.442098968868294],
    [-18.696372187658014, 10.917670653247317],
    [34.00621077164849, -46.75626100412701],
    [34.46937514492212, -42.709042282657805],
    [13.212531367151579, 0.6509464426682573],
    [18.49341373589171, 59.34257261336545],
    [-12.808344968072392, -38.00862286027172],
    [-13.470645905321536, -22.10187037976233],
    [0.20737659865999586, -32.45355455801032],
    [36.10780305370759, 27.09830595429266],
    [-28.270412972361296, 65.69033837298909],
    [15.225723109589575, 27.44570156892076],
    [56.82405573895957, 39.462802409822395],
    [61.72441666733435, -20.787824136233706],
    [-44.29584233600707, -25.21880269860826],
    [10.37623240580676, 8.396605328166483],
    [2.021238389304917, 56.73681578358834],
    [1.5801590847861209, 44.413392096533165],
    [-20.96068938722516, -1.9557249309319051],
    [-10.501124814460727, -65.70428039777126],
    [0.7473946497490241, 20.89726301925279],
    [47.756062319694585, -33.23181318036316],
    [-10.6598795313013, 60.516058001163806],
    [2.94726968377648, 26.21566731286101],
    [-10.482377793223739, 0.7077655526149249],
    [20.733747063577926, 2.0060266747460282],
    [-59.62076269816318, -17.303639223191283],
    [59.38332130416177, -7.623193063143396],
    [-14.577328061851967, -43.09903584182039],
    [-3.5056663211306307, 19.07003234477112],
    [-45.30217431644162, 30.016843993671515],
    [-14.392196163555267, -7.9170581346270055],
    [47.587063669002355, 6.328859317327079],
    [7.623462876725392, -7.198826717467106],
    [-28.87489560359502, 47.85317381788586],
    [12.58125846198057, -42.11974082338486],
    [-72.38919448128252, -9.121391614333207],
    [-9.703217946364127, 14.399453889450989],
    [-28.436903326346137, -11.34366353432341],
    [-12.196531789842275, -23.381659491421505],
    [-38.36971781233668, -12.375647763063666],
    [17.460270307377804, -32.67558457578376],
    [19.764292194848938, -7.740200945772103],
    [-61.17865439713909, 33.838004859905936],
    [6.976110881803235, 8.576441641912393],
    [64.20799518540461, -18.02193769150628],
    [-25.243769016810262, -10.420982002981418],
    [-5.863542085362808, -0.7421479685786425],
    [-26.696251285176054, 16.857515376487125],
    [32.74249110229689, -14.216876730833794],
    [-35.26396802205839, -1.524740531449007],
    [37.230816676398014, -33.26201982695024],
    [-0.3405449569463984, -27.531939358245488],
    [62.845781156748565, 15.232442707940093],
    [33.326814108513126, -46.03371303474595],
    [5.0180203723270775, -0.4697628905474174],
    [-26.23177070924187, 9.474416227513304],
    [18.481899865992634, 20.054547747272867],
    [5.0450473752684175, -24.451169180987282],
    [26.651271086096834, -17.240244178573562],
    [-27.592402662278257, 27.513169124171483],
    [41.80774366203027, 13.965848480171754],
    [13.701134094537897, -7.982612773082689],
    [2.823798101860149, 25.74513167163676],
    [13.380308726610442, -26.683347602591272],
    [-14.53977849031213, -3.0540493693376187],
    [-34.07433719514026, -4.223976465185347],
    [-40.82205146237196, -12.859769942170727],
    [-32.95902433217484, -55.83511659721363],
    [19.8270139741303, -32.337325502400375],
    [61.429098560225306, 32.042682886894326],
    [57.84472884162763, 37.09976433724876],
    [22.718028916417097, -3.945528647335392],
    [-21.79186353936364, -23.495746694360953],
    [-27.058589577488984, -64.5318359837802],
    [36.456577061184454, -2.337065578994982],
    [-15.398512973469668, 47.62592082927215],
    [-2.1455165789659616, -51.83186955730079],
    [28.648013207988644, -14.17475293745542],
    [8.548960948720644, 12.882459972806721],
    [-18.890668644465848, 12.299025152767456],
    [11.871722583531957, -28.410115088596797],
    [-12.37711858404055, 42.17702647148613],
    [-43.22108638708325, 20.105973599086163],
    [-7.286202468610956, 15.827829815914956],
    [0.6940283803039822, 21.7655373059438],
    [25.93792122844841, 18.287474360151638],
    [39.70120726931783, 47.25266167732303],
    [-33.068250109298084, -55.668597303879324],
    [-8.873155562905364, 2.3984507379362316],
    [19.328627598010748, 21.23561057270214],
    [-20.621507107600518, 22.449710447331807],
    [-52.148956881643144, -44.63020818758982],
    [-11.892778092784624, 62.6115770187538],
    [-21.694435200910313, -33.68171661793517],
    [-17.3481292035908, -18.572964266643478],
    [-9.751520596123191, 18.255240437629226],
    [-42.19568623051121, 44.112865105952494],
    [-1.649870763879393, -34.53963308729524],
    [-34.91813234519042, 56.077194813082315],
    [39.9028828859021, 49.379496879459694],
    [7.01015841448303, 10.17269320589943],
    [23.341238996239316, 68.74026177674358],
    [26.822814866241558, 33.35182063760426],
    [1.6658944190961416, 4.225482208711703],
    [-13.360484201589815, 29.615126026357483],
    [39.453273677006074, 4.812553325175206],
    [-29.68656778800887, -13.124181292671382],
    [24.703988328175026, 21.575940087898932],
    [-15.19535062860075, 46.80828005524512],
    [47.343680434587185, -6.185978715718774],
    [20.884992268622145, -51.101466066439876],
    [-29.279990453218296, -11.743510430539361],
    [-10.503696032737691, 14.23400149530271],
    [9.851184672714393, -18.51980704219417],
    [-4.220434325129346, 36.13499294095027],
    [2.9558902945221606, 1.0871338517718632],
    [20.19952216134592, 17.59647658711972],
    [-13.699219105287183, 61.09018562278948],
    [-54.564934513200214, 43.71604627078653],
    [-20.698463666784846, -53.813596866521245],
    [16.632437944570373, -7.440307868328073],
    [70.73094135324197, 0.27018092004038663],
    [0.38154191954070077, 19.879880359293903],
    [-37.277418698193166, 5.252100544630968],
    [-4.185734617346428, 5.902131270587165],
    [33.21471636106719, -2.1881518285610544],
    [59.11274932797191, -34.91330073423955],
    [50.46209772992433, 24.25273352333141],
    [-73.68295273240187, -7.4138439915904755],
    [-23.69746120913608, -16.35790122323473],
    [-33.62316753627337, -54.81526710463997],
    [5.621682561354614, 65.55882050537166],
    [1.5370364260857416, -5.264264639422695],
    [-24.317900500814005, 2.595372779834238],
    [-28.571959543983105, 46.27402358956521],
    [12.866760901061433, 35.10145303852824],
    [-7.9360900847314015, 0.07642199644807418],
    [-1.5691872017864026, -33.0775770282214],
    [-0.6265369462325633, 46.43397431805179],
    [4.092703632595804, -68.5256623378313],
    [32.201740793037985, -44.41767156676296],
    [12.816451479770302, -41.54963248229102],
    [-25.51531500962158, 8.401299696418777],
    [-16.985809862323677, 0.5998578874334249],
    [-17.64327637284687, -53.72173840314232],
    [-28.07541160047353, 17.771086111410202],
    [-3.702789859214415, 72.53091314793124],
    [24.1899298057202, 22.66679694764577],
    [23.190267134419067, 20.4662036198596],
    [4.9760004503788124, -32.22128894332166],
    [69.75426322342534, 0.526009756362427],
    [30.256747130153087, -11.335689049353576],
    [-20.284596411501035, 39.31313812348214],
    [-14.469017231189778, -40.01312861829211],
    [-44.31448645714523, -52.60431742905883],
    [-27.599524581307058, -25.516223275431674],
    [-0.5542784673009516, -29.82394633920913],
    [-21.69283092201965, -33.68528531982738],
    [14.873010742802455, 27.370729374407375],
    [-3.449546860372312, 26.03197826825335],
    [32.81640015967025, -64.41463449840265],
    [-13.751362335166066, -10.446538421208428],
    [26.32489779982883, 35.02467053702092],
    [-47.96661536118294, 14.573448954727883],
    [-3.9491102612546007, 31.959783367508624],
    [13.928743377839469, -15.390427500061984],
    [-1.6594551770161148, 2.232483515286495],
    [71.42571603383632, 0.09000708219311397],
    [0.5915480912693811, 41.59271007890475],
    [36.05333599200404, 25.634919348051426],
    [-45.77144186750449, -52.25526217016605],
    [-6.126980733731547, -5.78155977975919],
    [-24.03890440314236, 2.222635898469914],
    [38.65366027036884, 4.644386021159673],
    [25.46873290858787, 21.33249012474295],
    [-28.29765948500284, -21.527134963195866],
    [-47.93578833542697, -37.69520660901268],
    [-13.097844933772308, 8.83568667498646],
    [-12.796022915738503, 12.515832034704154],
    [18.031598675201433, 57.77121628770002],
    [30.830252659166412, -11.472821074804578],
    [14.940882150775964, -24.790313990879376],
    [18.55781735576597, 44.16417920503924],
    [0.44853273247840947, 11.710138032343915],
    [-28.875977470586434, 48.806495980077884],
    [49.733879911857144, -18.911084381747504],
    [16.218583954987956, -73.89615499107137],
    [-10.742140027605178, 62.68474822306975],
    [74.06977703244507, 14.493897436382367],
    [-50.21580102480839, 4.619238068560607],
    [-49.280508370833196, 31.577016224713645],
    [0.4540526612952255, -27.958424763654683],
    [35.863306964620705, -44.7830804424624],
    [-2.524416722940065, -18.96276414754942],
    [49.600068667835004, 5.457445042286367],
    [10.108890010925345, 49.22385825815927],
    [-40.89332862116053, 42.9034472993461],
    [-21.4064805169976, -55.06705868577173],
    [36.94624548665001, 23.071337820220943],
    [-7.716346940245847, -17.317131112442752],
    [-16.876884166022744, 17.960015112040697],
    [0.10580446055020917, 57.68253877449545],
    [-37.94261889449436, 25.683201196359768],
    [36.37299846859921, 62.09227616081181],
    [69.61143439163487, 0.5633726115420902],
    [-23.77899457445254, 29.61722370750227],
    [36.737724427760796, -3.5579532460535015],
    [-4.222054458750743, -22.545594971245944],
    [-10.668136142229514, -2.5576159938978704],
    [16.72719956252976, -5.903556189316978],
    [-29.577692794537125, -33.7946092750054],
    [7.108539281859233, -47.472795433749425],
    [-9.04000384589052, 42.63339278125295],
    [-9.546372604405319, -1.309987529265482],
    [-11.303900759569753, 42.729928220756506],
    [3.163377489348152, -13.334091706522413],
    [-43.69989573408325, 15.713796401249805],
    [24.385963426285578, 20.16250073198931],
    [1.4001652437722114, 1.1059348283977521],
    [-23.519086955561217, -40.404658412414925],
    [18.83146977244238, 2.6540285742199385],
    [-44.168833465456245, 29.40037648948939],
    [25.952189353492052, 46.877002023168544],
    [13.052469822655437, 16.870334585500355],
    [58.77072736576165, -34.59077464367542],
    [34.939401095311425, 61.346549075178864],
    [13.456548658677155, -37.60629900201754],
    [15.570751347250104, -73.435252109536],
    [5.203530806649584, 2.4307948476599224],
    [-21.135915160360703, 40.16496749212134],
    [42.58803558944946, 13.332574455319582],
    [-47.89570274345012, -45.942199998339],
    [-17.857388941373067, 4.487709499252015],
    [-67.28414056948102, 3.7376372039242862],
    [34.21754674953773, -45.467199809160775],
    [-55.359736528737486, 44.958430763295965],
    [-4.258465325493768, 1.5523412060337394],
    [-53.79540886350599, 44.00663232896683],
    [43.872025256286136, 35.4601514981791],
    [13.959562544429685, 71.11619721546411],
    [-28.614076568509727, 26.959129731213757],
    [-74.22291147032804, -10.274822747041757],
    [-47.9420452852446, -47.06861258964204],
    [43.86087503337857, -6.334349849994291],
    [-6.534404443905448, -26.130623276881714],
    [46.9717010199489, -7.223146998175263],
    [26.472143638702455, -19.33710518028737],
    [23.166913282327176, 31.552599759221298],
    [-12.133403311643649, 62.15361432677389],
    [-28.137342026521615, -46.84025430306181],
    [-4.350202277559425, 75.10691389306929],
    [14.318525709106353, 1.7020847657395914],
    [7.932791450350912, -30.804808444554777],
    [-3.1720116197370385, 33.92797918177822],
    [-17.502811228868364, -12.161751598522812],
    [-20.977453738562396, -53.59126153399407],
    [45.07370991852487, -57.80012318397502],
    [32.87560832752688, 9.645821841234893],
    [41.925453114290086, 35.03125264995556],
    [-27.566011495347166, -66.3569758432308],
    [-17.838689491467363, -0.41465374187986404],
    [18.84283431976366, -7.34786545770275],
    [17.22675926658668, 54.780522044343385],
    [35.67094995250957, -2.295286321487802],
    [-3.073025755823446, 12.057515995112656],
    [38.33476889741046, 45.67191729076501],
    [3.2489836647323256, 9.714695346546947],
    [26.434524822817096, 48.83831657872582],
    [2.5666892048783136, 29.49879248356075],
    [48.18458252697895, 7.79909699325564],
    [73.4136974037527, 13.779689910873202],
    [45.04072547818646, -57.62291637546098],
    [-31.561287883577894, 22.1551262336901],
    [-55.88772435297939, 4.157798748300109],
    [5.908878132646072, 13.738050229093759],
    [-16.240424080842317, -11.720411612606561],
    [-4.757171091186036, 24.849968454843648],
    [18.985405449756755, -17.86927343672],
    [0.34088982978715465, -3.02262483192999],
    [-5.651031450402543, 0.5289611145458594],
    [-73.4865082909851, -7.907598271761812],
    [-16.269879568794895, -18.855032067382474],
    [-2.3099982242136057, 0.2054597990078402],
    [-40.3786011668675, 4.917151618217544],
    [-3.103353088154816, -4.035455143583421],
    [18.30055920138307, 55.111795419381245],
    [-8.720088853181208, 42.612209754696714],
    [10.639223057603264, -57.33415028140302],
    [-2.598902948085986, 10.080113265755518],
    [0.3055048757032482, 45.28586706922696],
    [3.7456509802764986, -41.64171133282965],
    [-48.172566240221634, 31.70818960703742],
    [-30.257150374573644, -14.09112578089688],
    [31.37207728284909, -44.003472499137516],
    [3.502410083518321, 30.29119884707739],
    [-2.6995585461171094, -32.92036820253315],
    [61.492642464945234, 15.139698195819443],
    [-59.63302981751875, -17.30617732380979],
    [44.86348561574953, -59.23608579679466],
    [7.36586532440283, -4.283136297084745],
    [22.484323676568167, 20.865424127450716],
    [-2.0564448621148936, -2.0719490274007097],
    [37.831101802480354, 24.933501178934083],
    [-30.410871371051716, 26.695966869798543],
    [-2.7032237644131816, -0.8885596724983833],
    [-10.948775754923696, 6.094988268349331],
    [-47.4366854768168, 31.14310301592939],
    [42.83073796512589, 12.91624870460655],
    [33.39646317968881, 61.133827541423564],
    [-22.689665188168068, 28.63252308285839],
    [-32.39830067515318, 34.98463791549894],
    [-3.6595174353703412, 0.9369339383303611],
    [36.344938946209616, 25.763510515622116],
    [31.623976702771113, -45.33932326924842],
    [2.6303153774819488, -18.287851523931195],
    [-15.85639145534533, -29.862782123816125],
    [-50.449598722686474, 4.156479062558341],
    [-6.560041121651809, 14.468510619965839],
    [44.706369417359205, -7.495101600211687],
    [-11.809647814359144, 62.414865553468644],
    [-10.620115088420716, 2.741445416987491],
    [56.17739040611957, 39.79389513607138],
    [-23.19745945758325, 56.923409398672774],
    [2.7676105018154824, -31.796912297114172],
    [19.82579368445565, -32.34394003422128],
    [4.720340489950678, -3.596353318153579],
    [-46.21218188242172, -49.79028384106353],
    [-33.22091525361875, -4.859835774286767],
    [31.76837487383163, 8.740785191342445],
    [-2.970013293203734, 2.8871621198493784],
    [-41.737175595037854, -3.8078500513652083],
    [4.086953215616751, -67.26878416174976],
    [46.402721249700576, -42.31685290400236],
    [-0.3557366564646737, -0.568119548642986],
    [3.643592636172546, -70.04013989687854],
    [35.19672818814423, 25.301819254138806],
    [60.79518048304357, 32.599539719444444],
    [-0.31876693137570217, -14.990775491765032],
    [-51.44975992070819, -45.59297498858743],
    [-58.88601223617974, -7.630796897768962],
    [23.344305400312237, 68.74999418289234],
    [-24.12978700432296, -27.62610125219066],
    [-36.68596345819698, -11.974007688431257],
    [2.040788821693227, 54.78182119199786],
    [-38.30913384705012, -12.952495951548466],
    [45.63126491629467, 8.755322438119526],
    [-5.111772276990315, -7.274459894683161],
    [-3.550665139175451, 71.9076439993229],
    [-48.58219664304682, -20.33138446376438],
    [-46.10790399777686, 17.3889451262603],
    [-60.97129512000331, -17.572615346426446],
    [10.697224502342953, -56.81922666376004],
    [-5.316738735425399, -42.873284213373545],
    [-27.13210342198753, -65.5820347112317],
    [39.507836078534694, -32.881522276658856],
    [-3.5237690876738696, -13.634836477647687],
    [-47.537958831676406, -36.26015933946993],
    [-36.078302311762236, -28.603075034752557],
    [22.752952864864415, -54.262574079424105],
    [-72.02427790472593, -9.542811076828592],
    [-25.17091321687551, -2.9858934134485047],
    [-15.46060370759851, 1.0952829823403598],
    [50.11941335541375, 6.362808020507939],
    [10.463584098395577, -56.23630171043218],
    [31.12051454616608, -46.17002137502407],
    [57.7899168813015, 39.38158178359601],
    [32.91093051060108, -64.3098486683054],
    [-8.1994645232002, 4.806246741472689],
    [-40.184658421184714, 5.0447952525218644],
    [0.7504045921825352, -24.642027954009528],
    [-0.5462111772102708, 4.962648429712443],
    [49.710040008536495, 19.617060916858332],
    [-74.19881966685813, -8.833043592803898],
    [-14.413784041257433, -36.26856215904902],
    [10.600706977863549, 44.257613508745315],
    [53.77809537464315, -7.6256700076689725],
    [-6.14500122927133, -39.88645853140793],
    [-1.132335970725688, -32.36947508029206],
    [-58.123795730069546, -7.797464213682784],
    [-12.534659783869978, -54.35893901772459],
    [-37.24911290696263, -25.464838590343224],
    [37.13872358872104, 4.464825100913211],
    [48.42240708367847, -24.2731109888736],
    [-45.991534943795486, 15.33111708538867],
    [48.46767240273942, -23.908431833985038],
    [-22.005319593949828, -2.8806826566096895],
    [37.85417245846725, -2.4302204726672962],
    [39.06391093534816, 47.27208590412814],
    [45.35640533282158, -58.66849376540236],
    [18.29631084556018, -17.567024560287894],
    [10.37360871876302, 0.29100500854369526],
    [5.135874122632715, -42.702580503104556],
    [16.266893774457227, 54.35552675088836],
    [15.624035000074496, 15.588897411928606],
    [12.524736361964385, -0.8557434367984513],
    [-44.04489198984285, -24.842055212409615],
    [-61.73527671147372, 13.658035463424065],
    [32.518183396797745, -47.828350731761674],
    [-18.62983621755973, -53.5103388250236],
    [39.99652183484888, 33.012281090315746],
    [18.58016588514213, 19.583287120956847],
    [26.764224822218583, 29.473624256216226],
    [18.911696242642492, -19.32536758811299],
    [-61.178652115451186, 33.838006302531596],
    [6.268027467852394, 36.1410633372221],
    [41.42437414421468, 46.25836260666048],
    [-14.271964995710768, 32.6528275469534],
    [-37.098632811520545, 24.27780313239091],
    [23.753676179167158, -23.702153311069537],
    [9.49762816671404, 2.0525093137502086],
    [-28.77271495918549, 67.10802623026754],
    [-15.438755885215405, -3.4892599328139666],
    [15.155620665585474, 8.006302169945652],
    [24.897682966180938, -23.158843268932735],
    [-13.640085264618454, 63.57753816917375],
    [-2.852599015472994, -50.98116285542499],
    [21.754250294048127, 2.976051755194954],
    [-44.19714377639328, 17.028852868184337],
    [-25.601775397669634, 7.744664915216943],
    [59.952308257585315, -7.850543875612288],
    [-33.19518947631598, 38.51014545423729],
    [34.658321700261006, 7.658093686007493],
    [-33.18080628914872, 38.427785008156164],
    [-26.935986182784845, -65.3802679299804],
    [6.7362884332550585, -32.71062012294514],
    [35.32993637204632, 62.30088971896611],
    [10.434714508721976, 45.30889204901156],
    [-62.89275599339143, 24.321842731428212],
    [22.74169238090765, 19.647074262088154],
    [-28.067473118342242, -21.882671319135927],
    [-34.87810837967079, 55.61983196322948],
    [31.222337417484514, -19.9630026080074],
    [-6.507557941875429, -8.29706373501062],
    [8.29959928506933, 21.89530908965723],
    [47.43047830886618, -44.49412441091677],
    [12.162483143559687, -23.54516588292532],
    [37.25920613842909, 6.411273229345503],
    [0.33214054118053277, 22.814679027326957],
    [-13.998544773676567, 32.9314405763257],
    [-48.91447908066141, 14.348594474394773],
    [2.144398469199104, -26.665270510378466],
    [2.1472630876856584, -58.03454724348],
    [-44.16964463960563, 29.40093542247677],
    [-33.660151543265904, 11.027132828998866],
    [-8.005581789713698, -6.674291362385187],
    [9.468886150791914, -44.27925692020813],
    [-3.4495155069883054, 0.0011058424626444944],
    [-14.348811087694616, -2.955143907871367],
    [11.998010121668594, 1.6533807665607958],
    [34.5112945702146, 62.99675270781351],
    [33.015726398209445, 59.877494326303385],
    [-7.542638173329513, -17.0978349677283],
    [14.19618440016578, -43.7421020285037],
    [-25.49770267805355, -41.85013678490661],
    [-0.6313451405949128, 32.148846886784916],
    [-19.417566242560135, -8.126221611708067],
    [2.005933116336235, -6.186615374550421],
    [-73.961831543645, -9.856213964156943],
    [-27.332937868584334, -47.28599877199515],
    [-28.18578427275841, 65.28463172845605],
    [-4.390754073842761, -1.066152152425696],
    [33.03999637836846, 11.154856784386034],
    [23.640132261160343, 69.65392376231482],
    [-12.057684177611135, 8.021915682328164],
    [-21.07290558887493, -25.291461626681034],
    [-37.533943404093854, 4.512794506370922],
    [25.481253885669915, 10.85721884902108],
    [-56.23471212872501, -28.57659452502215],
    [-24.1080058226059, 31.569728210909787],
    [60.0091189807149, -35.59044991382841],
    [23.57506005950583, -32.88736374263703],
    [-30.742521464805876, 25.786325422509854],
    [-51.026796764947626, 3.074174705700619],
    [-19.515981257542325, 12.05871880653318],
    [-4.720055575670625, 7.943382340637022],
    [-36.34155359349959, -27.631498625299376],
    [45.56339029072924, 17.95985622433047],
    [-5.009775830040341, 33.20859138983687],
    [-13.38104041323882, 1.398211523155029],
    [-30.778644872951062, 25.909226178042662],
    [13.434293702860364, -25.834957522058012],
    [3.3997410627777405, 8.094488382391324],
    [13.384030533280251, -4.983822692687748],
    [-6.020581172791207, 13.104866784217602],
    [18.552526542809716, 59.65583638664703],
    [37.42963580469806, 45.794496759611974],
    [21.969264391918472, 21.199665751605156],
    [-0.3546461674980487, -28.7355186759542],
    [31.132288571771397, -17.338368022117294],
    [-23.771013461672528, -8.659048854256332],
    [20.059529977325084, -63.32450522680945],
    [-3.706483004123365, 72.546101492823],
    [36.44991980746977, 6.252905171573915],
    [-20.66799903571927, -1.6959512780766666],
    [25.733555591844624, 1.8142639367865079],
    [20.677243112450423, -52.19401848245299],
    [13.144875639262919, 15.681822691212664],
    [-1.3081799369927172, -23.188790511977636],
    [-24.218299018036063, 31.253524581710856],
    [14.381107416519209, -12.679149592891374],
    [-35.533422970275815, 23.04599893752361],
    [40.48871809899238, 33.68551726846546],
    [-24.105569234042076, -26.127309696822483],
    [-8.20598170815214, -4.096276523862491],
    [26.587820237622037, 2.073031043233789],
    [-6.600330936855237, 10.277641656748413],
    [-12.049063831795568, -15.238124597141299],
    [-16.31629640914686, -10.45366445838235],
    [-11.810060048118846, 24.742629996989887],
    [50.190212761191184, -18.064574995142355],
    [9.437033267128148, 9.173399355475775],
    [-11.400947191127516, -5.367024017060328],
    [-37.82218325028954, 24.87537374945251],
    [-33.358624934665215, 33.10908802663884],
    [-54.87937009234488, 4.805862804679211],
    [-11.701930029605098, 22.226336797059083],
    [7.269102811874699, 0.6884834738411251],
    [25.742109300178164, -34.288524068052325],
    [13.429025150074601, -39.086474319861516],
    [8.940302746571483, -10.883988378843712],
    [-24.978814724028712, -11.190327497025605],
    [-1.36252633439613, -7.163306217900952],
    [20.979383614740406, -64.60165442042282],
    [57.7444219685095, 1.5712443385990331],
    [1.3682978459993347, 31.55512997622604],
    [-28.97211371389818, -35.420251750483004],
    [-40.73805250059252, 3.9958944786245323],
    [58.03030199320484, -36.01135633202884],
    [-3.6344534174767413, 27.983465385659173],
    [-0.9751466051124134, 45.63318232375553],
    [34.48801337591474, -43.80221593216878],
    [-56.318942394873176, -6.338530535072559],
    [-33.121462727646616, 5.545834858517064],
    [-36.79653942366964, -41.24105977013923],
    [-4.248332589291011, 33.211795900891495],
    [-4.544029792002374, 0.3586815427402538],
    [-27.439518715175605, -45.78347591330743],
    [19.044535267072256, 56.44079899858512],
    [-34.46160395755044, 12.599627601532191],
    [-10.588685472785155, 24.223042151615278],
    [34.271569920932194, 16.659193217067962],
    [26.034956869080943, 31.163644326862272],
    [-2.2758628688906826, -29.12305775645975],
    [2.1260800271125304, -3.1082368336153454],
    [33.014738186609115, -65.27753277573737],
    [-6.157648788576756, 16.29260568095821],
    [-45.45585712911491, 14.370527116231484],
    [-24.11335836756684, -40.332293551614256],
    [25.291954295062034, -34.85231862026937],
    [-60.238547187938444, -30.87511456380632],
    [4.27677306630784, 10.055370692281215],
    [26.61253469042261, 9.297860597110315],
    [-17.39497731768469, 12.703729930077529],
    [40.36393873988632, 48.25320624230423],
    [14.735826722271533, -7.5386558799441845],
    [-13.297266972128394, 22.365067278699765],
    [63.91278935801798, -19.825191842812938],
    [30.269719959248487, -46.172442037353456],
    [28.08168222790038, 34.25853455072628],
    [-0.7353124604225036, 6.894908726841418],
    [-27.695849717887526, -25.494222313659414],
    [18.8934263872503, 1.3276617088632883],
    [-29.138204949552595, -9.69649185402601],
    [-25.110106379808744, -42.79403413713465],
    [-2.822069964750669, -5.494715880026731],
    [-4.570080120255002, 34.414444154809594],
    [3.515065758200668, 4.675525305122374],
    [-29.281799568901263, -33.32670099481157],
    [-12.182178349081944, -65.58009058767772],
    [-6.925229370242875, -10.953667962389606],
    [9.081341031009497, -11.193878247641797],
    [-61.600657370489635, -17.697836681727157],
    [-19.235148149683138, 9.019980340144077],
    [46.27627410551059, 4.904708131547744],
    [-61.81805528822883, -28.12073387738422],
    [1.7361196667701506, -58.53750329443309],
    [-3.6563561714927886, -19.341046532638327],
    [-36.202834511298974, 25.484731325045047],
    [-47.85996893668089, -4.007638375165151],
    [0.35094001483471865, 46.94005724035845],
    [13.188909398764059, -13.416300707639419],
    [-1.649119603328321, 32.33485109914168],
    [10.422113643914269, 45.38359424091762],
    [-49.73346316720509, -20.744577655212243],
    [19.205075135567, 9.498652460132838],
    [13.854194704658534, -27.470953749929613],
    [27.527753390430345, -5.0418800875550644],
    [4.097544324524631, -67.76708479709521],
    [-3.6661264331004855, 4.446114170181969],
    [-13.825228219989054, -30.87776220366005],
    [-20.897127743270897, 37.83805252730392],
    [-11.33901259091676, -22.690618417523],
    [34.78322175972297, 62.060301448733135],
    [-20.641783514533433, 12.998327452575868],
    [16.816896321296497, -5.457896768777927],
    [-6.395463699947644, 2.2075183188077454],
    [19.902056621114852, -52.7086212734649],
    [-17.930399865900334, 19.388412709661594],
    [-11.657598620628677, 23.177794845247018],
    [36.3838463339332, -20.03449075835441],
    [-49.50767434838108, 15.234015794144986],
    [12.29299289083852, -4.888854938005515],
    [49.87736135810943, 19.68677569332913],
    [12.729211803258268, 6.805534813791631],
    [-0.9807205782342927, -30.591876159266832],
    [-47.59804600284628, -20.17843338438609],
    [14.313736692478034, 9.000509842860515],
    [2.193163943719792, -57.978079371942364],
    [6.925298323259592, 65.88025434089884],
    [4.052947018034062, -33.01108066052949],
    [-28.44529853466964, 1.2611247131025778],
    [12.38510985464017, -42.96477331201615],
    [48.75166042432808, -22.613554382754828],
    [-47.69858343966867, -3.9760290645352896],
    [13.878352620056956, 7.6457244542045375],
    [-47.14627857022728, 14.044402590054583],
    [47.61347479344499, -43.86540984281157],
    [29.7944262156028, 33.798041233037246],
    [-23.071628753707447, -40.44615686178008],
    [-7.277047754547294, 6.9502150177215025],
    [1.404152886894326, 19.005870034589716],
    [-4.581074318610579, 26.730872694511877],
    [-26.50471891527685, 28.362601456763983],
    [-7.443958323029843, 31.582452272012535],
    [-25.005562467057096, -43.19991722646176],
    [4.568636691560623, 3.8066947091359222],
    [-6.971718031912115, -3.946397070336232],
    [-3.885611078194147, -26.628085476767794],
    [13.959564057111281, 71.11617856898866],
    [-29.020274902888914, -20.49724179643176],
    [31.15902121014996, -18.704270421763024],
    [-14.348960111744153, 47.45478332480368],
    [-26.639541657365363, 16.814735074547734],
    [-35.04318509757823, 26.69760083430863],
    [-35.910306772356755, -29.329059934823878],
    [25.46907544044141, 16.867888173480587],
    [6.028931240981747, -31.970448880569858],
    [-49.206612138575714, 3.2505899326288366],
    [-14.994105390434623, -8.428764845328086],
    [11.432277511013611, -57.111710244107215],
    [-6.517509291872301, 19.067749258079317],
    [60.937096718582765, -6.823596294176319],
    [57.18378039352163, 38.88280262189107],
    [61.725405907853414, 16.05182401103965],
    [-57.875990491886924, -7.32108246220127],
    [-5.102298245809775, -43.28241442788806],
    [-3.0414283232430144, -31.185757082172493],
    [2.8115296455349905, 23.439885611805774],
    [-11.7689423301445, 5.312096468947587],
    [-34.943346807544664, 56.36723769506416],
    [0.6755778148121665, 17.973749675730584],
    [-7.180869372529737, 3.945201830233141],
    [12.637259579589957, 21.610346590058683],
    [-13.396294589746008, 17.11380688509709],
    [-21.06162050399682, -22.655104759963425],
    [19.353852959139996, -17.600511418000305],
    [0.15444806247225165, 1.5138651098376652],
    [45.583872071772625, -5.076768686230572],
    [11.986748855181059, 4.28429835224887],
    [-1.1528721901341268, -4.625507820378633],
    [-2.568838042480682, -50.067605489661034],
    [58.44878967384456, -34.04595597155666],
    [-6.167418109874713, -26.001316603307906],
    [-28.43469262965816, 46.40228108952255],
    [-27.314319184355888, 46.06569918747985],
    [-36.776195231737375, 25.342136324652063],
    [-1.5930041743695316, -0.6796441286925367],
    [-6.157400661851695, -41.4041204851971],
    [41.2271896558892, 35.91267568965886],
    [-14.125130386545251, -43.77229696308137],
    [-56.06328007089133, 44.86167857948437],
    [-52.03958562142975, 4.525507871146102],
    [-45.9737361798047, 57.578138922037546],
    [2.1495335059561893, -10.673986495745508],
    [-7.014345612025465, 1.392331847095571],
    [3.000857693843682, 57.0802016291129],
    [-23.463770835501066, 1.5658645638021285],
    [-10.64009152367878, 16.56597634063716],
    [32.65515856441986, 59.340376383976654],
    [-72.75402786441929, -9.925608900454748],
    [-40.13873646018049, 42.205954782071075],
    [-38.41802866307508, -23.089829512390846],
    [20.23945951805018, 37.09789868810723],
    [25.686743478108845, 1.8112496374408515],
    [-2.3728069333564434, -28.83534212218301],
    [-17.235749261296174, 0.5262327638655685],
    [19.202897474800526, 39.373170852202705],
    [32.036693203712275, -45.579115814735],
    [22.98209647613229, 7.625578464129976],
    [24.676799860413375, 9.685445306731989],
    [-40.11519407409853, 42.183567491997685],
    [14.063388536762607, 6.507383743968487],
    [18.97949510350401, 15.606739331622649],
    [-4.040838439605748, -2.1317980729921966],
    [38.36546315482797, -33.168381570530286],
    [-2.4433452146288115, 1.447178186591594],
    [-27.971743834644265, -11.272548734846891],
    [7.010614595929855, -46.18839923372762],
    [-35.52115572971098, -10.95834816272895],
    [-19.385000382353482, -5.955906693105916],
    [45.525535395708175, 17.931007900727455],
    [25.96701533267296, -20.939822610095696],
    [-6.594394789493196, 20.816905593848414],
    [-6.636675982402995, 15.620152383883603],
    [-47.67893576755917, -2.501885536528719],
    [13.477353114654985, -37.232840713438975],
    [-45.42608826808998, 56.124683771685106],
    [6.165752687351045, 3.245914909268882],
    [-41.27034909630609, -4.270243846739826],
    [14.668315739589895, -25.461175899576045],
    [47.75620857001609, -33.23185428063507],
    [-44.83798671937962, 13.812686985331071],
    [3.2634865492254845, 20.832540690161025],
    [2.590281820035694, -18.313067986501235],
    [-55.28079887643719, -6.278181039806446],
    [61.754356099203896, 15.328516216510817],
    [-62.83262156021582, 24.313440710752815],
    [7.010616344235682, -46.18840945410782],
    [-41.53869214321141, 5.180576972353781],
    [61.47729610398871, 31.693545508831424],
    [6.328995636659994, -28.08432583385302],
    [-15.685631991358807, -42.99570679835131],
    [25.09889607802251, 8.247352985175723],
    [22.659063434742265, -3.9145212072903726],
    [74.70149981479331, 15.186182710483832],
    [-1.1487319971450451, 0.8413781020523948],
    [-13.717449436665643, -30.957398832506012],
    [-4.38019611539813, -31.056658638876364],
    [-1.9099621069352446, -24.967709522923013],
    [-2.5425673194136196, 7.076486068479916],
    [-2.85097232952335, 17.566413633564608],
    [-16.43492169285915, 46.92597886334843],
    [-48.09181133871547, -19.863853792210058],
    [-44.83133852245344, -51.12836018701548],
    [58.90448392857694, 13.210249287114696],
    [22.64354054757955, -54.16028618572216],
    [42.70351382786253, 34.68555608533731],
    [-54.57317054804015, 43.71276099631932],
    [-28.037123384413302, 67.65243851679946],
    [16.03303657573298, -73.76416063118619],
    [-42.61317804557273, 16.874544650133558],
    [50.077464858899155, -8.712965726913078],
    [49.40571304408857, 19.4357200688494],
    [-35.53456669204832, -2.977898299409249],
    [1.4553246273516696, -29.082808308960367],
    [-35.597430945071274, -2.9318965135698156],
    [37.11620358893261, -0.5016563183772472],
    [-38.21277917002783, -3.065402286862949],
    [-22.833266561420707, 28.73378771202556],
    [1.033725036174768, 44.6256150772732],
    [33.29511204584206, -2.321716352416302],
    [58.34238526020013, 2.413344933897498],
    [-6.816499211669995, -10.247185330090009],
    [2.757562957976374, -0.6023868111135015],
    [-4.551991983949312, -21.879501024204398],
    [47.6755124213815, -7.549071655631745],
    [50.58363280895385, 25.549364293914536],
    [-6.315909148950302, 34.262212480340004],
    [5.360550246948315, 34.25338805234717],
    [44.669825331447136, -9.616921190857218],
    [7.556553930164399, 11.76544780281931],
    [-25.373379701909915, 31.010465809765858],
    [-12.726556465610155, -54.30653877917995],
    [4.342805965535469, 29.38896766531993],
    [-16.091709716680725, 10.450448435604569],
    [29.021081526787533, -15.566542700182788],
    [1.2316600013754815, -22.790717257156512],
    [-22.335885539536722, 22.00891646762967],
    [-6.175967995804858, 7.525297194459401],
    [18.624586705379, 39.57325315560996],
    [51.00133912565934, 6.567867786901568],
    [6.674955611748441, 37.28837096736498],
    [-24.261037641748654, -25.16711079126518],
    [19.436067797076646, 9.563256719985482],
    [26.899143521004532, 33.557888585827044],
    [14.105230097403233, -4.242110141176617],
    [30.478491417266195, -28.590943993048768],
    [3.4321160177521857, -7.17515503753876],
    [-25.236452527017402, -2.991557869920451],
    [-7.410384782172437, 31.57347365113532],
    [-19.965448237584873, -53.286334399034025],
    [6.355822412623845, 65.71917456471846],
    [-29.76967303556976, 47.36946437616356],
    [6.124944726652966, -30.993348485726205],
    [-19.11432845904014, 39.30862428713379],
    [-14.431893751370932, -38.28956775015798],
    [-15.518650646253251, -38.364669258748556],
    [4.0619092570082245, 29.164828394637354],
    [3.6530742783934715, -12.008970022391082],
    [-11.930807090277233, -14.262074095327014],
    [37.05389357274772, 4.61358531142441],
    [-60.34857751442925, -30.374318853290255],
    [0.7425497073979469, 40.32015818489785],
    [7.825316848657938, -17.166762860003427],
    [6.250047263106314, 36.09904260126318],
    [-47.61832016490738, -36.54510841985668],
    [-31.071496227082275, 11.957678979878375],
    [4.1264927709480315, -69.71789727414003],
    [-0.8491781459589859, -13.91303154440815],
    [-57.31817384809806, -5.51331275765161],
    [-35.034371501634716, -10.527654079614337],
    [4.815287214467961, -10.814377059298778],
    [49.6335620135244, -19.108250267840624],
    [-0.6932688588081122, -1.9089774711678948],
    [5.166868374717477, -13.005763485636217],
    [-6.61024288595126, 27.60343736300328],
    [-2.732167529821853, 16.395818069114526],
    [1.8536704096318508, 55.33271937562324],
    [-54.88712369570469, 4.856467882657714],
    [-32.083800269448886, 21.164397201802245],
    [41.94609234089933, 12.035243710966718],
    [-44.61744728063923, -25.693283470777818],
    [-9.986618419418974, 5.8655241183438385],
    [-23.44470171889702, -8.76121627827541],
    [0.4042994028551554, 13.02865742284517],
    [20.965677188882594, -64.5827677725277],
    [37.03504219455357, -21.116495324157857],
    [-11.931659815110772, 63.81025136975886],
    [32.86009371750383, 9.472624709957186],
    [-41.945718844925565, -12.848432740609105],
    [-11.340236503561618, -1.8598970864561861],
    [53.97842139827543, -7.569480218002633],
    [-67.28392092349182, 3.737707507381762],
    [-4.7320229281687975, 26.512625342335653],
    [-38.06507594557353, -14.219216298191999],
    [-23.4522484510904, -26.03700031863089],
    [27.204116161162467, -12.318816656811327],
    [-37.114699164818646, -40.04459311924167],
    [-5.755989965132766, -2.346528283694411],
    [41.551672978977955, 11.576409894522433],
    [37.35524564266087, -23.228141041128726],
    [-56.19197562800824, -28.142529193652695],
    [55.5990942500771, 37.94912846887749],
    [46.71389322373071, -8.719099172446368],
    [-14.23786533580153, -38.21797180034365],
    [-45.17117278450132, 12.116161887266928],
    [-48.80342468596882, -3.9636272570082682],
    [-7.87803894036792, -10.091859815695802],
    [-60.16217178396039, 23.92994660985708],
    [0.5956472304253029, 41.733667209279695],
    [-21.873796426562418, -9.044563457675878],
    [20.58948021775989, -52.104964834025736],
    [48.725128919764956, 15.44270660457083],
    [-3.2464160928541883, 1.8800348451461646],
    [14.1899622809422, 27.24008604852089],
    [57.30507752581966, 37.84662839191146],
    [-10.381989443368322, -65.08719791357161],
    [24.632750878707885, 9.951075570340024],
    [-5.026114627731003, 2.512077623528033],
    [58.31112594296453, -17.27242380391393],
    [27.483643090464966, -17.119241122894216],
    [57.34242839804683, -47.93376512102719],
    [-14.616801107867445, 17.47375677735658],
    [-43.241787957494836, -20.62449891753945]
  ]
}
;