import ReactMarkdown from "react-markdown"

const essay = `
I always loved stages. When I was 13, I moved to a new town where everyone already knew each other and I decided to run for Class President my first day of high school. No one knew who the guy at the pedestal was and I ended up rallying a total of two votes across the entire school.

I liked physical performances too. I learned to juggle and performed at any venue that would accept my ridiculous and persistent propositioning. One time I managed to convince a barista at a Starbucks in the middle of nowhere to let me perform for the poor customers that happened to be sitting there having coffee that day. As I was dancing and juggling around the cafe for the slightly confused coffeegoers I was shocked to see my favorite musician — Steven Tyler — walk in. I freaked. I threw the 7 bouncy balls I was juggling in the air and they proceeded to cause mayhem as I ran out screaming.
Apparently Steven [lives in Marshfield, MA](https://virtualglobetrotting.com/map/steven-tylers-house/view/google/). 
![](https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fnick-personal%2F8Z5wq5AB_I?alt=media&token=f9ea3e41-f7fa-46b0-8c69-d794da09d185)

I’d run up to any stage I had any hope of being allowed on — or perform on the street outside if they said no. At 14 I heard about the upcoming show America’s Got Talent and I got in an application just before it closed and eventually made it to the second round of the show. At 16 I was quasi-invited to give a [technical talk](https://www.youtube.com/watch?v=BPzgLX02OME) at Microsoft NERD. Performing was easy and it was a blast.

This changed a decade later when I was running a startup and had to speak to a hedge-fund about a technical topic I was clueless about. The speech was supposed to be given by my employee, who had inconviently quit a couple hours earlier without warning to force me to do it. As I started trying to say words to the intimidating hedge-funders I felt my body freezing for the first time. I pretended to be sick, stopped my speech, and left. 

A few months later I gathered the courage to tell the head of the firm what really happened. We met up and he gave me a hug, and I avoided public speaking opportunities after that for a while.

Later on after joining the OpenAI safety team I panicked in front of the whole team while trying to give a small update on my project. After the meeting I sent this to our Slack channel, public to the company.

![](https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fnick-personal%2FPBj5Q23A_c?alt=media&token=79d48561-cfb7-41ff-b89d-316fc23b349f)

Then I panicked while giving my team feedback on a candidate I interviewed.

![](https://firebasestorage.googleapis.com/v0/b/firescript-577a2.appspot.com/o/imgs%2Fapp%2Fnick-personal%2FKcxI1jhAr8?alt=media&token=ec3a476d-f80c-4d26-a6d1-abf05652fc95)

It happened lots of other places too. It didn’t always go poorly — when a crowd at my Christmas party forced me to get on my table and give a toast to the holidays, it went well. 

But knowing I have this bug has made me avoid a lot of situations, and has generally increased life’s friction. So I’ve made working on it my sole personal development goal for 2020.

**My Plan**

My conscious thinking brain doesn’t find public speaking scary at all. But clearly some chunk of neurons in my brain thinks it’s the devil. My bigger fear is frightening that chunk even more, so I plan to take an incredibly slow and boring approach.

I’ve lined up a series of performance scenarios at various levels of scary.

1. Record a video lecture in an empty room
2. Give a small Twitch audience a lecture
3. Give a larger Twitch audience a lecture
4. Be on a podcast
5. Give a small in-person audience a lecture
6. Same but with Q/A
7. Give a medium size in-person audience a lecture

To make the learning curve even smoother, each time I get to a new rung of the ladder I will repeat it a few times with substances that make it more or less scary.

1. Take phenibut (maxed at once a week, preferably less frequently) the shockingly effective magic Russian anti-anxiety drug. (Please don’t take this as a recommendation, this drug has issues if improperly used.)
2. Take between 20-40mg of propranolol. Propranolol can [trigger memory reconsolidation to remove phobias in a single use](https://www.ncbi.nlm.nih.gov/pubmed/25980916) and this is where I expect the most actual emotional learning to happen.
3. Sober.
4. On two shots of espresso taken immediately before the performance.

Several times a week I’ll do whatever the current step I’m on, or go back one if I’m scared. I never have an obligation to jump to the next rung, but I do have an obligation to *do something*.  And I plan to stay at my current rung until *it feels boring*.

Doing a lecture to no one on the strongly pro-social phenibut already sounds boring, so I guess I'm starting suffiently small.

My hope is that I’ll eventually get tired with this whole thing and giving the same boring drugged lecture will become more painful than public speaking is.

Social accountability is powerful, and hopefully having this out there will increase the chance I stick to it. The first lecture begins next Monday. See you then!

—

Thanks to Nikita Obidin and Ben Mann for reviewing draft of this. I talked with them one at a time though, so it wasn’t scary.
`

export default class StageFright extends Component {
  render() {
    return (
      <post className="experiments">
        <row
          style={{
            flexFlow: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h1>My 1 Year Plan to Fight Stage Fright</h1>
          <date>January, 2020</date>
        </row>
        <ReactMarkdown source={essay} />
      </post>
    )
  }

  styles = {
    post: {
      width: 650,
    },
    strong: {
      display: "inline",
    },
    img: {
      width: "100%",
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
