import ReactMarkdown from 'react-markdown'

const essay = `
You have a gun pointed at you, from inside your head. When you lack energy, when failure hurts and risks are scary, and when life is heavy, it’s the gun.

Your limbic system controls it. It wants to tell you how worried it is about you, but it has no mouth, only a gun, which it cocks point blank all day, shaking, shooting when you make even the smallest mistake. With each recoil it weeps. It hates hurting you, but it knows the pain you’ve been through because it felt it too, and in moments of desperation it learned that disfiguring you with its bullets has a chance of stopping you from getting hurt even more.

It won’t be easy, but you'll go inside and meet it. You will be overwhelmed by its decades of bottled up feelings and nearly destroyed by the rush of memories that haunt it until you're forced to let go. You’ll be strong and enter the terror until you're embracing the part of you who only got to see glimpses of your life, granting them your eyes to see the world is beautiful and its people kind, failure is acceptable and sometimes even fun, and not every moment of life has to be productive. They’ll see one day both of you will die, and it will be okay.

As they see your eyes you see theirs, rewatching every moment of your life with unconditional compassion and undivided attention, ready to hurt the only thing you love at a moment’s notice. When they’re full of life you don’t get distracted, but when they suffer you suffer. You accept a life of pain to help someone who doesn’t know you exist.

As you feel their life, atrophied circuits separating your worlds surround both of you and as these information channels once again start to flow their compassion for you becomes yours. You notice a love for long-hated aspects of your body and mind, and wonder how you ever didn't. You giggle, finding it kind of cute, or silly, that you’ve been both sides of an exhausting and unnecessary war, and as the circuits fold inwards and engulf you in white, you feel a gun in your hand and let go.
`

export default class Gun extends Component {
  render() {
    return (
      <post className="experiments">
        <row
          style={{
            flexFlow: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <h1>The Gun to Your Head</h1>
          <date>October, 2020</date>
        </row>
        <ReactMarkdown source={essay} />
      </post>
    )
  }

  styles = {
    post: {
      width: 650,
    },
    strong: {
      display: 'inline',
    },
    img: {
      width: '100%',
    },
    h1: {
      fontSize: 20,
      fontWeight: 600,
    },
  }
}
