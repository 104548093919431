import Satisfaction from './views/posts/satisfaction'
import Experiments from './views/posts/experiments'
import Fertile from './views/posts/fertile'
import Abstraction from './views/posts/abstraction'
import StageFright from './views/posts/stageFright'
import Attention from './views/posts/attention'
import Problem from './views/posts/problem'
import Interviewing from './views/posts/interviewing'
import Intermission from './views/posts/intermission'
import ScienceFeelings from './views/posts/scienceFeelings'
import ICLR from './views/posts/iclr'
import Reappraisal from './views/posts/reappraisal'
import Quarantine from './views/posts/quarantine'
import LovetheWord from './views/posts/loveTheWord'
import Gun from './views/posts/gun'

export default [
  {
    name: 'Everything is Fertile',
    view: Fertile,
    url: 'everything-is-fertile',
    date: 'May 2019',
    desc: 'An exploration into perception and the development of magic eyes.',
    stage: 2,
  },
  {
    name: 'The Gun to Your Head',
    view: Gun,
    url: 'the-gun-to-your-head',
    date: 'October 2020',
    desc: 'The gun in your head and what it takes to put it down.',
    stage: 1,
  },
  {
    name: 'Quarantine',
    view: Quarantine,
    url: 'quarantine',
    date: 'Feb 2020',
    desc: 'Using time away from normal life as a period of growth.',
    stage: 2,
  },
  {
    name: 'Love, the Word',
    view: LovetheWord,
    url: 'love-the-word',
    date: 'June 2019',
    desc: "Let's bring an important word and feeling closer together.",
    stage: 0,
  },
  {
    name: 'Be Skeptical (75%)',
    view: ScienceFeelings,
    date: 'January 2020',
    url: 'be-skeptical',
    desc: 'Calibrating and sharing low-probility ideas.',
    stage: 1,
  },
  {
    name: 'My 1 Year Plan to Fight Stage Fright',
    view: StageFright,
    date: 'January 2020',
    url: '1-year-stage-fright',
    desc: 'Conquering a phobia.',
    stage: 1,
  },
  {
    name: 'The Two Winning Experiments of 2019',
    view: Experiments,
    date: 'January 2020',
    url: 'two-experiments-2019',
    desc: 'The two best experiments I ran in 2020.',
    stage: 1,
  },
  {
    name: 'The Two Winning Experiments of 2019',
    view: Experiments,
    date: 'January 2020',
    url: 'experiments-2019',
    desc: 'The two best experiments I ran in 2020.',
    stage: 0,
  },
  {
    name: 'Attention and the Treewalk',
    view: Attention,
    date: 'December 2018',
    url: 'attention',
    desc: 'A new way for me to explore and appreciate my neighborhood.',
    stage: 2,
  },
  {
    name: 'Empathetic Reappraisal',
    url: 'empathetic-reappraisal',
    date: 'June 2019',
    desc:
      'A technique I use to help me empathetic with the famous and the attractive.',
    stage: 2,
    view: Reappraisal,
  },
  {
    name: 'Sensors',
    view: Problem,
    date: 'May 2019',
    url: 'sensors',
    desc: "If only my sensors weren't attached to my head.",
    stage: 2,
  },
  {
    name: 'Pieces of Happy',
    view: Satisfaction,
    url: 'pieces-of-happy',
    date: 'September 2018',
    desc: 'A simple framework for personal experimentation.',
    stage: 2,
  },
  {
    name: 'Shape of ICLR',
    view: ICLR,
    url: 'iclr',
    date: 'June 2018',
    desc: 'A visualization for exploring the papers submitted to ICLR 2019.',
    stage: 2,
  },
  {
    name: 'Intermission',
    view: Intermission,
    url: 'intermission',
    desc: 'A short note of reflection before taking off around the world.',
    date: 'June 2019',
    stage: 2,
  },
]
